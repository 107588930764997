import React, { useState } from 'react'
import FeedCard from './FeedCard'
import FeedTitle from './FeedTitle'
import { UqButton } from '@uniqore/wrapper'
import { Props } from '../customer-ui/Header'
import { useQuery } from '@apollo/client'
import { gql } from '@apollo/client/core'
import { refetch as refetchAction } from '../../store/actions/common'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../types/types'

const GET_EVENTS = gql`
  query fetchDataview($id: ID!, $parameters: GenericScalar) {
    fetchDataview(id: $id, parameters: $parameters)
  }
`

interface Event {
  content: {
    avatarBackgroundColor: string
    date: string
    subtitle: string
    text: string
    user: string
  }
}

interface Events {
  fetchDataview: {
    data: Array<Event>
  }
}

export const Feed: React.FC<Props> = ({ engagement }) => {
  const dispatch = useDispatch()
  const { refetch } = useSelector((state: AppState) => state.common)
  const [more, setMore] = useState(10)
  const {
    loading,
    error,
    data,
    refetch: refetchEvents
  } = useQuery<Events>(GET_EVENTS, {
    variables: {
      id: process.env.REACT_APP_EVENTS,
      parameters: { engag: engagement.id }
    }
  })

  if (refetch) {
    refetchEvents()
    dispatch(refetchAction(false))
  }

  if (loading || error || data === undefined) return null

  const items = []
  let index = 0
  for (let i = data.fetchDataview.data.length; i--; ) {
    const item = data.fetchDataview.data[i]

    if (item.content == null) continue

    items.push(
      <FeedCard
        key={i}
        avatarBackgroundColor={item.content.avatarBackgroundColor}
        date={item.content.date}
        subtitle={item.content.subtitle}
        text={item.content.text}
        user={item.content.user}
      />
    )
    index = index + 1
  }

  return (
    <React.Fragment>
      <FeedTitle text={'Viimeiset tapahtumat'} />
      {items.slice(0, more)}
      {more < items.length ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <UqButton color="primary" onClick={() => setMore(more + 10)} variant="contained">
            Lisää
          </UqButton>
        </div>
      ) : null}
    </React.Fragment>
  )
}
