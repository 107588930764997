const { utcToZonedTime, format } = require('date-fns-tz')
const { getISODay, addDays, differenceInSeconds, parse, parseISO } = require('date-fns')
const TIMEZONE = 'Europe/Helsinki'

export const lastSunday = () => {
  const date = new Date()
  const offsetDays = -7 - (getISODay(date) - 7)
  return addDays(date, offsetDays)
}

export const changeDateFormat = (date: string) => {
  let parts = date.split('-')
  return parts[2] + '.' + parts[1] + '.' + parts[0]
}

export const secondsToString = (val: number) => {
  let valString = val + ''
  if (valString.length < 2) {
    return '0' + valString
  } else {
    return valString
  }
}

export const secondsToHours = (val: number) => {
  return secondsToString(Math.floor(val / 3600))
}

export const secondsToMinutes = (val: number) => {
  return secondsToString(Math.floor((val % (60 * 60)) / 60))
}

export const secondsToHoursAndMinutes = (val: number) => {
  return `${secondsToHours(val)}:${secondsToMinutes(val)}`
}

export const getActionsInSeconds = (actions: React.ReactNode[], type: string) => {
  let sumOfSeconds = 0
  actions.forEach((action: any) => {
    if (type === action.status) {
      const start = parse(action.startTime, 'yyyy-MM-dd HH:mm:ss', new Date())
      let end = action.endTime
      if (end === null) {
        end = new Date()
      } else {
        end = parse(end, 'yyyy-MM-dd HH:mm:ss', new Date())
      }
      sumOfSeconds += differenceInSeconds(end, start)
    }
  })

  return sumOfSeconds
}

export const getWorkingHoursInSeconds = (workingHours: React.ReactNode[]) => {
  let sumOfSeconds = 0
  const breakReason = localStorage.getItem('breakReason')
  workingHours.forEach((action: any) => {
    const activeStatus = action.status === 'Work' || breakReason === 'Paid break'
    const isCurrentDate =
      new Date(action.startTime).getDate() === new Date().getDate() &&
      new Date(action.startTime).getMonth() === new Date().getMonth() &&
      new Date(action.startTime).getFullYear() === new Date().getFullYear()
    if (activeStatus && isCurrentDate) {
      const start = parseISO(action.startTime)
      let end = action.endTime
      if (end === null) {
        end = new Date()
      } else {
        end = parseISO(end)
      }
      sumOfSeconds += differenceInSeconds(end, start)
    }
  })

  return sumOfSeconds
}

export const formatDateForEvents = (date: Date) => {
  return format(utcToZonedTime(date, TIMEZONE), "dd.MM.yyyy 'klo' HH:mm")
}

export const formatDateTime = (date: Date, dateTimeFormat: string) => {
  return format(utcToZonedTime(date, TIMEZONE), dateTimeFormat)
}

export const formatActionDateTime = (date: Date) => {
  return format(utcToZonedTime(date, TIMEZONE), 'yyyy-MM-dd HH:mm:ss')
}

export const secondsToHoursMinutesAndSeconds = (seconds: number) => {
  return new Date(seconds * 1000).toISOString().substr(11, 8)
}
