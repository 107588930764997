import { gql } from '@apollo/client'

export const loginQuery = gql`
  mutation loginOrgUser($auth: ID!, $email: String!, $password: String!) {
    loginOrgUser(auth: $auth, email: $email, password: $password) {
      token
      error
      orgUserId
    }
  }
`

export const getOrgUserQuery = gql`
  query orgUser($id: ID!) {
    orgUser(id: $id) {
      firstName
    }
  }
`

export const getCleaningsQuery = gql`
  query fetchDataview($id: ID!, $parameters: GenericScalar) {
    fetchDataview(id: $id, parameters: $parameters)
  }
`

export const getCleaningTimesQuery = gql`
  query fetchDataview($id: ID!, $parameters: GenericScalar) {
    fetchDataview(id: $id, parameters: $parameters)
  }
`

export const addTimerActionQuery = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`

export const markCompletedQuery = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`

export const searchQuery = gql`
  query fetchDataview($id: ID!, $parameters: GenericScalar, $search: String) {
    fetchDataview(id: $id, parameters: $parameters, search: $search)
  }
`

export const editDataQuery = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`

export const getFeedcardsQuery = gql`
  query serviceEngagement($id: ID!) {
    serviceEngagement(id: $id) {
      id
      events {
        created
        key
        metadata {
          key
          value
        }
      }
    }
  }
`
