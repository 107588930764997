import { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { IconButton, Alert as MuiAlert, AlertProps, TextFieldProps } from '@mui/material'
import { Delete, Edit, PlayCircleFilled, Close } from '@mui/icons-material'
import { AppBar, StatusHero, Dialog, WhiteLabel, StyledButton } from 'components/common'
import {
  UqCircularProgress,
  UqContainer,
  UqButton,
  UqTypography,
  UqListItem,
  UqList,
  UqListItemSecondaryAction,
  UqListItemText,
  UqSnackbar,
  UqTextField
} from '@uniqore/wrapper'
import { useHistory, useParams } from 'react-router-dom'
import { addTimerActionQuery, getCleaningsQuery, editDataQuery } from './queries/queries'
import { formatDateTime } from './utils/dateFunctions'
import { appBarAction } from './utils/help'
import { useMutation, useQuery } from '@apollo/client'
import { format } from 'date-fns'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'

const useStyles = makeStyles()(() => ({
  whiteLabel: {
    position: 'fixed',
    bottom: '0',
    width: '100% !important',
    left: '0'
  },
  textButton: {
    width: '100%',
    textAlign: 'center'
  },
  speedDial: {
    position: 'fixed',
    left: '75%',
    bottom: 10
  },
  snackbar: {
    backgroundColor: '#FF3F8D'
  },
  timeFields: {
    marginTop: 25
  }
}))

interface newTime {
  start: Date
  end: Date
}

const WorkTime = () => {
  const { classes } = useStyles()
  let history = useHistory()
  const { date } = useParams<{ date: string }>()
  const currentDate = new Date(date)
  const formattedCurrentDate = format(currentDate, 'dd.MM.yyyy')
  const [worktimes, setWorktimes] = useState<any>([])
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
  const [worktime, setWorktime] = useState<any>(null)
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)

  const [showTimeDialog, setShowTimeDialog] = useState<boolean>(false)
  const [showEditTimeDialog, setShowEditTimeDialog] = useState<boolean>(false)
  const [startEditedTimeValue, setEditedStartTimeValue] = useState(new Date())
  const [endEditedTimeValue, setEditedEndTimeValue] = useState(new Date())
  const [airtableId, setAirtableId] = useState<string>('')
  const [currentRecordType, setCurrentRecordType] = useState<string>('')

  const [newTime, setNewTime] = useState<newTime>({
    start: new Date(),
    end: new Date()
  })

  const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }

  const { loading, refetch } = useQuery(getCleaningsQuery, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: process.env.REACT_APP_WORKTIME,
      parameters: {
        date: date
      }
    },
    onCompleted(result) {
      setWorktimes(result.fetchDataview.data.records)
    },
    onError(error) {
      setShowSnackbar(true)
    }
  })

  const appBarActions = () => {
    const actions = []
    if (process.env.REACT_APP_GUIDE_URL) {
      actions.push(appBarAction)
    }
    return actions
  }

  const returnToPrev = () => {
    history.push('/')
  }

  const [addTimeAction, { loading: addTimeLoading }] = useMutation(addTimerActionQuery, {
    onCompleted(mutationResult) {
      if (mutationResult.ingestForm.errors || !mutationResult.ingestForm.ok) {
        setShowSnackbar(true)
      } else {
        refetch()
      }
    }
  })

  const [editWorkingTimeAction, { loading: editLoading }] = useMutation(editDataQuery, {
    notifyOnNetworkStatusChange: true,
    onCompleted(mutationResult) {
      if (mutationResult.ingestForm.errors || !mutationResult.ingestForm.ok) {
        setShowSnackbar(true)
      } else {
        setShowEditTimeDialog(false)
        refetch()
      }
    }
  })

  const sendEditedTime = () => {
    editWorkingTimeAction({
      variables: {
        id: process.env.REACT_APP_WORKTIME_EDIT,
        form: {
          start: startEditedTimeValue,
          end: endEditedTimeValue,
          id: airtableId,
          type: currentRecordType
        }
      }
    })
  }

  const addNewTime = async () => {
    const currentDateStartTime = newTime.start.setDate(currentDate.getDate())
    const currentDateEndTime = newTime.end.setDate(currentDate.getDate())
    await addTimeAction({
      variables: {
        id: process.env.REACT_APP_START_WORK_BREAK,
        form: {
          start: currentDateStartTime,
          end: currentDateEndTime,
          type: 'Work'
        }
      }
    })
    setShowTimeDialog(false)
  }

  const [deleteWorktime, { loading: deleteLoading }] = useMutation(addTimerActionQuery, {
    onCompleted() {
      refetch()
      setDeleteDialog(false)
      setWorktime(null)
    }
  })

  const worktimeFormatted = (start: Date, end: Date) => {
    return `${formatDateTime(start, 'dd.MM.yyyy HH:mm')} - ${formatDateTime(end, 'HH:mm')}`
  }

  return (
    <div style={{ width: '100%' }}>
      <UqSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setShowSnackbar(false)}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      >
        <Alert severity="error">Virhe tapahtui. Ole hyvä ja yritä hetken kuluttua uudestaan.</Alert>
      </UqSnackbar>
      <AppBar
        backgroundColor="#69315F"
        color="#fff"
        logo="https://storage.googleapis.com/minttisiivous/41d7da18-mintti-flag-big-darkbg.svg"
        logoSize={32}
        mainAction={{ icon: 'ArrowBack', onClick: returnToPrev }}
        actions={appBarActions()}
        maxWidth="sm"
      />
      <StatusHero
        backgroundColor="#69315F"
        color="#FFF1E9"
        noticeText={formattedCurrentDate}
        iconSize={80}
        actionComponent={
          <>
            <UqButton
              startIcon={<PlayCircleFilled />}
              style={{ textAlign: 'center' }}
              variant="contained"
              color="primary"
              onClick={() => setShowTimeDialog(true)}
              disabled={loading}
              sx={{ padding: '16px 24px' }}
            >
              {addTimeLoading ? (
                <UqCircularProgress size="24px" color="inherit" />
              ) : (
                'Lisää uusi aikamerkintä'
              )}
            </UqButton>
          </>
        }
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Dialog
          open={showEditTimeDialog}
          onClose={() => {
            setShowEditTimeDialog(false)
          }}
          title="Muokkaa"
          actionComponents={[
            <StyledButton
              disabled={editLoading}
              variant="text"
              color="secondary"
              onClick={() => setShowEditTimeDialog(false)}
            >
              Peruuta
            </StyledButton>,
            <StyledButton
              disabled={editLoading}
              variant="contained"
              color="primary"
              onClick={sendEditedTime}
            >
              {editLoading ? <UqCircularProgress size="24px" color="inherit" /> : 'Lähetä'}
            </StyledButton>
          ]}
        >
          <TimePicker
            key="start"
            className={classes.timeFields}
            value={startEditedTimeValue}
            inputFormat="HH:mm"
            renderInput={(params: TextFieldProps) => (
              <UqTextField
                {...params}
                fullWidth
                // error={startTimeError}
                // helperText={startTimeErrorMessage}
              />
            )}
            ampm={false}
            label="Aloitus"
            onChange={(date: any) => setEditedStartTimeValue(date)}
            onAccept={(date: any) => setEditedStartTimeValue(date)}
          />
          <TimePicker
            key="end"
            value={endEditedTimeValue}
            className={classes.timeFields}
            inputFormat="HH:mm"
            renderInput={(params: TextFieldProps) => (
              <UqTextField
                {...params}
                fullWidth
                // error={endTimeError}
                // helperText={endTimeErrorMessage}
              />
            )}
            ampm={false}
            label="Lopetus"
            onChange={(date: any) => setEditedEndTimeValue(date)}
            onAccept={(date: any) => setEditedEndTimeValue(date)}
          />
        </Dialog>
        <Dialog
          open={showTimeDialog}
          onClose={() => {
            setShowTimeDialog(false)
          }}
          title="Lisää uusi aikamerkintä"
          actionComponents={[
            <StyledButton
              disabled={addTimeLoading}
              variant="text"
              color="secondary"
              onClick={() => setShowTimeDialog(false)}
            >
              Peruuta
            </StyledButton>,
            <StyledButton
              disabled={addTimeLoading}
              variant="contained"
              color="primary"
              onClick={addNewTime}
            >
              {addTimeLoading ? <UqCircularProgress size="24px" color="inherit" /> : 'Lähetä'}
            </StyledButton>
          ]}
        >
          <TimePicker
            key="start"
            className={classes.timeFields}
            value={newTime.start}
            inputFormat="HH:mm"
            renderInput={(params: TextFieldProps) => (
              <UqTextField
                {...params}
                fullWidth
                // error={startTimeError}
                // helperText={startTimeErrorMessage}
              />
            )}
            ampm={false}
            label="Aloitus"
            onChange={(date: any) =>
              setNewTime({
                ...newTime,
                start: date
              })
            }
            onAccept={(date: any) => {
              setNewTime({
                ...newTime,
                start: date
              })
            }}
          />
          <TimePicker
            key="end"
            value={newTime.end}
            className={classes.timeFields}
            inputFormat="HH:mm"
            renderInput={(params: TextFieldProps) => (
              <UqTextField
                {...params}
                fullWidth
                // error={endTimeError}
                // helperText={endTimeErrorMessage}
              />
            )}
            ampm={false}
            label="Lopetus"
            onChange={(date: any) =>
              setNewTime({
                ...newTime,
                end: date
              })
            }
            onAccept={(date: any) =>
              setNewTime({
                ...newTime,
                end: date
              })
            }
          />
        </Dialog>
      </LocalizationProvider>
      <UqContainer maxWidth="sm" fixed={true} marginTop={48} sx={{ paddingBottom: '24px' }}>
        <UqTypography align="left" variant="h6">
          Työajat
        </UqTypography>
        {loading ? (
          <div
            style={{
              width: 60,
              margin: '25px auto'
            }}
          >
            <UqCircularProgress />
          </div>
        ) : worktimes ? (
          <UqList>
            {worktimes.map((day: any) => {
              return (
                <UqListItem key={day.id}>
                  <UqListItemText
                    primary={worktimeFormatted(day.fields.Start, day.fields.End)}
                    secondary={day.fields.Type}
                  />
                  <UqListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      style={{marginRight: '10px'}}
                      aria-label="edit"
                      disabled={loading}
                      onClick={() => {
                        setAirtableId(day.id)
                        setCurrentRecordType(day.fields.Type)
                        setEditedStartTimeValue(day.fields.Start)
                        setEditedEndTimeValue(day.fields.End)
                        setShowEditTimeDialog(true)
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      disabled={loading}
                      onClick={() => {
                        setDeleteDialog(true)
                        setWorktime(day)
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </UqListItemSecondaryAction>
                </UqListItem>
              )
            })}
          </UqList>
        ) : null}
      </UqContainer>

      <Dialog
        open={deleteDialog}
        onClose={() => {
          setDeleteDialog(false)
          setWorktime(null)
        }}
        title="Poista tuntimerkintä"
        children={
          <div>
            Haluatko varmasti poistaa tuntimerkinnän{' '}
            {worktime ? worktimeFormatted(worktime.fields.Start, worktime.fields.End) : null}?
          </div>
        }
        actionComponents={[
          <StyledButton
            variant="text"
            disabled={loading}
            onClick={() => {
              setDeleteDialog(false)
              setWorktime(null)
            }}
          >
            Peruuta
          </StyledButton>,
          <StyledButton
            disabled={loading}
            color="primary"
            variant="contained"
            onClick={() => {
              deleteWorktime({
                variables: {
                  id: process.env.REACT_APP_WORKTIME_DELETE,
                  form: {
                    id: worktime.id
                  }
                }
              })
            }}
          >
            {deleteLoading ? <UqCircularProgress size="24px" color="inherit" /> : 'Poista'}
          </StyledButton>
        ]}
      />
      <UqContainer maxWidth="lg" fixed={true}>
        <WhiteLabel className={classes.whiteLabel} />
      </UqContainer>
    </div>
  )
}

export default WorkTime
