import React from 'react'
import { default as MaterialDialog } from '@mui/material/Dialog'
import { makeStyles } from 'tss-react/mui'
import { Theme } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Wrapper from './Wrapper'

const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    fontStyle: theme.typography.h6.fontStyle,
    fontSize: theme.typography.h6.fontSize,
    lineHeight: theme.typography.h6.lineHeight,
    letterSpacing: theme.typography.h6.letterSpacing,
    color: theme.palette.text.primary,
    paddingBottom: '16px'
  },
  subtitle: {
    fontStyle: theme.typography.subtitle1.fontStyle,
    fontSize: theme.typography.subtitle1.fontSize,
    lineHeight: theme.typography.subtitle1.lineHeight,
    letterSpacing: theme.typography.subtitle1.letterSpacing,
    color: theme.palette.text.secondary,
    marginBottom: '0px',
    paddingBottom: '8px'
  },
  dialogContent: {
    padding: '0px 24px 16px 24px'
  },
  dialogActions: {
    padding: '0px 24px 24px 24px'
  }
}))

export interface Props {
  /**
   * What title to use.
   */
  title?: string
  /**
   * What subtitle to use.
   */
  subtitle?: string
  /**
   * If 'true' the Dialog is shown.
   */
  open: boolean
  /**
   * Callback fired when the component requests to be closed.
   *
   * @param {object} event The event source of the callback.
   * @param {string} reason Can be: `"escapeKeyDown"`, `"backdropClick"`.
   */
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void
  /**
   * Callback fired when default Submit -button is clicked (actionComponents attribute is undefined).
   */
  onSubmit?: Function
  /**
   * If `true`, the dialog stretches to `maxWidth`.
   */
  fullWidth?: boolean
  /**
   * An array of components inside Dialog's actions area, usually buttons.
   */
  actionComponents?: React.ReactNode[]
  /**
   * Dialog contents as children
   */
  children?: NonNullable<React.ReactNode>
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the dialog.
   * The dialog width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const Dialog: React.FC<Props> = ({
  title,
  subtitle,
  open = false,
  onClose,
  onSubmit,
  fullWidth = true,
  maxWidth = 'sm',
  children,
  actionComponents,
  style,
  className
}) => {
  const { classes } = useStyles()

  return (
    <Wrapper style={style} className={className}>
      <MaterialDialog
        open={open}
        onClose={onClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-labelledby="dialog"
      >
        <DialogTitle className={classes.title}>{title}</DialogTitle>
        {children || subtitle ? (
          <DialogContent className={classes.dialogContent}>
            {subtitle ? (
              <DialogContentText className={classes.subtitle}>{subtitle}</DialogContentText>
            ) : null}
            {children}
          </DialogContent>
        ) : null}
        {actionComponents && (
          <DialogActions className={classes.dialogActions}>
            {actionComponents.map((actionComponent, index) => {
              return <div key={index}>{actionComponent}</div>
            })}
          </DialogActions>
        )}
      </MaterialDialog>
    </Wrapper>
  )
}

export default Dialog
