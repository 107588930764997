import { authLink } from './authLink'
import { analyticsMiddleware } from './analyticsMiddlware'
import { errorLink } from './errorLink'
import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_APIURL as string
})

const PublicClient = new ApolloClient({
  link: from([analyticsMiddleware, authLink, errorLink, httpLink]),
  cache: new InMemoryCache()
})

export default PublicClient
