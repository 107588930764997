import { Record, RecordFields } from '../../types/types'

/**
 * Allocation that is in progress, not completed
 * but is not the currently active allocation for the user
 * @param record
 * @returns
 */
export const workAllocationIsInProgress = (record: Record) => {
  const recordFields: RecordFields = record.fields
  const workAllocationTimes = recordFields['Work allocations times'] as Array<string>
  return (
    !('Completed at' in recordFields) &&
    'Work allocations times' in recordFields &&
    workAllocationTimes?.length > 0 &&
    !workAllocationIsActive(record)
  )
}

/**
 * Planned allocations. Planned allocation is one that is not
 * active, doesn't have times logged and is not completed
 * @param record
 * @returns
 */
export const workAllocationIsPlanned = (record: Record) => {
  return (
    !workAllocationIsCompleted(record) &&
    !workAllocationIsInProgress(record) &&
    !workAllocationIsActive(record)
    //&& (itemInProgress === null || itemInProgress === '')
  )
}

export const workAllocationIsCompleted = (record: Record) => {
  return record.fields['Completed at'] ? true : false
}

export const hasItemsInProgress = (records: Array<Record>) => {
  if (userHasItemInProgress() || getCurrentItem()) return true
  return records.some((record: Record) => {
    return workAllocationIsInProgress(record)
  })
}

/**
 * Check if the passed record is active for the user eg. is in localStorage
 * @param record
 * @returns
 */
export const workAllocationIsActive = (record: Record) => {
  const currentItem = getCurrentItem()
  return currentItem ? currentItem.airtableId === record.id : false
}

export const userHasItemInProgress = () => {
  return (
    localStorage.getItem('itemInProgress') !== null && localStorage.getItem('itemInProgress') !== ''
  )
}

export const getItemInProgress = () => {
  return localStorage.getItem('itemInProgress')
}

/**
 * Get active item from localStorage as object
 * @returns
 */
export const getCurrentItem = () => {
  const _currentItem = localStorage.getItem('currentItem')
  return _currentItem ? JSON.parse(_currentItem) : null
}

/**
 * Get property value safely from object.
 * Returns an empty string if property was not found in obj.
 * Pass in index if the property value is an array (like Address of work allocation)
 */
export const getField = (obj: any, field: string, index: number | null = null): any => {
  if (field in obj) {
    if (index !== null && index in obj[field]) {
      return obj[field][index]
    }
    return obj[field]
  }
  return ''
}
