import { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { UqContainer, UqTextField, UqButton } from '@uniqore/wrapper'
import { Login } from '@uniqore/module'
import { WhiteLabel } from 'components/common'
import { CircularProgress, IconButton, InputAdornment, Snackbar } from '@mui/material'
import { Close, LockOutlined, MailOutline, Visibility, VisibilityOff } from '@mui/icons-material'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { passwordResetDone } from '../store/actions/userInterface'
import { AppState } from 'types/types'
import { loginQuery } from './queries/queries'

const useStyles = makeStyles()(() => ({
  form: {
    marginTop: '40%'
  },
  whiteLabel: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    left: '0'
  }
}))

const LoginView = () => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { passwordResetDone: passDone } = useSelector((state: AppState) => state.userInterface)
  let history = useHistory()

  const [login, { loading: mutationLoading }] = useMutation(loginQuery, {
    onCompleted(mutationResult) {
      if (mutationResult.loginOrgUser.error) {
        setErrorMessage(mutationResult.loginOrgUser.error)
      } else if (mutationResult.loginOrgUser.token) {
        localStorage.setItem('token', mutationResult.loginOrgUser.token)
        localStorage.setItem('userId', mutationResult.loginOrgUser.orgUserId)
        history.push('/')
      }
    }
  })

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    localStorage.setItem('email', email)
    login({
      variables: {
        email: email,
        password: password,
        auth: process.env.REACT_APP_ORG_AUTH_ID
      }
    })
  }

  const onForgotPassword = () => {
    history.push('/palauta-salasana')
  }

  const handleClose = () => {
    dispatch(passwordResetDone(false))
  }

  return (
    <UqContainer maxWidth="xs" fixed={true}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={passDone}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Hienoa! Salasana on vaihdettu."
        color="secondary"
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <Close fontSize="small" />
          </IconButton>
        }
      />
      <Login
        style={{ marginTop: '40%' }}
        headingComponents={{
          primary: {
            text: 'Kirjaudu sisään',
            variant: 'h5'
          },
          secondary: {
            text: ''
          }
        }}
        contentComponents={[
          <UqTextField
            autoFocus
            fullWidth
            placeholder="Sähköposti"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutline />
                </InputAdornment>
              )
            }}
            name="email"
            value={email}
            onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
              setEmail(e.target.value)
            }
            error={errorMessage ? true : false}
            helperText={errorMessage}
            marginBottom={16}
          />,
          <UqTextField
            fullWidth
            placeholder="Salasana"
            type={passwordVisible ? 'text' : 'password'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setPasswordVisible(!passwordVisible)} edge="end">
                    {passwordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            name="password"
            value={password}
            onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
              setPassword(e.target.value)
            }
            error={errorMessage ? true : false}
            helperText={errorMessage}
          />
        ]}
        actionComponents={[
          <UqButton
            type="submit"
            color="secondary"
            variant="contained"
            fullWidth
            disabled={mutationLoading}
            sx={{ padding: '16px 0' }}
            marginBottom={16}
            onClick={(e: any) => handleSubmit(e)}
          >
            {mutationLoading ? <CircularProgress size="24px" color="inherit" /> : 'KIRJAUDU SISÄÄN'}
          </UqButton>,
          <UqButton
            variant="text"
            fullWidth
            color="secondary"
            onClick={onForgotPassword}
            sx={{ padding: '16px 0', textTransform: 'none' }}
          >
            Salasana unohtunut?
          </UqButton>
        ]}
      />
      <WhiteLabel className={classes.whiteLabel} />
    </UqContainer>
  )
}

export default LoginView
