import { createTheme } from '@mui/material/styles'
import { UqTheme } from '@uniqore/theme'

const theme = createTheme({
  ...UqTheme,
  palette: {
    ...UqTheme.palette,
    primary: {
      main: '#FF3F8D'
    },
    secondary: {
      main: '#69315F'
    }
  },
  typography: {
    ...UqTheme.typography,
    h5: {
      fontWeight: 600
    },
    h6: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '31px',
      letterSpacing: '0.15px'
    },
    button: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '1.25px'
    }
  }
})

export default theme
