import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Theme } from '@mui/material'
import Typography from '@mui/material/Typography'
import Wrapper from './Wrapper'

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: '18px 8px 2px 8px',
    margin: 'auto',
    maxWidth: 344,
    '@media (min-width: 1024px)': {
      maxWidth: 400
    }
  },
  text: {
    color: theme.palette.text.secondary
  }
}))

export interface Props {
  /**
   * What text to use
   */
  text?: string
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const FeedTitle: React.FC<Props> = ({ text, style, className, maxWidth }) => {
  const { classes } = useStyles()

  return (
    <Wrapper style={style} className={className} maxWidth={maxWidth}>
      <div className={classes.container} style={{ textAlign: 'left' }}>
        <Typography className={classes.text} variant="subtitle1">
          {text}
        </Typography>
      </div>
    </Wrapper>
  )
}

export default FeedTitle
