import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import theme from 'theme/theme'
import { Theme } from '@mui/material'
import Rating from '@mui/material/Rating'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Popover from '@mui/material/Popover'
import { Wrapper } from 'components/common'
import ListItemText from '@mui/material/ListItemText'
import Fab from '@mui/material/Fab'
import ClearIcon from '@mui/icons-material/Clear'
import CheckIcon from '@mui/icons-material/Check'
import { ReactComponent as Emoji1 } from '../../assets/emojis/1.svg'
import { ReactComponent as Emoji2 } from '../../assets/emojis/2.svg'
import { ReactComponent as Emoji3 } from '../../assets/emojis/3.svg'
import { ReactComponent as Emoji4 } from '../../assets/emojis/4.svg'

const useStyles = makeStyles()((theme: Theme) => ({
  primary: {
    color: theme.palette.text.primary
  },
  secondary: {
    color: theme.palette.text.secondary
  },
  dialog: {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'left',
    height: 72,
    width: 288,
    '& [class*="MuiListItemText-root"]': {
      flex: 'none'
    }
  },
  text: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 72,
    paddingBottom: '18px',
    boxSizing: 'border-box'
  },
  paper: {
    display: 'inline-block',
    boxSizing: 'border-box',
    height: 72,
    width: 288,
    textAlign: 'center',
    padding: '8px 0px 0px 0px',
    '& [class*="MuiFab-root"]': {
      boxShadow: 'none'
    },
    '& [class*="MuiFab-root"][class*="MuiFab-colorInherit"]': {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.primary.main}`
    },
    '& [class*="MuiFab-root"][class*="MuiFab-colorInherit"]:hover': {
      backgroundColor: 'transparent',
      opacity: '0.7'
    },
    '& [class*="MuiRating-root"][class*="Mui-disabled"]': {
      opacity: '1 !important'
    }
  },
  rating: {
    boxSizing: 'border-box',
    '& [class*="MuiRating-icon"] .selected svg': {
      boxShadow:
        '0px 2px 1px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
      borderRadius: 100
    },
    '& [class*="MuiRating-icon"] .not-selected svg path:first-child': {
      fill: '#CCD4D7'
    },
    '& [class*="MuiRating-icon"] .hovering': {
      opacity: 0.5
    },
    '& [class*="MuiRating-iconActive"]': {
      transform: 'scale(1)',
      color: 'inherit'
    }
  },
  popover: {
    pointerEvents: 'none',
    '& [class*="MuiPopover-paper"]': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '20px',
      height: 'auto',
      width: 'auto'
    }
  }
}))

const customIcons = [
  {
    icon: <Emoji4 id="very-happy" style={{ width: 56, height: 56 }} />,
    value: 4
  },
  {
    icon: <Emoji3 id="happy" style={{ width: 56, height: 56 }} />,
    value: 3
  },
  {
    icon: <Emoji2 id="unhappy" style={{ width: 56, height: 56 }} />,
    value: 2
  },
  {
    icon: <Emoji1 id="very-unhappy" style={{ width: 56, height: 56 }} />,
    value: 1
  }
]

export interface Props {
  /**
   * The component name.
   */
  name?: string
  /**
   * The message text to be shown after the feedback is submitted.
   */
  text?: string
  /**
   * The title for the dialog, (limited to 20 letters, including the space).
   */
  title?: string
  /**
   * The subtitle for the dialog,
   * if longer than 22 letters, the ellipsis (i.e. three little dots) will be enabled
   * and you can view the full text version by hovering over it.
   */
  subtitle?: string
  /**
   * Returns the value of the feedback.
   */
  onChange?: (value: number) => void
  /**
   * Set the value of the feedback.
   */
  feedbackValue?: 1 | 2 | 3 | 4
  /**
   * If the comment is submitted.
   * Note: this requires the "feedbackValue" props to be set first.
   */
  commentSubmitted?: boolean
  /**
   * Confirm button's onClicked event
   */
  onConfirm?: React.MouseEventHandler
  /**
   * Cancel button's onClicked event
   */
  onCancel?: React.MouseEventHandler
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
}

// When rating is reversed (left to right -> good to bad), and Mui-v5 update is messing this up
const reverseValue = (value: number): number => {
  let result: number = 0
  if (value === 1) {
    result = 4
  }
  if (value === 2) {
    result = 3
  }
  if (value === 3) {
    result = 2
  }
  if (value === 4) {
    result = 1
  }
  return result
}

const Feedback: React.FC<Props> = ({
  name = 'Feedback',
  text,
  title = 'Thanks',
  subtitle = 'Add comments?',
  onChange,
  onConfirm,
  onCancel,
  feedbackValue,
  commentSubmitted = false,
  style,
  className
}) => {
  const { classes } = useStyles()

  const [clicked, setClicked] = useState<boolean>(false)
  const [hovering, setHovering] = useState<number>(feedbackValue || -1)
  const [currentValue, setCurrentValue] = useState<number>(0)

  /**
   * For the popover control
   */
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const popoverOpen = Boolean(anchorEl)

  return (
    <Wrapper style={style} className={className}>
      <Paper className={classes.paper} sx={{ borderRadius: 100 }}>
        {clicked || (feedbackValue && (text || onConfirm)) ? (
          onConfirm ? (
            commentSubmitted === false || !feedbackValue ? (
              <div className={classes.dialog}>
                <ListItemText
                  className={classes.text}
                  style={{ marginLeft: '16px' }}
                  primary={
                    <Typography variant="subtitle1" className={classes.primary}>
                      {title.length > 20 ? `${title.substring(0, 20)}` : title}
                    </Typography>
                  }
                  secondary={
                    <Typography className={classes.secondary} variant="body2" component="span">
                      {subtitle.length > 21 ? (
                        <span
                          aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
                          aria-haspopup="true"
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}
                        >
                          {subtitle.substring(0, 19)} ...
                        </span>
                      ) : (
                        subtitle
                      )}
                    </Typography>
                  }
                />
                <Fab
                  color="primary"
                  aria-label="add"
                  className="inline"
                  style={{
                    margin: '0 0 2px 6px',
                    backgroundColor: theme.palette.primary.main
                  }}
                  onClick={onConfirm}
                >
                  <CheckIcon />
                </Fab>
                <Fab
                  color="inherit"
                  aria-label="delete"
                  className="inline"
                  style={{ margin: '0px 8px 0px 6px' }}
                  onClick={onCancel}
                >
                  <ClearIcon />
                </Fab>
              </div>
            ) : (
              <div className={classes.text}>
                <Typography variant="subtitle1" className={classes.primary}>
                  {text ? text : 'Thank you for your feedback!'}
                </Typography>
              </div>
            )
          ) : text ? (
            <div className={classes.text}>
              <Typography variant="subtitle1" className={classes.primary}>
                {text}
              </Typography>
            </div>
          ) : null
        ) : (
          <div>
            <Rating
              className={classes.rating}
              sx={{ flexDirection: 'row-reverse' }}
              max={4}
              name={name}
              value={currentValue}
              disabled={currentValue > 0 || feedbackValue !== undefined}
              onChangeActive={(_event, newValue) => {
                setHovering(reverseValue(newValue))
              }}
              onChange={(_event, newValue) => {
                setCurrentValue(reverseValue(newValue as number))
                if (onChange) {
                  onChange(reverseValue(newValue as number))
                }
                if (text || onConfirm) {
                  setClicked(true)
                }
              }}
              IconContainerComponent={(props: { value: number }) => {
                const { value, ...other } = props
                const iconClasses = []
                if (value === currentValue || feedbackValue === value) {
                  iconClasses.push('selected')
                } else if (
                  (value !== currentValue && currentValue > 0) ||
                  (feedbackValue! > 0 && feedbackValue !== currentValue)
                ) {
                  iconClasses.push('not-selected')
                }
                if (hovering > 0 && value !== hovering) {
                  iconClasses.push('hovering')
                }
                return (
                  <span {...other}>
                    <span className={iconClasses.join(' ')} style={{ margin: '0 7px' }}>
                      {customIcons.find(item => item.value === value)!.icon}
                    </span>
                  </span>
                )
              }}
            />
          </div>
        )}
      </Paper>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={popoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div>{subtitle}</div>
      </Popover>
    </Wrapper>
  )
}

export default Feedback
