import {
  AcUnit,
  AccessAlarm,
  AccessAlarms,
  AccessTime,
  Accessibility,
  AccessibilityNew,
  Accessible,
  AccessibleForward,
  AccountBalance,
  AccountBalanceWallet,
  AccountBox,
  AccountCircle,
  AccountTree,
  Adb,
  Add,
  AddAPhoto,
  AddAlarm,
  AddAlert,
  AddBox,
  AddCircle,
  AddCircleOutline,
  AddComment,
  AddIcCall,
  AddLocation,
  AddPhotoAlternate,
  AddShoppingCart,
  AddToHomeScreen,
  AddToPhotos,
  AddToQueue,
  Adjust,
  AirlineSeatFlat,
  AirlineSeatFlatAngled,
  AirlineSeatIndividualSuite,
  AirlineSeatLegroomExtra,
  AirlineSeatLegroomNormal,
  AirlineSeatLegroomReduced,
  AirlineSeatReclineExtra,
  AirlineSeatReclineNormal,
  AirplanemodeActive,
  AirplanemodeInactive,
  Airplay,
  AirportShuttle,
  Alarm,
  AlarmAdd,
  AlarmOff,
  AlarmOn,
  Album,
  AllInbox,
  AllInclusive,
  AllOut,
  AlternateEmail,
  Android,
  Announcement,
  Apartment,
  Apple,
  Apps,
  Archive,
  ArrowBack,
  ArrowBackIos,
  ArrowDownward,
  ArrowDropDown,
  ArrowDropDownCircle,
  ArrowDropUp,
  ArrowForward,
  ArrowForwardIos,
  ArrowLeft,
  ArrowRight,
  ArrowRightAlt,
  ArrowUpward,
  ArtTrack,
  AspectRatio,
  Assessment,
  Assignment,
  AssignmentInd,
  AssignmentLate,
  AssignmentReturn,
  AssignmentReturned,
  AssignmentTurnedIn,
  Assistant,
  AssistantPhoto,
  Atm,
  AttachFile,
  AttachMoney,
  Attachment,
  Audiotrack,
  Autorenew,
  AvTimer,
  Backspace,
  Backup,
  Ballot,
  BarChart,
  Bathtub,
  Battery20,
  Battery30,
  Battery50,
  Battery60,
  Battery80,
  Battery90,
  BatteryAlert,
  BatteryCharging20,
  BatteryCharging30,
  BatteryCharging50,
  BatteryCharging60,
  BatteryCharging80,
  BatteryCharging90,
  BatteryChargingFull,
  BatteryFull,
  BatteryStd,
  BatteryUnknown,
  BeachAccess,
  Beenhere,
  Block,
  Bluetooth,
  BluetoothAudio,
  BluetoothConnected,
  BluetoothDisabled,
  BluetoothSearching,
  BlurCircular,
  BlurLinear,
  BlurOff,
  BlurOn,
  Book,
  Bookmark,
  BookmarkBorder,
  Bookmarks,
  BorderAll,
  BorderBottom,
  BorderClear,
  BorderColor,
  BorderHorizontal,
  BorderInner,
  BorderLeft,
  BorderOuter,
  BorderRight,
  BorderStyle,
  BorderTop,
  BorderVertical,
  BrandingWatermark,
  Brightness1,
  Brightness2,
  Brightness3,
  Brightness4,
  Brightness5,
  Brightness6,
  Brightness7,
  BrightnessAuto,
  BrightnessHigh,
  BrightnessLow,
  BrightnessMedium,
  BrokenImage,
  Brush,
  BubbleChart,
  BugReport,
  Build,
  BurstMode,
  Business,
  BusinessCenter,
  Cached,
  Cake,
  CalendarToday,
  CalendarViewDay,
  Call,
  CallEnd,
  CallMade,
  CallMerge,
  CallMissed,
  CallMissedOutgoing,
  CallReceived,
  CallSplit,
  CallToAction,
  Camera,
  CameraAlt,
  CameraEnhance,
  CameraFront,
  CameraRear,
  CameraRoll,
  Cancel,
  CancelPresentation,
  CancelScheduleSend,
  CardGiftcard,
  CardMembership,
  CardTravel,
  Casino,
  Cast,
  CastConnected,
  CastForEducation,
  Category,
  CellWifi,
  CenterFocusStrong,
  CenterFocusWeak,
  ChangeHistory,
  Chat,
  ChatBubble,
  ChatBubbleOutline,
  Check,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircle,
  CheckCircleOutline,
  ChevronLeft,
  ChevronRight,
  ChildCare,
  ChildFriendly,
  ChromeReaderMode,
  Class,
  Clear,
  ClearAll,
  Close,
  ClosedCaption,
  Cloud,
  CloudCircle,
  CloudDone,
  CloudDownload,
  CloudOff,
  CloudQueue,
  CloudUpload,
  Code,
  Collections,
  CollectionsBookmark,
  ColorLens,
  Colorize,
  Comment,
  Commute,
  Compare,
  CompareArrows,
  CompassCalibration,
  Computer,
  ConfirmationNumber,
  ContactMail,
  ContactPhone,
  ContactSupport,
  Contactless,
  Contacts,
  ControlCamera,
  ControlPoint,
  ControlPointDuplicate,
  Copyright,
  Create,
  CreateNewFolder,
  CreditCard,
  Crop,
  Crop169,
  Crop32,
  Crop54,
  Crop75,
  CropDin,
  CropFree,
  CropLandscape,
  CropOriginal,
  CropPortrait,
  CropRotate,
  CropSquare,
  Dashboard,
  DataUsage,
  DateRange,
  Deck,
  Dehaze,
  Delete,
  DeleteForever,
  DeleteOutline,
  DeleteSweep,
  DepartureBoard,
  Description,
  DesktopAccessDisabled,
  DesktopMac,
  DesktopWindows,
  Details,
  DeveloperBoard,
  DeveloperMode,
  DeviceHub,
  DeviceUnknown,
  Devices,
  DevicesOther,
  DialerSip,
  Dialpad,
  Directions,
  DirectionsBike,
  DirectionsBoat,
  DirectionsBus,
  DirectionsCar,
  DirectionsRailway,
  DirectionsRun,
  DirectionsSubway,
  DirectionsTransit,
  DirectionsWalk,
  DiscFull,
  Dns,
  Dock,
  Domain,
  DomainDisabled,
  Done,
  DoneAll,
  DoneOutline,
  DonutLarge,
  DonutSmall,
  DoubleArrow,
  Drafts,
  DragHandle,
  DragIndicator,
  DriveEta,
  Duo,
  Dvr,
  DynamicFeed,
  Edit,
  EditAttributes,
  EditLocation,
  Eject,
  Email,
  EmojiEmotions,
  EmojiEvents,
  EmojiFlags,
  EmojiFoodBeverage,
  EmojiNature,
  EmojiObjects,
  EmojiPeople,
  EmojiSymbols,
  EmojiTransportation,
  EnhancedEncryption,
  Equalizer,
  Error,
  ErrorOutline,
  Euro,
  EuroSymbol,
  EvStation,
  Event,
  EventAvailable,
  EventBusy,
  EventNote,
  EventSeat,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  Explicit,
  Explore,
  ExploreOff,
  Exposure,
  Extension,
  Face,
  Facebook,
  FastForward,
  FastRewind,
  Fastfood,
  Favorite,
  FavoriteBorder,
  FeaturedPlayList,
  FeaturedVideo,
  Feedback,
  FiberDvr,
  FiberManualRecord,
  FiberNew,
  FiberPin,
  FiberSmartRecord,
  FileCopy,
  Filter,
  Filter1,
  Filter2,
  Filter3,
  Filter4,
  Filter5,
  Filter6,
  Filter7,
  Filter8,
  Filter9,
  Filter9Plus,
  FilterBAndW,
  FilterCenterFocus,
  FilterDrama,
  FilterFrames,
  FilterHdr,
  FilterList,
  FilterNone,
  FilterTiltShift,
  FilterVintage,
  FindInPage,
  FindReplace,
  Fingerprint,
  Fireplace,
  FirstPage,
  FitnessCenter,
  Flag,
  Flare,
  FlashAuto,
  FlashOff,
  FlashOn,
  Flight,
  FlightLand,
  FlightTakeoff,
  Flip,
  FlipCameraAndroid,
  FlipCameraIos,
  FlipToBack,
  FlipToFront,
  Folder,
  FolderOpen,
  FolderShared,
  FolderSpecial,
  FontDownload,
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatClear,
  FormatColorFill,
  FormatColorReset,
  FormatColorText,
  FormatIndentDecrease,
  FormatIndentIncrease,
  FormatItalic,
  FormatLineSpacing,
  FormatListBulleted,
  FormatListNumbered,
  FormatListNumberedRtl,
  FormatPaint,
  FormatQuote,
  FormatShapes,
  FormatSize,
  FormatStrikethrough,
  FormatTextdirectionLToR,
  FormatTextdirectionRToL,
  FormatUnderlined,
  Forum,
  Forward,
  Forward10,
  Forward30,
  Forward5,
  FourK,
  FreeBreakfast,
  Fullscreen,
  FullscreenExit,
  Functions,
  GTranslate,
  Gamepad,
  Games,
  Gavel,
  Gesture,
  GetApp,
  Gif,
  GitHub,
  GolfCourse,
  GpsFixed,
  GpsNotFixed,
  GpsOff,
  Grade,
  Gradient,
  Grain,
  GraphicEq,
  GridOff,
  GridOn,
  Group,
  GroupAdd,
  GroupWork,
  Hd,
  HdrOff,
  HdrOn,
  HdrStrong,
  HdrWeak,
  Headset,
  HeadsetMic,
  Healing,
  Hearing,
  Height,
  Help,
  HelpOutline,
  HighQuality,
  Highlight,
  HighlightOff,
  History,
  Home,
  HomeWork,
  HorizontalSplit,
  HotTub,
  Hotel,
  HourglassEmpty,
  HourglassFull,
  House,
  HowToReg,
  HowToVote,
  Http,
  Https,
  Image,
  ImageAspectRatio,
  ImageSearch,
  ImportContacts,
  ImportExport,
  ImportantDevices,
  Inbox,
  IndeterminateCheckBox,
  Info,
  Input,
  InsertChart,
  InsertComment,
  InsertDriveFile,
  InsertEmoticon,
  InsertInvitation,
  InsertLink,
  InsertPhoto,
  Instagram,
  InvertColors,
  InvertColorsOff,
  Iso,
  Keyboard,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  KeyboardBackspace,
  KeyboardCapslock,
  KeyboardHide,
  KeyboardReturn,
  KeyboardTab,
  KeyboardVoice,
  KingBed,
  Kitchen,
  Label,
  LabelImportant,
  LabelOff,
  Landscape,
  Language,
  Laptop,
  LaptopChromebook,
  LaptopMac,
  LaptopWindows,
  LastPage,
  Launch,
  Layers,
  LayersClear,
  LeakAdd,
  LeakRemove,
  Lens,
  LibraryAdd,
  LibraryAddCheck,
  LibraryBooks,
  LibraryMusic,
  LineStyle,
  LineWeight,
  LinearScale,
  Link,
  LinkOff,
  LinkedCamera,
  LinkedIn,
  List,
  ListAlt,
  LiveHelp,
  LiveTv,
  LocalActivity,
  LocalAirport,
  LocalAtm,
  LocalBar,
  LocalCafe,
  LocalCarWash,
  LocalConvenienceStore,
  LocalDining,
  LocalDrink,
  LocalFlorist,
  LocalGasStation,
  LocalGroceryStore,
  LocalHospital,
  LocalHotel,
  LocalLaundryService,
  LocalLibrary,
  LocalMall,
  LocalMovies,
  LocalOffer,
  LocalParking,
  LocalPharmacy,
  LocalPhone,
  LocalPizza,
  LocalPlay,
  LocalPostOffice,
  LocalPrintshop,
  LocalSee,
  LocalShipping,
  LocalTaxi,
  LocationCity,
  LocationDisabled,
  LocationOff,
  LocationOn,
  LocationSearching,
  Lock,
  LockOpen,
  Looks,
  Looks3,
  Looks4,
  Looks5,
  Looks6,
  LooksOne,
  LooksTwo,
  Loop,
  Loupe,
  LowPriority,
  Loyalty,
  Mail,
  MailOutline,
  Map,
  Markunread,
  MarkunreadMailbox,
  Maximize,
  MeetingRoom,
  Memory,
  Menu,
  MenuBook,
  MenuOpen,
  MergeType,
  Message,
  Mic,
  MicNone,
  MicOff,
  Minimize,
  MissedVideoCall,
  Mms,
  MobileFriendly,
  MobileOff,
  MobileScreenShare,
  ModeComment,
  MonetizationOn,
  Money,
  MoneyOff,
  MonochromePhotos,
  Mood,
  MoodBad,
  More,
  MoreHoriz,
  MoreVert,
  Mouse,
  MoveToInbox,
  Movie,
  MovieCreation,
  MovieFilter,
  MultilineChart,
  Museum,
  MusicNote,
  MusicOff,
  MusicVideo,
  MyLocation,
  Nature,
  NaturePeople,
  NavigateBefore,
  NavigateNext,
  Navigation,
  NearMe,
  NetworkCell,
  NetworkCheck,
  NetworkLocked,
  NetworkWifi,
  NewReleases,
  NextWeek,
  Nfc,
  NightsStay,
  NoEncryption,
  NoMeetingRoom,
  NoSim,
  NotInterested,
  NotListedLocation,
  Note,
  NoteAdd,
  Notes,
  NotificationImportant,
  Notifications,
  NotificationsActive,
  NotificationsNone,
  NotificationsOff,
  NotificationsPaused,
  OfflineBolt,
  OfflinePin,
  OndemandVideo,
  Opacity,
  OpenInBrowser,
  OpenInNew,
  OpenWith,
  OutdoorGrill,
  Pages,
  Pageview,
  Palette,
  PanTool,
  Panorama,
  PanoramaFishEye,
  PanoramaHorizontal,
  PanoramaVertical,
  PanoramaWideAngle,
  PartyMode,
  Pause,
  PauseCircleFilled,
  PauseCircleOutline,
  PausePresentation,
  Payment,
  People,
  PeopleAlt,
  PeopleOutline,
  PermCameraMic,
  PermContactCalendar,
  PermDataSetting,
  PermDeviceInformation,
  PermIdentity,
  PermMedia,
  PermPhoneMsg,
  PermScanWifi,
  Person,
  PersonAdd,
  PersonAddDisabled,
  PersonOutline,
  PersonPin,
  PersonPinCircle,
  PersonalVideo,
  Pets,
  Phone,
  PhoneAndroid,
  PhoneBluetoothSpeaker,
  PhoneCallback,
  PhoneDisabled,
  PhoneEnabled,
  PhoneForwarded,
  PhoneInTalk,
  PhoneIphone,
  PhoneLocked,
  PhoneMissed,
  PhonePaused,
  Phonelink,
  PhonelinkErase,
  PhonelinkLock,
  PhonelinkOff,
  PhonelinkRing,
  PhonelinkSetup,
  Photo,
  PhotoAlbum,
  PhotoCamera,
  PhotoFilter,
  PhotoLibrary,
  PhotoSizeSelectActual,
  PhotoSizeSelectLarge,
  PhotoSizeSelectSmall,
  PictureAsPdf,
  PictureInPicture,
  PictureInPictureAlt,
  PieChart,
  PinDrop,
  Pinterest,
  Place,
  PlayArrow,
  PlayCircleFilled,
  PlayCircleFilledWhite,
  PlayCircleOutline,
  PlayForWork,
  PlaylistAdd,
  PlaylistAddCheck,
  PlaylistPlay,
  PlusOne,
  Policy,
  Poll,
  Pool,
  PortableWifiOff,
  Portrait,
  PostAdd,
  Power,
  PowerInput,
  PowerOff,
  PowerSettingsNew,
  PregnantWoman,
  PresentToAll,
  Print,
  PrintDisabled,
  PriorityHigh,
  Public,
  Publish,
  QueryBuilder,
  QuestionAnswer,
  Queue,
  QueueMusic,
  QueuePlayNext,
  Radio,
  RadioButtonChecked,
  RadioButtonUnchecked,
  RateReview,
  Receipt,
  RecentActors,
  RecordVoiceOver,
  Reddit,
  Redeem,
  Redo,
  Refresh,
  Remove,
  RemoveCircle,
  RemoveCircleOutline,
  RemoveFromQueue,
  RemoveRedEye,
  RemoveShoppingCart,
  Reorder,
  Repeat,
  RepeatOne,
  Replay,
  Replay10,
  Replay30,
  Replay5,
  Reply,
  ReplyAll,
  Report,
  ReportOff,
  ReportProblem,
  Restaurant,
  RestaurantMenu,
  Restore,
  RestoreFromTrash,
  RestorePage,
  RingVolume,
  Room,
  RoomService,
  Rotate90DegreesCcw,
  RotateLeft,
  RotateRight,
  Router,
  Rowing,
  RssFeed,
  RvHookup,
  Satellite,
  Save,
  SaveAlt,
  Scanner,
  ScatterPlot,
  Schedule,
  School,
  Score,
  ScreenLockLandscape,
  ScreenLockPortrait,
  ScreenLockRotation,
  ScreenRotation,
  ScreenShare,
  SdCard,
  SdStorage,
  Search,
  Security,
  SelectAll,
  Send,
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied,
  Settings,
  SettingsApplications,
  SettingsBackupRestore,
  SettingsBluetooth,
  SettingsBrightness,
  SettingsCell,
  SettingsEthernet,
  SettingsInputAntenna,
  SettingsInputComponent,
  SettingsInputComposite,
  SettingsInputHdmi,
  SettingsInputSvideo,
  SettingsOverscan,
  SettingsPhone,
  SettingsPower,
  SettingsRemote,
  SettingsSystemDaydream,
  SettingsVoice,
  Share,
  Shop,
  ShopTwo,
  ShoppingBasket,
  ShoppingCart,
  ShortText,
  ShowChart,
  Shuffle,
  ShutterSpeed,
  SignalCellular0Bar,
  SignalCellular1Bar,
  SignalCellular2Bar,
  SignalCellular3Bar,
  SignalCellular4Bar,
  SignalCellularAlt,
  SignalCellularConnectedNoInternet0Bar,
  SignalCellularConnectedNoInternet1Bar,
  SignalCellularConnectedNoInternet2Bar,
  SignalCellularConnectedNoInternet3Bar,
  SignalCellularConnectedNoInternet4Bar,
  SignalCellularNoSim,
  SignalCellularNull,
  SignalCellularOff,
  SignalWifi0Bar,
  SignalWifi1Bar,
  SignalWifi1BarLock,
  SignalWifi2Bar,
  SignalWifi2BarLock,
  SignalWifi3Bar,
  SignalWifi3BarLock,
  SignalWifi4Bar,
  SignalWifi4BarLock,
  SignalWifiOff,
  SimCard,
  SingleBed,
  SkipNext,
  SkipPrevious,
  Slideshow,
  SlowMotionVideo,
  Smartphone,
  SmokeFree,
  SmokingRooms,
  Sms,
  SmsFailed,
  Snooze,
  Sort,
  SortByAlpha,
  Spa,
  SpaceBar,
  Speaker,
  SpeakerGroup,
  SpeakerNotes,
  SpeakerNotesOff,
  SpeakerPhone,
  Speed,
  Spellcheck,
  Sports,
  SportsBaseball,
  SportsBasketball,
  SportsCricket,
  SportsEsports,
  SportsFootball,
  SportsGolf,
  SportsHandball,
  SportsHockey,
  SportsKabaddi,
  SportsMma,
  SportsMotorsports,
  SportsRugby,
  SportsSoccer,
  SportsTennis,
  SportsVolleyball,
  SquareFoot,
  Star,
  StarBorder,
  StarHalf,
  StarOutline,
  StarRate,
  Stars,
  StayCurrentLandscape,
  StayCurrentPortrait,
  StayPrimaryLandscape,
  StayPrimaryPortrait,
  Stop,
  StopScreenShare,
  Storage,
  Store,
  StoreMallDirectory,
  Storefront,
  Straighten,
  Streetview,
  StrikethroughS,
  Style,
  SubdirectoryArrowLeft,
  SubdirectoryArrowRight,
  Subject,
  Subscriptions,
  Subtitles,
  Subway,
  SupervisedUserCircle,
  SupervisorAccount,
  SurroundSound,
  SwapCalls,
  SwapHoriz,
  SwapHorizontalCircle,
  SwapVert,
  SwapVerticalCircle,
  SwitchCamera,
  SwitchVideo,
  Sync,
  SyncAlt,
  SyncDisabled,
  SyncProblem,
  SystemUpdate,
  SystemUpdateAlt,
  Tab,
  TabUnselected,
  TableChart,
  Tablet,
  TabletAndroid,
  TabletMac,
  TagFaces,
  TapAndPlay,
  Telegram,
  Terrain,
  TextFields,
  TextFormat,
  TextRotateUp,
  TextRotateVertical,
  TextRotationAngledown,
  TextRotationAngleup,
  TextRotationDown,
  TextRotationNone,
  Textsms,
  Texture,
  Theaters,
  ThreeDRotation,
  ThreeSixty,
  ThumbDown,
  ThumbDownAlt,
  ThumbUp,
  ThumbUpAlt,
  ThumbsUpDown,
  TimeToLeave,
  Timelapse,
  Timeline,
  Timer,
  Timer10,
  Timer3,
  TimerOff,
  Title,
  Toc,
  Today,
  ToggleOff,
  ToggleOn,
  Toll,
  Tonality,
  TouchApp,
  Toys,
  TrackChanges,
  Traffic,
  Train,
  Tram,
  TransferWithinAStation,
  Transform,
  TransitEnterexit,
  Translate,
  TrendingDown,
  TrendingFlat,
  TrendingUp,
  TripOrigin,
  Tune,
  TurnedIn,
  TurnedInNot,
  Tv,
  TvOff,
  Twitter,
  TwoWheeler,
  Unarchive,
  Undo,
  UnfoldLess,
  UnfoldMore,
  Unsubscribe,
  Update,
  Usb,
  VerifiedUser,
  VerticalAlignBottom,
  VerticalAlignCenter,
  VerticalAlignTop,
  VerticalSplit,
  Vibration,
  VideoCall,
  VideoLabel,
  VideoLibrary,
  Videocam,
  VideocamOff,
  VideogameAsset,
  ViewAgenda,
  ViewArray,
  ViewCarousel,
  ViewColumn,
  ViewComfy,
  ViewCompact,
  ViewDay,
  ViewHeadline,
  ViewList,
  ViewModule,
  ViewQuilt,
  ViewStream,
  ViewWeek,
  Vignette,
  Visibility,
  VisibilityOff,
  VoiceChat,
  VoiceOverOff,
  Voicemail,
  VolumeDown,
  VolumeMute,
  VolumeOff,
  VolumeUp,
  VpnKey,
  VpnLock,
  Wallpaper,
  Warning,
  Watch,
  WatchLater,
  Waves,
  WbAuto,
  WbCloudy,
  WbIncandescent,
  WbIridescent,
  WbSunny,
  Wc,
  Web,
  WebAsset,
  Weekend,
  WhatsApp,
  Whatshot,
  WhereToVote,
  Widgets,
  Wifi,
  WifiLock,
  WifiOff,
  WifiTethering,
  Work,
  WorkOff,
  WorkOutline,
  WrapText,
  YouTube,
  YoutubeSearchedFor,
  ZoomIn,
  ZoomOut,
  ZoomOutMap,
  AcUnitOutlined,
  AccessAlarmOutlined,
  AccessAlarmsOutlined,
  AccessTimeOutlined,
  AccessibilityNewOutlined,
  AccessibilityOutlined,
  AccessibleForwardOutlined,
  AccessibleOutlined,
  AccountBalanceOutlined,
  AccountBalanceWalletOutlined,
  AccountBoxOutlined,
  AccountCircleOutlined,
  AccountTreeOutlined,
  AdbOutlined,
  AddAPhotoOutlined,
  AddAlarmOutlined,
  AddAlertOutlined,
  AddBoxOutlined,
  AddCircleOutlineOutlined,
  AddCircleOutlined,
  AddCommentOutlined,
  AddIcCallOutlined,
  AddLocationOutlined,
  AddOutlined,
  AddPhotoAlternateOutlined,
  AddShoppingCartOutlined,
  AddToHomeScreenOutlined,
  AddToPhotosOutlined,
  AddToQueueOutlined,
  AdjustOutlined,
  AirlineSeatFlatAngledOutlined,
  AirlineSeatFlatOutlined,
  AirlineSeatIndividualSuiteOutlined,
  AirlineSeatLegroomExtraOutlined,
  AirlineSeatLegroomNormalOutlined,
  AirlineSeatLegroomReducedOutlined,
  AirlineSeatReclineExtraOutlined,
  AirlineSeatReclineNormalOutlined,
  AirplanemodeActiveOutlined,
  AirplanemodeInactiveOutlined,
  AirplayOutlined,
  AirportShuttleOutlined,
  AlarmAddOutlined,
  AlarmOffOutlined,
  AlarmOnOutlined,
  AlarmOutlined,
  AlbumOutlined,
  AllInboxOutlined,
  AllInclusiveOutlined,
  AllOutOutlined,
  AlternateEmailOutlined,
  AndroidOutlined,
  AnnouncementOutlined,
  ApartmentOutlined,
  AppsOutlined,
  ArchiveOutlined,
  ArrowBackIosOutlined,
  ArrowBackOutlined,
  ArrowDownwardOutlined,
  ArrowDropDownCircleOutlined,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  ArrowForwardIosOutlined,
  ArrowForwardOutlined,
  ArrowLeftOutlined,
  ArrowRightAltOutlined,
  ArrowRightOutlined,
  ArrowUpwardOutlined,
  ArtTrackOutlined,
  AspectRatioOutlined,
  AssessmentOutlined,
  AssignmentIndOutlined,
  AssignmentLateOutlined,
  AssignmentOutlined,
  AssignmentReturnOutlined,
  AssignmentReturnedOutlined,
  AssignmentTurnedInOutlined,
  AssistantOutlined,
  AssistantPhotoOutlined,
  AtmOutlined,
  AttachFileOutlined,
  AttachMoneyOutlined,
  AttachmentOutlined,
  AudiotrackOutlined,
  AutorenewOutlined,
  AvTimerOutlined,
  BackspaceOutlined,
  BackupOutlined,
  BallotOutlined,
  BarChartOutlined,
  BathtubOutlined,
  Battery20Outlined,
  Battery30Outlined,
  Battery50Outlined,
  Battery60Outlined,
  Battery80Outlined,
  Battery90Outlined,
  BatteryAlertOutlined,
  BatteryCharging20Outlined,
  BatteryCharging30Outlined,
  BatteryCharging50Outlined,
  BatteryCharging60Outlined,
  BatteryCharging80Outlined,
  BatteryCharging90Outlined,
  BatteryChargingFullOutlined,
  BatteryFullOutlined,
  BatteryStdOutlined,
  BatteryUnknownOutlined,
  BeachAccessOutlined,
  BeenhereOutlined,
  BlockOutlined,
  BluetoothAudioOutlined,
  BluetoothConnectedOutlined,
  BluetoothDisabledOutlined,
  BluetoothOutlined,
  BluetoothSearchingOutlined,
  BlurCircularOutlined,
  BlurLinearOutlined,
  BlurOffOutlined,
  BlurOnOutlined,
  BookOutlined,
  BookmarkBorderOutlined,
  BookmarkOutlined,
  BookmarksOutlined,
  BorderAllOutlined,
  BorderBottomOutlined,
  BorderClearOutlined,
  BorderColorOutlined,
  BorderHorizontalOutlined,
  BorderInnerOutlined,
  BorderLeftOutlined,
  BorderOuterOutlined,
  BorderRightOutlined,
  BorderStyleOutlined,
  BorderTopOutlined,
  BorderVerticalOutlined,
  BrandingWatermarkOutlined,
  Brightness1Outlined,
  Brightness2Outlined,
  Brightness3Outlined,
  Brightness4Outlined,
  Brightness5Outlined,
  Brightness6Outlined,
  Brightness7Outlined,
  BrightnessAutoOutlined,
  BrightnessHighOutlined,
  BrightnessLowOutlined,
  BrightnessMediumOutlined,
  BrokenImageOutlined,
  BrushOutlined,
  BubbleChartOutlined,
  BugReportOutlined,
  BuildOutlined,
  BurstModeOutlined,
  BusinessCenterOutlined,
  BusinessOutlined,
  CachedOutlined,
  CakeOutlined,
  CalendarTodayOutlined,
  CalendarViewDayOutlined,
  CallEndOutlined,
  CallMadeOutlined,
  CallMergeOutlined,
  CallMissedOutgoingOutlined,
  CallMissedOutlined,
  CallOutlined,
  CallReceivedOutlined,
  CallSplitOutlined,
  CallToActionOutlined,
  CameraAltOutlined,
  CameraEnhanceOutlined,
  CameraFrontOutlined,
  CameraOutlined,
  CameraRearOutlined,
  CameraRollOutlined,
  CancelOutlined,
  CancelPresentationOutlined,
  CancelScheduleSendOutlined,
  CardGiftcardOutlined,
  CardMembershipOutlined,
  CardTravelOutlined,
  CasinoOutlined,
  CastConnectedOutlined,
  CastForEducationOutlined,
  CastOutlined,
  CategoryOutlined,
  CellWifiOutlined,
  CenterFocusStrongOutlined,
  CenterFocusWeakOutlined,
  ChangeHistoryOutlined,
  ChatBubbleOutlineOutlined,
  ChatBubbleOutlined,
  ChatOutlined,
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
  CheckCircleOutlineOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  ChevronLeftOutlined,
  ChevronRightOutlined,
  ChildCareOutlined,
  ChildFriendlyOutlined,
  ChromeReaderModeOutlined,
  ClassOutlined,
  ClearAllOutlined,
  ClearOutlined,
  CloseOutlined,
  ClosedCaptionOutlined,
  CloudCircleOutlined,
  CloudDoneOutlined,
  CloudDownloadOutlined,
  CloudOffOutlined,
  CloudOutlined,
  CloudQueueOutlined,
  CloudUploadOutlined,
  CodeOutlined,
  CollectionsBookmarkOutlined,
  CollectionsOutlined,
  ColorLensOutlined,
  ColorizeOutlined,
  CommentOutlined,
  CommuteOutlined,
  CompareArrowsOutlined,
  CompareOutlined,
  CompassCalibrationOutlined,
  ComputerOutlined,
  ConfirmationNumberOutlined,
  ContactMailOutlined,
  ContactPhoneOutlined,
  ContactSupportOutlined,
  ContactlessOutlined,
  ContactsOutlined,
  ControlCameraOutlined,
  ControlPointDuplicateOutlined,
  ControlPointOutlined,
  CopyrightOutlined,
  CreateNewFolderOutlined,
  CreateOutlined,
  CreditCardOutlined,
  Crop169Outlined,
  Crop32Outlined,
  Crop54Outlined,
  Crop75Outlined,
  CropDinOutlined,
  CropFreeOutlined,
  CropLandscapeOutlined,
  CropOriginalOutlined,
  CropOutlined,
  CropPortraitOutlined,
  CropRotateOutlined,
  CropSquareOutlined,
  DashboardOutlined,
  DataUsageOutlined,
  DateRangeOutlined,
  DeckOutlined,
  DehazeOutlined,
  DeleteForeverOutlined,
  DeleteOutlineOutlined,
  DeleteOutlined,
  DeleteSweepOutlined,
  DepartureBoardOutlined,
  DescriptionOutlined,
  DesktopAccessDisabledOutlined,
  DesktopMacOutlined,
  DesktopWindowsOutlined,
  DetailsOutlined,
  DeveloperBoardOutlined,
  DeveloperModeOutlined,
  DeviceHubOutlined,
  DeviceUnknownOutlined,
  DevicesOtherOutlined,
  DevicesOutlined,
  DialerSipOutlined,
  DialpadOutlined,
  DirectionsBikeOutlined,
  DirectionsBoatOutlined,
  DirectionsBusOutlined,
  DirectionsCarOutlined,
  DirectionsOutlined,
  DirectionsRailwayOutlined,
  DirectionsRunOutlined,
  DirectionsSubwayOutlined,
  DirectionsTransitOutlined,
  DirectionsWalkOutlined,
  DiscFullOutlined,
  DnsOutlined,
  DockOutlined,
  DomainDisabledOutlined,
  DomainOutlined,
  DoneAllOutlined,
  DoneOutlineOutlined,
  DoneOutlined,
  DonutLargeOutlined,
  DonutSmallOutlined,
  DoubleArrowOutlined,
  DraftsOutlined,
  DragHandleOutlined,
  DragIndicatorOutlined,
  DriveEtaOutlined,
  DuoOutlined,
  DvrOutlined,
  DynamicFeedOutlined,
  EditAttributesOutlined,
  EditLocationOutlined,
  EditOutlined,
  EjectOutlined,
  EmailOutlined,
  EmojiEmotionsOutlined,
  EmojiEventsOutlined,
  EmojiFlagsOutlined,
  EmojiFoodBeverageOutlined,
  EmojiNatureOutlined,
  EmojiObjectsOutlined,
  EmojiPeopleOutlined,
  EmojiSymbolsOutlined,
  EmojiTransportationOutlined,
  EnhancedEncryptionOutlined,
  EqualizerOutlined,
  ErrorOutlineOutlined,
  ErrorOutlined,
  EuroOutlined,
  EuroSymbolOutlined,
  EvStationOutlined,
  EventAvailableOutlined,
  EventBusyOutlined,
  EventNoteOutlined,
  EventOutlined,
  EventSeatOutlined,
  ExitToAppOutlined,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  ExplicitOutlined,
  ExploreOffOutlined,
  ExploreOutlined,
  ExposureOutlined,
  ExtensionOutlined,
  FaceOutlined,
  FastForwardOutlined,
  FastRewindOutlined,
  FastfoodOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  FeaturedPlayListOutlined,
  FeaturedVideoOutlined,
  FeedbackOutlined,
  FiberDvrOutlined,
  FiberManualRecordOutlined,
  FiberNewOutlined,
  FiberPinOutlined,
  FiberSmartRecordOutlined,
  FileCopyOutlined,
  Filter1Outlined,
  Filter2Outlined,
  Filter3Outlined,
  Filter4Outlined,
  Filter5Outlined,
  Filter6Outlined,
  Filter7Outlined,
  Filter8Outlined,
  Filter9Outlined,
  Filter9PlusOutlined,
  FilterBAndWOutlined,
  FilterCenterFocusOutlined,
  FilterDramaOutlined,
  FilterFramesOutlined,
  FilterHdrOutlined,
  FilterListOutlined,
  FilterNoneOutlined,
  FilterOutlined,
  FilterTiltShiftOutlined,
  FilterVintageOutlined,
  FindInPageOutlined,
  FindReplaceOutlined,
  FingerprintOutlined,
  FireplaceOutlined,
  FirstPageOutlined,
  FitnessCenterOutlined,
  FlagOutlined,
  FlareOutlined,
  FlashAutoOutlined,
  FlashOffOutlined,
  FlashOnOutlined,
  FlightLandOutlined,
  FlightOutlined,
  FlightTakeoffOutlined,
  FlipCameraAndroidOutlined,
  FlipCameraIosOutlined,
  FlipOutlined,
  FlipToBackOutlined,
  FlipToFrontOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  FolderSharedOutlined,
  FolderSpecialOutlined,
  FontDownloadOutlined,
  FormatAlignCenterOutlined,
  FormatAlignJustifyOutlined,
  FormatAlignLeftOutlined,
  FormatAlignRightOutlined,
  FormatBoldOutlined,
  FormatClearOutlined,
  FormatColorFillOutlined,
  FormatColorResetOutlined,
  FormatColorTextOutlined,
  FormatIndentDecreaseOutlined,
  FormatIndentIncreaseOutlined,
  FormatItalicOutlined,
  FormatLineSpacingOutlined,
  FormatListBulletedOutlined,
  FormatListNumberedOutlined,
  FormatListNumberedRtlOutlined,
  FormatPaintOutlined,
  FormatQuoteOutlined,
  FormatShapesOutlined,
  FormatSizeOutlined,
  FormatStrikethroughOutlined,
  FormatTextdirectionLToROutlined,
  FormatTextdirectionRToLOutlined,
  FormatUnderlinedOutlined,
  ForumOutlined,
  Forward10Outlined,
  Forward30Outlined,
  Forward5Outlined,
  ForwardOutlined,
  FourKOutlined,
  FreeBreakfastOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  FunctionsOutlined,
  GTranslateOutlined,
  GamepadOutlined,
  GamesOutlined,
  GavelOutlined,
  GestureOutlined,
  GetAppOutlined,
  GifOutlined,
  GolfCourseOutlined,
  GpsFixedOutlined,
  GpsNotFixedOutlined,
  GpsOffOutlined,
  GradeOutlined,
  GradientOutlined,
  GrainOutlined,
  GraphicEqOutlined,
  GridOffOutlined,
  GridOnOutlined,
  GroupAddOutlined,
  GroupOutlined,
  GroupWorkOutlined,
  HdOutlined,
  HdrOffOutlined,
  HdrOnOutlined,
  HdrStrongOutlined,
  HdrWeakOutlined,
  HeadsetMicOutlined,
  HeadsetOutlined,
  HealingOutlined,
  HearingOutlined,
  HeightOutlined,
  HelpOutlineOutlined,
  HelpOutlined,
  HighQualityOutlined,
  HighlightOffOutlined,
  HighlightOutlined,
  HistoryOutlined,
  HomeOutlined,
  HomeWorkOutlined,
  HorizontalSplitOutlined,
  HotTubOutlined,
  HotelOutlined,
  HourglassEmptyOutlined,
  HourglassFullOutlined,
  HouseOutlined,
  HowToRegOutlined,
  HowToVoteOutlined,
  HttpOutlined,
  HttpsOutlined,
  ImageAspectRatioOutlined,
  ImageOutlined,
  ImageSearchOutlined,
  ImportContactsOutlined,
  ImportExportOutlined,
  ImportantDevicesOutlined,
  InboxOutlined,
  IndeterminateCheckBoxOutlined,
  InfoOutlined,
  InputOutlined,
  InsertChartOutlined,
  InsertChartOutlinedOutlined,
  InsertChartOutlinedRounded,
  InsertChartOutlinedSharp,
  InsertChartOutlinedTwoTone,
  InsertCommentOutlined,
  InsertDriveFileOutlined,
  InsertEmoticonOutlined,
  InsertInvitationOutlined,
  InsertLinkOutlined,
  InsertPhotoOutlined,
  InvertColorsOffOutlined,
  InvertColorsOutlined,
  IsoOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
  KeyboardArrowUpOutlined,
  KeyboardBackspaceOutlined,
  KeyboardCapslockOutlined,
  KeyboardHideOutlined,
  KeyboardOutlined,
  KeyboardReturnOutlined,
  KeyboardTabOutlined,
  KeyboardVoiceOutlined,
  KingBedOutlined,
  KitchenOutlined,
  LabelImportantOutlined,
  LabelOffOutlined,
  LabelOutlined,
  LandscapeOutlined,
  LanguageOutlined,
  LaptopChromebookOutlined,
  LaptopMacOutlined,
  LaptopOutlined,
  LaptopWindowsOutlined,
  LastPageOutlined,
  LaunchOutlined,
  LayersClearOutlined,
  LayersOutlined,
  LeakAddOutlined,
  LeakRemoveOutlined,
  LensOutlined,
  LibraryAddCheckOutlined,
  LibraryAddOutlined,
  LibraryBooksOutlined,
  LibraryMusicOutlined,
  LineStyleOutlined,
  LineWeightOutlined,
  LinearScaleOutlined,
  LinkOffOutlined,
  LinkOutlined,
  LinkedCameraOutlined,
  ListAltOutlined,
  ListOutlined,
  LiveHelpOutlined,
  LiveTvOutlined,
  LocalActivityOutlined,
  LocalAirportOutlined,
  LocalAtmOutlined,
  LocalBarOutlined,
  LocalCafeOutlined,
  LocalCarWashOutlined,
  LocalConvenienceStoreOutlined,
  LocalDiningOutlined,
  LocalDrinkOutlined,
  LocalFloristOutlined,
  LocalGasStationOutlined,
  LocalGroceryStoreOutlined,
  LocalHospitalOutlined,
  LocalHotelOutlined,
  LocalLaundryServiceOutlined,
  LocalLibraryOutlined,
  LocalMallOutlined,
  LocalMoviesOutlined,
  LocalOfferOutlined,
  LocalParkingOutlined,
  LocalPharmacyOutlined,
  LocalPhoneOutlined,
  LocalPizzaOutlined,
  LocalPlayOutlined,
  LocalPostOfficeOutlined,
  LocalPrintshopOutlined,
  LocalSeeOutlined,
  LocalShippingOutlined,
  LocalTaxiOutlined,
  LocationCityOutlined,
  LocationDisabledOutlined,
  LocationOffOutlined,
  LocationOnOutlined,
  LocationSearchingOutlined,
  LockOpenOutlined,
  LockOutlined,
  Looks3Outlined,
  Looks4Outlined,
  Looks5Outlined,
  Looks6Outlined,
  LooksOneOutlined,
  LooksOutlined,
  LooksTwoOutlined,
  LoopOutlined,
  LoupeOutlined,
  LowPriorityOutlined,
  LoyaltyOutlined,
  MailOutlineOutlined,
  MailOutlined,
  MapOutlined,
  MarkunreadMailboxOutlined,
  MarkunreadOutlined,
  MaximizeOutlined,
  MeetingRoomOutlined,
  MemoryOutlined,
  MenuBookOutlined,
  MenuOpenOutlined,
  MenuOutlined,
  MergeTypeOutlined,
  MessageOutlined,
  MicNoneOutlined,
  MicOffOutlined,
  MicOutlined,
  MinimizeOutlined,
  MissedVideoCallOutlined,
  MmsOutlined,
  MobileFriendlyOutlined,
  MobileOffOutlined,
  MobileScreenShareOutlined,
  ModeCommentOutlined,
  MonetizationOnOutlined,
  MoneyOffOutlined,
  MoneyOutlined,
  MonochromePhotosOutlined,
  MoodBadOutlined,
  MoodOutlined,
  MoreHorizOutlined,
  MoreOutlined,
  MoreVertOutlined,
  MouseOutlined,
  MoveToInboxOutlined,
  MovieCreationOutlined,
  MovieFilterOutlined,
  MovieOutlined,
  MultilineChartOutlined,
  MuseumOutlined,
  MusicNoteOutlined,
  MusicOffOutlined,
  MusicVideoOutlined,
  MyLocationOutlined,
  NatureOutlined,
  NaturePeopleOutlined,
  NavigateBeforeOutlined,
  NavigateNextOutlined,
  NavigationOutlined,
  NearMeOutlined,
  NetworkCellOutlined,
  NetworkCheckOutlined,
  NetworkLockedOutlined,
  NetworkWifiOutlined,
  NewReleasesOutlined,
  NextWeekOutlined,
  NfcOutlined,
  NightsStayOutlined,
  NoEncryptionOutlined,
  NoMeetingRoomOutlined,
  NoSimOutlined,
  NotInterestedOutlined,
  NotListedLocationOutlined,
  NoteAddOutlined,
  NoteOutlined,
  NotesOutlined,
  NotificationImportantOutlined,
  NotificationsActiveOutlined,
  NotificationsNoneOutlined,
  NotificationsOffOutlined,
  NotificationsOutlined,
  NotificationsPausedOutlined,
  OfflineBoltOutlined,
  OfflinePinOutlined,
  OndemandVideoOutlined,
  OpacityOutlined,
  OpenInBrowserOutlined,
  OpenInNewOutlined,
  OpenWithOutlined,
  OutdoorGrillOutlined,
  OutlinedFlag,
  OutlinedFlagOutlined,
  OutlinedFlagRounded,
  OutlinedFlagSharp,
  OutlinedFlagTwoTone,
  PagesOutlined,
  PageviewOutlined,
  PaletteOutlined,
  PanToolOutlined,
  PanoramaFishEyeOutlined,
  PanoramaHorizontalOutlined,
  PanoramaOutlined,
  PanoramaVerticalOutlined,
  PanoramaWideAngleOutlined,
  PartyModeOutlined,
  PauseCircleFilledOutlined,
  PauseCircleOutlineOutlined,
  PauseOutlined,
  PausePresentationOutlined,
  PaymentOutlined,
  PeopleAltOutlined,
  PeopleOutlineOutlined,
  PeopleOutlined,
  PermCameraMicOutlined,
  PermContactCalendarOutlined,
  PermDataSettingOutlined,
  PermDeviceInformationOutlined,
  PermIdentityOutlined,
  PermMediaOutlined,
  PermPhoneMsgOutlined,
  PermScanWifiOutlined,
  PersonAddDisabledOutlined,
  PersonAddOutlined,
  PersonOutlineOutlined,
  PersonOutlined,
  PersonPinCircleOutlined,
  PersonPinOutlined,
  PersonalVideoOutlined,
  PetsOutlined,
  PhoneAndroidOutlined,
  PhoneBluetoothSpeakerOutlined,
  PhoneCallbackOutlined,
  PhoneDisabledOutlined,
  PhoneEnabledOutlined,
  PhoneForwardedOutlined,
  PhoneInTalkOutlined,
  PhoneIphoneOutlined,
  PhoneLockedOutlined,
  PhoneMissedOutlined,
  PhoneOutlined,
  PhonePausedOutlined,
  PhonelinkEraseOutlined,
  PhonelinkLockOutlined,
  PhonelinkOffOutlined,
  PhonelinkOutlined,
  PhonelinkRingOutlined,
  PhonelinkSetupOutlined,
  PhotoAlbumOutlined,
  PhotoCameraOutlined,
  PhotoFilterOutlined,
  PhotoLibraryOutlined,
  PhotoOutlined,
  PhotoSizeSelectActualOutlined,
  PhotoSizeSelectLargeOutlined,
  PhotoSizeSelectSmallOutlined,
  PictureAsPdfOutlined,
  PictureInPictureAltOutlined,
  PictureInPictureOutlined,
  PieChartOutlined,
  PinDropOutlined,
  PlaceOutlined,
  PlayArrowOutlined,
  PlayCircleFilledOutlined,
  PlayCircleFilledWhiteOutlined,
  PlayCircleOutlineOutlined,
  PlayForWorkOutlined,
  PlaylistAddCheckOutlined,
  PlaylistAddOutlined,
  PlaylistPlayOutlined,
  PlusOneOutlined,
  PolicyOutlined,
  PollOutlined,
  PoolOutlined,
  PortableWifiOffOutlined,
  PortraitOutlined,
  PostAddOutlined,
  PowerInputOutlined,
  PowerOffOutlined,
  PowerOutlined,
  PowerSettingsNewOutlined,
  PregnantWomanOutlined,
  PresentToAllOutlined,
  PrintDisabledOutlined,
  PrintOutlined,
  PriorityHighOutlined,
  PublicOutlined,
  PublishOutlined,
  QueryBuilderOutlined,
  QuestionAnswerOutlined,
  QueueMusicOutlined,
  QueueOutlined,
  QueuePlayNextOutlined,
  RadioButtonCheckedOutlined,
  RadioButtonUncheckedOutlined,
  RadioOutlined,
  RateReviewOutlined,
  ReceiptOutlined,
  RecentActorsOutlined,
  RecordVoiceOverOutlined,
  RedeemOutlined,
  RedoOutlined,
  RefreshOutlined,
  RemoveCircleOutlineOutlined,
  RemoveCircleOutlined,
  RemoveFromQueueOutlined,
  RemoveOutlined,
  RemoveRedEyeOutlined,
  RemoveShoppingCartOutlined,
  ReorderOutlined,
  RepeatOneOutlined,
  RepeatOutlined,
  Replay10Outlined,
  Replay30Outlined,
  Replay5Outlined,
  ReplayOutlined,
  ReplyAllOutlined,
  ReplyOutlined,
  ReportOffOutlined,
  ReportOutlined,
  ReportProblemOutlined,
  RestaurantMenuOutlined,
  RestaurantOutlined,
  RestoreFromTrashOutlined,
  RestoreOutlined,
  RestorePageOutlined,
  RingVolumeOutlined,
  RoomOutlined,
  RoomServiceOutlined,
  Rotate90DegreesCcwOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  RoundedCornerOutlined,
  RouterOutlined,
  RowingOutlined,
  RssFeedOutlined,
  RvHookupOutlined,
  SatelliteOutlined,
  SaveAltOutlined,
  SaveOutlined,
  ScannerOutlined,
  ScatterPlotOutlined,
  ScheduleOutlined,
  SchoolOutlined,
  ScoreOutlined,
  ScreenLockLandscapeOutlined,
  ScreenLockPortraitOutlined,
  ScreenLockRotationOutlined,
  ScreenRotationOutlined,
  ScreenShareOutlined,
  SdCardOutlined,
  SdStorageOutlined,
  SearchOutlined,
  SecurityOutlined,
  SelectAllOutlined,
  SendOutlined,
  SentimentDissatisfiedOutlined,
  SentimentSatisfiedAltOutlined,
  SentimentSatisfiedOutlined,
  SentimentVeryDissatisfiedOutlined,
  SentimentVerySatisfiedOutlined,
  SettingsApplicationsOutlined,
  SettingsBackupRestoreOutlined,
  SettingsBluetoothOutlined,
  SettingsBrightnessOutlined,
  SettingsCellOutlined,
  SettingsEthernetOutlined,
  SettingsInputAntennaOutlined,
  SettingsInputComponentOutlined,
  SettingsInputCompositeOutlined,
  SettingsInputHdmiOutlined,
  SettingsInputSvideoOutlined,
  SettingsOutlined,
  SettingsOverscanOutlined,
  SettingsPhoneOutlined,
  SettingsPowerOutlined,
  SettingsRemoteOutlined,
  SettingsSystemDaydreamOutlined,
  SettingsVoiceOutlined,
  ShareOutlined,
  ShopOutlined,
  ShopTwoOutlined,
  ShoppingBasketOutlined,
  ShoppingCartOutlined,
  ShortTextOutlined,
  ShowChartOutlined,
  ShuffleOutlined,
  ShutterSpeedOutlined,
  SignalCellular0BarOutlined,
  SignalCellular1BarOutlined,
  SignalCellular2BarOutlined,
  SignalCellular3BarOutlined,
  SignalCellular4BarOutlined,
  SignalCellularAltOutlined,
  SignalCellularConnectedNoInternet0BarOutlined,
  SignalCellularConnectedNoInternet1BarOutlined,
  SignalCellularConnectedNoInternet2BarOutlined,
  SignalCellularConnectedNoInternet3BarOutlined,
  SignalCellularConnectedNoInternet4BarOutlined,
  SignalCellularNoSimOutlined,
  SignalCellularNullOutlined,
  SignalCellularOffOutlined,
  SignalWifi0BarOutlined,
  SignalWifi1BarLockOutlined,
  SignalWifi1BarOutlined,
  SignalWifi2BarLockOutlined,
  SignalWifi2BarOutlined,
  SignalWifi3BarLockOutlined,
  SignalWifi3BarOutlined,
  SignalWifi4BarLockOutlined,
  SignalWifi4BarOutlined,
  SignalWifiOffOutlined,
  SimCardOutlined,
  SingleBedOutlined,
  SkipNextOutlined,
  SkipPreviousOutlined,
  SlideshowOutlined,
  SlowMotionVideoOutlined,
  SmartphoneOutlined,
  SmokeFreeOutlined,
  SmokingRoomsOutlined,
  SmsFailedOutlined,
  SmsOutlined,
  SnoozeOutlined,
  SortByAlphaOutlined,
  SortOutlined,
  SpaOutlined,
  SpaceBarOutlined,
  SpeakerGroupOutlined,
  SpeakerNotesOffOutlined,
  SpeakerNotesOutlined,
  SpeakerOutlined,
  SpeakerPhoneOutlined,
  SpeedOutlined,
  SpellcheckOutlined,
  SportsBaseballOutlined,
  SportsBasketballOutlined,
  SportsCricketOutlined,
  SportsEsportsOutlined,
  SportsFootballOutlined,
  SportsGolfOutlined,
  SportsHandballOutlined,
  SportsHockeyOutlined,
  SportsKabaddiOutlined,
  SportsMmaOutlined,
  SportsMotorsportsOutlined,
  SportsOutlined,
  SportsRugbyOutlined,
  SportsSoccerOutlined,
  SportsTennisOutlined,
  SportsVolleyballOutlined,
  SquareFootOutlined,
  StarBorderOutlined,
  StarHalfOutlined,
  StarOutlineOutlined,
  StarOutlined,
  StarRateOutlined,
  StarsOutlined,
  StayCurrentLandscapeOutlined,
  StayCurrentPortraitOutlined,
  StayPrimaryLandscapeOutlined,
  StayPrimaryPortraitOutlined,
  StopOutlined,
  StopScreenShareOutlined,
  StorageOutlined,
  StoreMallDirectoryOutlined,
  StoreOutlined,
  StorefrontOutlined,
  StraightenOutlined,
  StreetviewOutlined,
  StrikethroughSOutlined,
  StyleOutlined,
  SubdirectoryArrowLeftOutlined,
  SubdirectoryArrowRightOutlined,
  SubjectOutlined,
  SubscriptionsOutlined,
  SubtitlesOutlined,
  SubwayOutlined,
  SupervisedUserCircleOutlined,
  SupervisorAccountOutlined,
  SurroundSoundOutlined,
  SwapCallsOutlined,
  SwapHorizOutlined,
  SwapHorizontalCircleOutlined,
  SwapVertOutlined,
  SwapVerticalCircleOutlined,
  SwitchCameraOutlined,
  SwitchVideoOutlined,
  SyncAltOutlined,
  SyncDisabledOutlined,
  SyncOutlined,
  SyncProblemOutlined,
  SystemUpdateAltOutlined,
  SystemUpdateOutlined,
  TabOutlined,
  TabUnselectedOutlined,
  TableChartOutlined,
  TabletAndroidOutlined,
  TabletMacOutlined,
  TabletOutlined,
  TagFacesOutlined,
  TapAndPlayOutlined,
  TerrainOutlined,
  TextFieldsOutlined,
  TextFormatOutlined,
  TextRotateUpOutlined,
  TextRotateVerticalOutlined,
  TextRotationAngledownOutlined,
  TextRotationAngleupOutlined,
  TextRotationDownOutlined,
  TextRotationNoneOutlined,
  TextsmsOutlined,
  TextureOutlined,
  TheatersOutlined,
  ThreeDRotationOutlined,
  ThreeSixtyOutlined,
  ThumbDownAltOutlined,
  ThumbDownOutlined,
  ThumbUpAltOutlined,
  ThumbUpOutlined,
  ThumbsUpDownOutlined,
  TimeToLeaveOutlined,
  TimelapseOutlined,
  TimelineOutlined,
  Timer10Outlined,
  Timer3Outlined,
  TimerOffOutlined,
  TimerOutlined,
  TitleOutlined,
  TocOutlined,
  TodayOutlined,
  ToggleOffOutlined,
  ToggleOnOutlined,
  TollOutlined,
  TonalityOutlined,
  TouchAppOutlined,
  ToysOutlined,
  TrackChangesOutlined,
  TrafficOutlined,
  TrainOutlined,
  TramOutlined,
  TransferWithinAStationOutlined,
  TransformOutlined,
  TransitEnterexitOutlined,
  TranslateOutlined,
  TrendingDownOutlined,
  TrendingFlatOutlined,
  TrendingUpOutlined,
  TripOriginOutlined,
  TuneOutlined,
  TurnedInNotOutlined,
  TurnedInOutlined,
  TvOffOutlined,
  TvOutlined,
  TwoWheelerOutlined,
  UnarchiveOutlined,
  UndoOutlined,
  UnfoldLessOutlined,
  UnfoldMoreOutlined,
  UnsubscribeOutlined,
  UpdateOutlined,
  UsbOutlined,
  VerifiedUserOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignCenterOutlined,
  VerticalAlignTopOutlined,
  VerticalSplitOutlined,
  VibrationOutlined,
  VideoCallOutlined,
  VideoLabelOutlined,
  VideoLibraryOutlined,
  VideocamOffOutlined,
  VideocamOutlined,
  VideogameAssetOutlined,
  ViewAgendaOutlined,
  ViewArrayOutlined,
  ViewCarouselOutlined,
  ViewColumnOutlined,
  ViewComfyOutlined,
  ViewCompactOutlined,
  ViewDayOutlined,
  ViewHeadlineOutlined,
  ViewListOutlined,
  ViewModuleOutlined,
  ViewQuiltOutlined,
  ViewStreamOutlined,
  ViewWeekOutlined,
  VignetteOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
  VoiceChatOutlined,
  VoiceOverOffOutlined,
  VoicemailOutlined,
  VolumeDownOutlined,
  VolumeMuteOutlined,
  VolumeOffOutlined,
  VolumeUpOutlined,
  VpnKeyOutlined,
  VpnLockOutlined,
  WallpaperOutlined,
  WarningOutlined,
  WatchLaterOutlined,
  WatchOutlined,
  WavesOutlined,
  WbAutoOutlined,
  WbCloudyOutlined,
  WbIncandescentOutlined,
  WbIridescentOutlined,
  WbSunnyOutlined,
  WcOutlined,
  WebAssetOutlined,
  WebOutlined,
  WeekendOutlined,
  WhatshotOutlined,
  WhereToVoteOutlined,
  WidgetsOutlined,
  WifiLockOutlined,
  WifiOffOutlined,
  WifiOutlined,
  WifiTetheringOutlined,
  WorkOffOutlined,
  WorkOutlineOutlined,
  WorkOutlined,
  WrapTextOutlined,
  YoutubeSearchedForOutlined,
  ZoomInOutlined,
  ZoomOutMapOutlined,
  ZoomOutOutlined
} from '@mui/icons-material'

const Icons: { [k: string]: any } = {
  AcUnit,
  AccessAlarm,
  AccessAlarms,
  AccessTime,
  Accessibility,
  AccessibilityNew,
  Accessible,
  AccessibleForward,
  AccountBalance,
  AccountBalanceWallet,
  AccountBox,
  AccountCircle,
  AccountTree,
  Adb,
  Add,
  AddAPhoto,
  AddAlarm,
  AddAlert,
  AddBox,
  AddCircle,
  AddCircleOutline,
  AddComment,
  AddIcCall,
  AddLocation,
  AddPhotoAlternate,
  AddShoppingCart,
  AddToHomeScreen,
  AddToPhotos,
  AddToQueue,
  Adjust,
  AirlineSeatFlat,
  AirlineSeatFlatAngled,
  AirlineSeatIndividualSuite,
  AirlineSeatLegroomExtra,
  AirlineSeatLegroomNormal,
  AirlineSeatLegroomReduced,
  AirlineSeatReclineExtra,
  AirlineSeatReclineNormal,
  AirplanemodeActive,
  AirplanemodeInactive,
  Airplay,
  AirportShuttle,
  Alarm,
  AlarmAdd,
  AlarmOff,
  AlarmOn,
  Album,
  AllInbox,
  AllInclusive,
  AllOut,
  AlternateEmail,
  Android,
  Announcement,
  Apartment,
  Apple,
  Apps,
  Archive,
  ArrowBack,
  ArrowBackIos,
  ArrowDownward,
  ArrowDropDown,
  ArrowDropDownCircle,
  ArrowDropUp,
  ArrowForward,
  ArrowForwardIos,
  ArrowLeft,
  ArrowRight,
  ArrowRightAlt,
  ArrowUpward,
  ArtTrack,
  AspectRatio,
  Assessment,
  Assignment,
  AssignmentInd,
  AssignmentLate,
  AssignmentReturn,
  AssignmentReturned,
  AssignmentTurnedIn,
  Assistant,
  AssistantPhoto,
  Atm,
  AttachFile,
  AttachMoney,
  Attachment,
  Audiotrack,
  Autorenew,
  AvTimer,
  Backspace,
  Backup,
  Ballot,
  BarChart,
  Bathtub,
  Battery20,
  Battery30,
  Battery50,
  Battery60,
  Battery80,
  Battery90,
  BatteryAlert,
  BatteryCharging20,
  BatteryCharging30,
  BatteryCharging50,
  BatteryCharging60,
  BatteryCharging80,
  BatteryCharging90,
  BatteryChargingFull,
  BatteryFull,
  BatteryStd,
  BatteryUnknown,
  BeachAccess,
  Beenhere,
  Block,
  Bluetooth,
  BluetoothAudio,
  BluetoothConnected,
  BluetoothDisabled,
  BluetoothSearching,
  BlurCircular,
  BlurLinear,
  BlurOff,
  BlurOn,
  Book,
  Bookmark,
  BookmarkBorder,
  Bookmarks,
  BorderAll,
  BorderBottom,
  BorderClear,
  BorderColor,
  BorderHorizontal,
  BorderInner,
  BorderLeft,
  BorderOuter,
  BorderRight,
  BorderStyle,
  BorderTop,
  BorderVertical,
  BrandingWatermark,
  Brightness1,
  Brightness2,
  Brightness3,
  Brightness4,
  Brightness5,
  Brightness6,
  Brightness7,
  BrightnessAuto,
  BrightnessHigh,
  BrightnessLow,
  BrightnessMedium,
  BrokenImage,
  Brush,
  BubbleChart,
  BugReport,
  Build,
  BurstMode,
  Business,
  BusinessCenter,
  Cached,
  Cake,
  CalendarToday,
  CalendarViewDay,
  Call,
  CallEnd,
  CallMade,
  CallMerge,
  CallMissed,
  CallMissedOutgoing,
  CallReceived,
  CallSplit,
  CallToAction,
  Camera,
  CameraAlt,
  CameraEnhance,
  CameraFront,
  CameraRear,
  CameraRoll,
  Cancel,
  CancelPresentation,
  CancelScheduleSend,
  CardGiftcard,
  CardMembership,
  CardTravel,
  Casino,
  Cast,
  CastConnected,
  CastForEducation,
  Category,
  CellWifi,
  CenterFocusStrong,
  CenterFocusWeak,
  ChangeHistory,
  Chat,
  ChatBubble,
  ChatBubbleOutline,
  Check,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircle,
  CheckCircleOutline,
  ChevronLeft,
  ChevronRight,
  ChildCare,
  ChildFriendly,
  ChromeReaderMode,
  Class,
  Clear,
  ClearAll,
  Close,
  ClosedCaption,
  Cloud,
  CloudCircle,
  CloudDone,
  CloudDownload,
  CloudOff,
  CloudQueue,
  CloudUpload,
  Code,
  Collections,
  CollectionsBookmark,
  ColorLens,
  Colorize,
  Comment,
  Commute,
  Compare,
  CompareArrows,
  CompassCalibration,
  Computer,
  ConfirmationNumber,
  ContactMail,
  ContactPhone,
  ContactSupport,
  Contactless,
  Contacts,
  ControlCamera,
  ControlPoint,
  ControlPointDuplicate,
  Copyright,
  Create,
  CreateNewFolder,
  CreditCard,
  Crop,
  Crop169,
  Crop32,
  Crop54,
  Crop75,
  CropDin,
  CropFree,
  CropLandscape,
  CropOriginal,
  CropPortrait,
  CropRotate,
  CropSquare,
  Dashboard,
  DataUsage,
  DateRange,
  Deck,
  Dehaze,
  Delete,
  DeleteForever,
  DeleteOutline,
  DeleteSweep,
  DepartureBoard,
  Description,
  DesktopAccessDisabled,
  DesktopMac,
  DesktopWindows,
  Details,
  DeveloperBoard,
  DeveloperMode,
  DeviceHub,
  DeviceUnknown,
  Devices,
  DevicesOther,
  DialerSip,
  Dialpad,
  Directions,
  DirectionsBike,
  DirectionsBoat,
  DirectionsBus,
  DirectionsCar,
  DirectionsRailway,
  DirectionsRun,
  DirectionsSubway,
  DirectionsTransit,
  DirectionsWalk,
  DiscFull,
  Dns,
  Dock,
  Domain,
  DomainDisabled,
  Done,
  DoneAll,
  DoneOutline,
  DonutLarge,
  DonutSmall,
  DoubleArrow,
  Drafts,
  DragHandle,
  DragIndicator,
  DriveEta,
  Duo,
  Dvr,
  DynamicFeed,
  Edit,
  EditAttributes,
  EditLocation,
  Eject,
  Email,
  EmojiEmotions,
  EmojiEvents,
  EmojiFlags,
  EmojiFoodBeverage,
  EmojiNature,
  EmojiObjects,
  EmojiPeople,
  EmojiSymbols,
  EmojiTransportation,
  EnhancedEncryption,
  Equalizer,
  Error,
  ErrorOutline,
  Euro,
  EuroSymbol,
  EvStation,
  Event,
  EventAvailable,
  EventBusy,
  EventNote,
  EventSeat,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  Explicit,
  Explore,
  ExploreOff,
  Exposure,
  Extension,
  Face,
  Facebook,
  FastForward,
  FastRewind,
  Fastfood,
  Favorite,
  FavoriteBorder,
  FeaturedPlayList,
  FeaturedVideo,
  Feedback,
  FiberDvr,
  FiberManualRecord,
  FiberNew,
  FiberPin,
  FiberSmartRecord,
  FileCopy,
  Filter,
  Filter1,
  Filter2,
  Filter3,
  Filter4,
  Filter5,
  Filter6,
  Filter7,
  Filter8,
  Filter9,
  Filter9Plus,
  FilterBAndW,
  FilterCenterFocus,
  FilterDrama,
  FilterFrames,
  FilterHdr,
  FilterList,
  FilterNone,
  FilterTiltShift,
  FilterVintage,
  FindInPage,
  FindReplace,
  Fingerprint,
  Fireplace,
  FirstPage,
  FitnessCenter,
  Flag,
  Flare,
  FlashAuto,
  FlashOff,
  FlashOn,
  Flight,
  FlightLand,
  FlightTakeoff,
  Flip,
  FlipCameraAndroid,
  FlipCameraIos,
  FlipToBack,
  FlipToFront,
  Folder,
  FolderOpen,
  FolderShared,
  FolderSpecial,
  FontDownload,
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatClear,
  FormatColorFill,
  FormatColorReset,
  FormatColorText,
  FormatIndentDecrease,
  FormatIndentIncrease,
  FormatItalic,
  FormatLineSpacing,
  FormatListBulleted,
  FormatListNumbered,
  FormatListNumberedRtl,
  FormatPaint,
  FormatQuote,
  FormatShapes,
  FormatSize,
  FormatStrikethrough,
  FormatTextdirectionLToR,
  FormatTextdirectionRToL,
  FormatUnderlined,
  Forum,
  Forward,
  Forward10,
  Forward30,
  Forward5,
  FourK,
  FreeBreakfast,
  Fullscreen,
  FullscreenExit,
  Functions,
  GTranslate,
  Gamepad,
  Games,
  Gavel,
  Gesture,
  GetApp,
  Gif,
  GitHub,
  GolfCourse,
  GpsFixed,
  GpsNotFixed,
  GpsOff,
  Grade,
  Gradient,
  Grain,
  GraphicEq,
  GridOff,
  GridOn,
  Group,
  GroupAdd,
  GroupWork,
  Hd,
  HdrOff,
  HdrOn,
  HdrStrong,
  HdrWeak,
  Headset,
  HeadsetMic,
  Healing,
  Hearing,
  Height,
  Help,
  HelpOutline,
  HighQuality,
  Highlight,
  HighlightOff,
  History,
  Home,
  HomeWork,
  HorizontalSplit,
  HotTub,
  Hotel,
  HourglassEmpty,
  HourglassFull,
  House,
  HowToReg,
  HowToVote,
  Http,
  Https,
  Image,
  ImageAspectRatio,
  ImageSearch,
  ImportContacts,
  ImportExport,
  ImportantDevices,
  Inbox,
  IndeterminateCheckBox,
  Info,
  Input,
  InsertChart,
  InsertComment,
  InsertDriveFile,
  InsertEmoticon,
  InsertInvitation,
  InsertLink,
  InsertPhoto,
  Instagram,
  InvertColors,
  InvertColorsOff,
  Iso,
  Keyboard,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  KeyboardBackspace,
  KeyboardCapslock,
  KeyboardHide,
  KeyboardReturn,
  KeyboardTab,
  KeyboardVoice,
  KingBed,
  Kitchen,
  Label,
  LabelImportant,
  LabelOff,
  Landscape,
  Language,
  Laptop,
  LaptopChromebook,
  LaptopMac,
  LaptopWindows,
  LastPage,
  Launch,
  Layers,
  LayersClear,
  LeakAdd,
  LeakRemove,
  Lens,
  LibraryAdd,
  LibraryAddCheck,
  LibraryBooks,
  LibraryMusic,
  LineStyle,
  LineWeight,
  LinearScale,
  Link,
  LinkOff,
  LinkedCamera,
  LinkedIn,
  List,
  ListAlt,
  LiveHelp,
  LiveTv,
  LocalActivity,
  LocalAirport,
  LocalAtm,
  LocalBar,
  LocalCafe,
  LocalCarWash,
  LocalConvenienceStore,
  LocalDining,
  LocalDrink,
  LocalFlorist,
  LocalGasStation,
  LocalGroceryStore,
  LocalHospital,
  LocalHotel,
  LocalLaundryService,
  LocalLibrary,
  LocalMall,
  LocalMovies,
  LocalOffer,
  LocalParking,
  LocalPharmacy,
  LocalPhone,
  LocalPizza,
  LocalPlay,
  LocalPostOffice,
  LocalPrintshop,
  LocalSee,
  LocalShipping,
  LocalTaxi,
  LocationCity,
  LocationDisabled,
  LocationOff,
  LocationOn,
  LocationSearching,
  Lock,
  LockOpen,
  Looks,
  Looks3,
  Looks4,
  Looks5,
  Looks6,
  LooksOne,
  LooksTwo,
  Loop,
  Loupe,
  LowPriority,
  Loyalty,
  Mail,
  MailOutline,
  Map,
  Markunread,
  MarkunreadMailbox,
  Maximize,
  MeetingRoom,
  Memory,
  Menu,
  MenuBook,
  MenuOpen,
  MergeType,
  Message,
  Mic,
  MicNone,
  MicOff,
  Minimize,
  MissedVideoCall,
  Mms,
  MobileFriendly,
  MobileOff,
  MobileScreenShare,
  ModeComment,
  MonetizationOn,
  Money,
  MoneyOff,
  MonochromePhotos,
  Mood,
  MoodBad,
  More,
  MoreHoriz,
  MoreVert,
  Mouse,
  MoveToInbox,
  Movie,
  MovieCreation,
  MovieFilter,
  MultilineChart,
  Museum,
  MusicNote,
  MusicOff,
  MusicVideo,
  MyLocation,
  Nature,
  NaturePeople,
  NavigateBefore,
  NavigateNext,
  Navigation,
  NearMe,
  NetworkCell,
  NetworkCheck,
  NetworkLocked,
  NetworkWifi,
  NewReleases,
  NextWeek,
  Nfc,
  NightsStay,
  NoEncryption,
  NoMeetingRoom,
  NoSim,
  NotInterested,
  NotListedLocation,
  Note,
  NoteAdd,
  Notes,
  NotificationImportant,
  Notifications,
  NotificationsActive,
  NotificationsNone,
  NotificationsOff,
  NotificationsPaused,
  OfflineBolt,
  OfflinePin,
  OndemandVideo,
  Opacity,
  OpenInBrowser,
  OpenInNew,
  OpenWith,
  OutdoorGrill,
  Pages,
  Pageview,
  Palette,
  PanTool,
  Panorama,
  PanoramaFishEye,
  PanoramaHorizontal,
  PanoramaVertical,
  PanoramaWideAngle,
  PartyMode,
  Pause,
  PauseCircleFilled,
  PauseCircleOutline,
  PausePresentation,
  Payment,
  People,
  PeopleAlt,
  PeopleOutline,
  PermCameraMic,
  PermContactCalendar,
  PermDataSetting,
  PermDeviceInformation,
  PermIdentity,
  PermMedia,
  PermPhoneMsg,
  PermScanWifi,
  Person,
  PersonAdd,
  PersonAddDisabled,
  PersonOutline,
  PersonPin,
  PersonPinCircle,
  PersonalVideo,
  Pets,
  Phone,
  PhoneAndroid,
  PhoneBluetoothSpeaker,
  PhoneCallback,
  PhoneDisabled,
  PhoneEnabled,
  PhoneForwarded,
  PhoneInTalk,
  PhoneIphone,
  PhoneLocked,
  PhoneMissed,
  PhonePaused,
  Phonelink,
  PhonelinkErase,
  PhonelinkLock,
  PhonelinkOff,
  PhonelinkRing,
  PhonelinkSetup,
  Photo,
  PhotoAlbum,
  PhotoCamera,
  PhotoFilter,
  PhotoLibrary,
  PhotoSizeSelectActual,
  PhotoSizeSelectLarge,
  PhotoSizeSelectSmall,
  PictureAsPdf,
  PictureInPicture,
  PictureInPictureAlt,
  PieChart,
  PinDrop,
  Pinterest,
  Place,
  PlayArrow,
  PlayCircleFilled,
  PlayCircleFilledWhite,
  PlayCircleOutline,
  PlayForWork,
  PlaylistAdd,
  PlaylistAddCheck,
  PlaylistPlay,
  PlusOne,
  Policy,
  Poll,
  Pool,
  PortableWifiOff,
  Portrait,
  PostAdd,
  Power,
  PowerInput,
  PowerOff,
  PowerSettingsNew,
  PregnantWoman,
  PresentToAll,
  Print,
  PrintDisabled,
  PriorityHigh,
  Public,
  Publish,
  QueryBuilder,
  QuestionAnswer,
  Queue,
  QueueMusic,
  QueuePlayNext,
  Radio,
  RadioButtonChecked,
  RadioButtonUnchecked,
  RateReview,
  Receipt,
  RecentActors,
  RecordVoiceOver,
  Reddit,
  Redeem,
  Redo,
  Refresh,
  Remove,
  RemoveCircle,
  RemoveCircleOutline,
  RemoveFromQueue,
  RemoveRedEye,
  RemoveShoppingCart,
  Reorder,
  Repeat,
  RepeatOne,
  Replay,
  Replay10,
  Replay30,
  Replay5,
  Reply,
  ReplyAll,
  Report,
  ReportOff,
  ReportProblem,
  Restaurant,
  RestaurantMenu,
  Restore,
  RestoreFromTrash,
  RestorePage,
  RingVolume,
  Room,
  RoomService,
  Rotate90DegreesCcw,
  RotateLeft,
  RotateRight,
  Router,
  Rowing,
  RssFeed,
  RvHookup,
  Satellite,
  Save,
  SaveAlt,
  Scanner,
  ScatterPlot,
  Schedule,
  School,
  Score,
  ScreenLockLandscape,
  ScreenLockPortrait,
  ScreenLockRotation,
  ScreenRotation,
  ScreenShare,
  SdCard,
  SdStorage,
  Search,
  Security,
  SelectAll,
  Send,
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied,
  Settings,
  SettingsApplications,
  SettingsBackupRestore,
  SettingsBluetooth,
  SettingsBrightness,
  SettingsCell,
  SettingsEthernet,
  SettingsInputAntenna,
  SettingsInputComponent,
  SettingsInputComposite,
  SettingsInputHdmi,
  SettingsInputSvideo,
  SettingsOverscan,
  SettingsPhone,
  SettingsPower,
  SettingsRemote,
  SettingsSystemDaydream,
  SettingsVoice,
  Share,
  Shop,
  ShopTwo,
  ShoppingBasket,
  ShoppingCart,
  ShortText,
  ShowChart,
  Shuffle,
  ShutterSpeed,
  SignalCellular0Bar,
  SignalCellular1Bar,
  SignalCellular2Bar,
  SignalCellular3Bar,
  SignalCellular4Bar,
  SignalCellularAlt,
  SignalCellularConnectedNoInternet0Bar,
  SignalCellularConnectedNoInternet1Bar,
  SignalCellularConnectedNoInternet2Bar,
  SignalCellularConnectedNoInternet3Bar,
  SignalCellularConnectedNoInternet4Bar,
  SignalCellularNoSim,
  SignalCellularNull,
  SignalCellularOff,
  SignalWifi0Bar,
  SignalWifi1Bar,
  SignalWifi1BarLock,
  SignalWifi2Bar,
  SignalWifi2BarLock,
  SignalWifi3Bar,
  SignalWifi3BarLock,
  SignalWifi4Bar,
  SignalWifi4BarLock,
  SignalWifiOff,
  SimCard,
  SingleBed,
  SkipNext,
  SkipPrevious,
  Slideshow,
  SlowMotionVideo,
  Smartphone,
  SmokeFree,
  SmokingRooms,
  Sms,
  SmsFailed,
  Snooze,
  Sort,
  SortByAlpha,
  Spa,
  SpaceBar,
  Speaker,
  SpeakerGroup,
  SpeakerNotes,
  SpeakerNotesOff,
  SpeakerPhone,
  Speed,
  Spellcheck,
  Sports,
  SportsBaseball,
  SportsBasketball,
  SportsCricket,
  SportsEsports,
  SportsFootball,
  SportsGolf,
  SportsHandball,
  SportsHockey,
  SportsKabaddi,
  SportsMma,
  SportsMotorsports,
  SportsRugby,
  SportsSoccer,
  SportsTennis,
  SportsVolleyball,
  SquareFoot,
  Star,
  StarBorder,
  StarHalf,
  StarOutline,
  StarRate,
  Stars,
  StayCurrentLandscape,
  StayCurrentPortrait,
  StayPrimaryLandscape,
  StayPrimaryPortrait,
  Stop,
  StopScreenShare,
  Storage,
  Store,
  StoreMallDirectory,
  Storefront,
  Straighten,
  Streetview,
  StrikethroughS,
  Style,
  SubdirectoryArrowLeft,
  SubdirectoryArrowRight,
  Subject,
  Subscriptions,
  Subtitles,
  Subway,
  SupervisedUserCircle,
  SupervisorAccount,
  SurroundSound,
  SwapCalls,
  SwapHoriz,
  SwapHorizontalCircle,
  SwapVert,
  SwapVerticalCircle,
  SwitchCamera,
  SwitchVideo,
  Sync,
  SyncAlt,
  SyncDisabled,
  SyncProblem,
  SystemUpdate,
  SystemUpdateAlt,
  Tab,
  TabUnselected,
  TableChart,
  Tablet,
  TabletAndroid,
  TabletMac,
  TagFaces,
  TapAndPlay,
  Telegram,
  Terrain,
  TextFields,
  TextFormat,
  TextRotateUp,
  TextRotateVertical,
  TextRotationAngledown,
  TextRotationAngleup,
  TextRotationDown,
  TextRotationNone,
  Textsms,
  Texture,
  Theaters,
  ThreeDRotation,
  ThreeSixty,
  ThumbDown,
  ThumbDownAlt,
  ThumbUp,
  ThumbUpAlt,
  ThumbsUpDown,
  TimeToLeave,
  Timelapse,
  Timeline,
  Timer,
  Timer10,
  Timer3,
  TimerOff,
  Title,
  Toc,
  Today,
  ToggleOff,
  ToggleOn,
  Toll,
  Tonality,
  TouchApp,
  Toys,
  TrackChanges,
  Traffic,
  Train,
  Tram,
  TransferWithinAStation,
  Transform,
  TransitEnterexit,
  Translate,
  TrendingDown,
  TrendingFlat,
  TrendingUp,
  TripOrigin,
  Tune,
  TurnedIn,
  TurnedInNot,
  Tv,
  TvOff,
  Twitter,
  TwoWheeler,
  Unarchive,
  Undo,
  UnfoldLess,
  UnfoldMore,
  Unsubscribe,
  Update,
  Usb,
  VerifiedUser,
  VerticalAlignBottom,
  VerticalAlignCenter,
  VerticalAlignTop,
  VerticalSplit,
  Vibration,
  VideoCall,
  VideoLabel,
  VideoLibrary,
  Videocam,
  VideocamOff,
  VideogameAsset,
  ViewAgenda,
  ViewArray,
  ViewCarousel,
  ViewColumn,
  ViewComfy,
  ViewCompact,
  ViewDay,
  ViewHeadline,
  ViewList,
  ViewModule,
  ViewQuilt,
  ViewStream,
  ViewWeek,
  Vignette,
  Visibility,
  VisibilityOff,
  VoiceChat,
  VoiceOverOff,
  Voicemail,
  VolumeDown,
  VolumeMute,
  VolumeOff,
  VolumeUp,
  VpnKey,
  VpnLock,
  Wallpaper,
  Warning,
  Watch,
  WatchLater,
  Waves,
  WbAuto,
  WbCloudy,
  WbIncandescent,
  WbIridescent,
  WbSunny,
  Wc,
  Web,
  WebAsset,
  Weekend,
  WhatsApp,
  Whatshot,
  WhereToVote,
  Widgets,
  Wifi,
  WifiLock,
  WifiOff,
  WifiTethering,
  Work,
  WorkOff,
  WorkOutline,
  WrapText,
  YouTube,
  YoutubeSearchedFor,
  ZoomIn,
  ZoomOut,
  ZoomOutMap,
  AcUnitOutlined,
  AccessAlarmOutlined,
  AccessAlarmsOutlined,
  AccessTimeOutlined,
  AccessibilityNewOutlined,
  AccessibilityOutlined,
  AccessibleForwardOutlined,
  AccessibleOutlined,
  AccountBalanceOutlined,
  AccountBalanceWalletOutlined,
  AccountBoxOutlined,
  AccountCircleOutlined,
  AccountTreeOutlined,
  AdbOutlined,
  AddAPhotoOutlined,
  AddAlarmOutlined,
  AddAlertOutlined,
  AddBoxOutlined,
  AddCircleOutlineOutlined,
  AddCircleOutlined,
  AddCommentOutlined,
  AddIcCallOutlined,
  AddLocationOutlined,
  AddOutlined,
  AddPhotoAlternateOutlined,
  AddShoppingCartOutlined,
  AddToHomeScreenOutlined,
  AddToPhotosOutlined,
  AddToQueueOutlined,
  AdjustOutlined,
  AirlineSeatFlatAngledOutlined,
  AirlineSeatFlatOutlined,
  AirlineSeatIndividualSuiteOutlined,
  AirlineSeatLegroomExtraOutlined,
  AirlineSeatLegroomNormalOutlined,
  AirlineSeatLegroomReducedOutlined,
  AirlineSeatReclineExtraOutlined,
  AirlineSeatReclineNormalOutlined,
  AirplanemodeActiveOutlined,
  AirplanemodeInactiveOutlined,
  AirplayOutlined,
  AirportShuttleOutlined,
  AlarmAddOutlined,
  AlarmOffOutlined,
  AlarmOnOutlined,
  AlarmOutlined,
  AlbumOutlined,
  AllInboxOutlined,
  AllInclusiveOutlined,
  AllOutOutlined,
  AlternateEmailOutlined,
  AndroidOutlined,
  AnnouncementOutlined,
  ApartmentOutlined,
  AppsOutlined,
  ArchiveOutlined,
  ArrowBackIosOutlined,
  ArrowBackOutlined,
  ArrowDownwardOutlined,
  ArrowDropDownCircleOutlined,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  ArrowForwardIosOutlined,
  ArrowForwardOutlined,
  ArrowLeftOutlined,
  ArrowRightAltOutlined,
  ArrowRightOutlined,
  ArrowUpwardOutlined,
  ArtTrackOutlined,
  AspectRatioOutlined,
  AssessmentOutlined,
  AssignmentIndOutlined,
  AssignmentLateOutlined,
  AssignmentOutlined,
  AssignmentReturnOutlined,
  AssignmentReturnedOutlined,
  AssignmentTurnedInOutlined,
  AssistantOutlined,
  AssistantPhotoOutlined,
  AtmOutlined,
  AttachFileOutlined,
  AttachMoneyOutlined,
  AttachmentOutlined,
  AudiotrackOutlined,
  AutorenewOutlined,
  AvTimerOutlined,
  BackspaceOutlined,
  BackupOutlined,
  BallotOutlined,
  BarChartOutlined,
  BathtubOutlined,
  Battery20Outlined,
  Battery30Outlined,
  Battery50Outlined,
  Battery60Outlined,
  Battery80Outlined,
  Battery90Outlined,
  BatteryAlertOutlined,
  BatteryCharging20Outlined,
  BatteryCharging30Outlined,
  BatteryCharging50Outlined,
  BatteryCharging60Outlined,
  BatteryCharging80Outlined,
  BatteryCharging90Outlined,
  BatteryChargingFullOutlined,
  BatteryFullOutlined,
  BatteryStdOutlined,
  BatteryUnknownOutlined,
  BeachAccessOutlined,
  BeenhereOutlined,
  BlockOutlined,
  BluetoothAudioOutlined,
  BluetoothConnectedOutlined,
  BluetoothDisabledOutlined,
  BluetoothOutlined,
  BluetoothSearchingOutlined,
  BlurCircularOutlined,
  BlurLinearOutlined,
  BlurOffOutlined,
  BlurOnOutlined,
  BookOutlined,
  BookmarkBorderOutlined,
  BookmarkOutlined,
  BookmarksOutlined,
  BorderAllOutlined,
  BorderBottomOutlined,
  BorderClearOutlined,
  BorderColorOutlined,
  BorderHorizontalOutlined,
  BorderInnerOutlined,
  BorderLeftOutlined,
  BorderOuterOutlined,
  BorderRightOutlined,
  BorderStyleOutlined,
  BorderTopOutlined,
  BorderVerticalOutlined,
  BrandingWatermarkOutlined,
  Brightness1Outlined,
  Brightness2Outlined,
  Brightness3Outlined,
  Brightness4Outlined,
  Brightness5Outlined,
  Brightness6Outlined,
  Brightness7Outlined,
  BrightnessAutoOutlined,
  BrightnessHighOutlined,
  BrightnessLowOutlined,
  BrightnessMediumOutlined,
  BrokenImageOutlined,
  BrushOutlined,
  BubbleChartOutlined,
  BugReportOutlined,
  BuildOutlined,
  BurstModeOutlined,
  BusinessCenterOutlined,
  BusinessOutlined,
  CachedOutlined,
  CakeOutlined,
  CalendarTodayOutlined,
  CalendarViewDayOutlined,
  CallEndOutlined,
  CallMadeOutlined,
  CallMergeOutlined,
  CallMissedOutgoingOutlined,
  CallMissedOutlined,
  CallOutlined,
  CallReceivedOutlined,
  CallSplitOutlined,
  CallToActionOutlined,
  CameraAltOutlined,
  CameraEnhanceOutlined,
  CameraFrontOutlined,
  CameraOutlined,
  CameraRearOutlined,
  CameraRollOutlined,
  CancelOutlined,
  CancelPresentationOutlined,
  CancelScheduleSendOutlined,
  CardGiftcardOutlined,
  CardMembershipOutlined,
  CardTravelOutlined,
  CasinoOutlined,
  CastConnectedOutlined,
  CastForEducationOutlined,
  CastOutlined,
  CategoryOutlined,
  CellWifiOutlined,
  CenterFocusStrongOutlined,
  CenterFocusWeakOutlined,
  ChangeHistoryOutlined,
  ChatBubbleOutlineOutlined,
  ChatBubbleOutlined,
  ChatOutlined,
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
  CheckCircleOutlineOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  ChevronLeftOutlined,
  ChevronRightOutlined,
  ChildCareOutlined,
  ChildFriendlyOutlined,
  ChromeReaderModeOutlined,
  ClassOutlined,
  ClearAllOutlined,
  ClearOutlined,
  CloseOutlined,
  ClosedCaptionOutlined,
  CloudCircleOutlined,
  CloudDoneOutlined,
  CloudDownloadOutlined,
  CloudOffOutlined,
  CloudOutlined,
  CloudQueueOutlined,
  CloudUploadOutlined,
  CodeOutlined,
  CollectionsBookmarkOutlined,
  CollectionsOutlined,
  ColorLensOutlined,
  ColorizeOutlined,
  CommentOutlined,
  CommuteOutlined,
  CompareArrowsOutlined,
  CompareOutlined,
  CompassCalibrationOutlined,
  ComputerOutlined,
  ConfirmationNumberOutlined,
  ContactMailOutlined,
  ContactPhoneOutlined,
  ContactSupportOutlined,
  ContactlessOutlined,
  ContactsOutlined,
  ControlCameraOutlined,
  ControlPointDuplicateOutlined,
  ControlPointOutlined,
  CopyrightOutlined,
  CreateNewFolderOutlined,
  CreateOutlined,
  CreditCardOutlined,
  Crop169Outlined,
  Crop32Outlined,
  Crop54Outlined,
  Crop75Outlined,
  CropDinOutlined,
  CropFreeOutlined,
  CropLandscapeOutlined,
  CropOriginalOutlined,
  CropOutlined,
  CropPortraitOutlined,
  CropRotateOutlined,
  CropSquareOutlined,
  DashboardOutlined,
  DataUsageOutlined,
  DateRangeOutlined,
  DeckOutlined,
  DehazeOutlined,
  DeleteForeverOutlined,
  DeleteOutlineOutlined,
  DeleteOutlined,
  DeleteSweepOutlined,
  DepartureBoardOutlined,
  DescriptionOutlined,
  DesktopAccessDisabledOutlined,
  DesktopMacOutlined,
  DesktopWindowsOutlined,
  DetailsOutlined,
  DeveloperBoardOutlined,
  DeveloperModeOutlined,
  DeviceHubOutlined,
  DeviceUnknownOutlined,
  DevicesOtherOutlined,
  DevicesOutlined,
  DialerSipOutlined,
  DialpadOutlined,
  DirectionsBikeOutlined,
  DirectionsBoatOutlined,
  DirectionsBusOutlined,
  DirectionsCarOutlined,
  DirectionsOutlined,
  DirectionsRailwayOutlined,
  DirectionsRunOutlined,
  DirectionsSubwayOutlined,
  DirectionsTransitOutlined,
  DirectionsWalkOutlined,
  DiscFullOutlined,
  DnsOutlined,
  DockOutlined,
  DomainDisabledOutlined,
  DomainOutlined,
  DoneAllOutlined,
  DoneOutlineOutlined,
  DoneOutlined,
  DonutLargeOutlined,
  DonutSmallOutlined,
  DoubleArrowOutlined,
  DraftsOutlined,
  DragHandleOutlined,
  DragIndicatorOutlined,
  DriveEtaOutlined,
  DuoOutlined,
  DvrOutlined,
  DynamicFeedOutlined,
  EditAttributesOutlined,
  EditLocationOutlined,
  EditOutlined,
  EjectOutlined,
  EmailOutlined,
  EmojiEmotionsOutlined,
  EmojiEventsOutlined,
  EmojiFlagsOutlined,
  EmojiFoodBeverageOutlined,
  EmojiNatureOutlined,
  EmojiObjectsOutlined,
  EmojiPeopleOutlined,
  EmojiSymbolsOutlined,
  EmojiTransportationOutlined,
  EnhancedEncryptionOutlined,
  EqualizerOutlined,
  ErrorOutlineOutlined,
  ErrorOutlined,
  EuroOutlined,
  EuroSymbolOutlined,
  EvStationOutlined,
  EventAvailableOutlined,
  EventBusyOutlined,
  EventNoteOutlined,
  EventOutlined,
  EventSeatOutlined,
  ExitToAppOutlined,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  ExplicitOutlined,
  ExploreOffOutlined,
  ExploreOutlined,
  ExposureOutlined,
  ExtensionOutlined,
  FaceOutlined,
  FastForwardOutlined,
  FastRewindOutlined,
  FastfoodOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  FeaturedPlayListOutlined,
  FeaturedVideoOutlined,
  FeedbackOutlined,
  FiberDvrOutlined,
  FiberManualRecordOutlined,
  FiberNewOutlined,
  FiberPinOutlined,
  FiberSmartRecordOutlined,
  FileCopyOutlined,
  Filter1Outlined,
  Filter2Outlined,
  Filter3Outlined,
  Filter4Outlined,
  Filter5Outlined,
  Filter6Outlined,
  Filter7Outlined,
  Filter8Outlined,
  Filter9Outlined,
  Filter9PlusOutlined,
  FilterBAndWOutlined,
  FilterCenterFocusOutlined,
  FilterDramaOutlined,
  FilterFramesOutlined,
  FilterHdrOutlined,
  FilterListOutlined,
  FilterNoneOutlined,
  FilterOutlined,
  FilterTiltShiftOutlined,
  FilterVintageOutlined,
  FindInPageOutlined,
  FindReplaceOutlined,
  FingerprintOutlined,
  FireplaceOutlined,
  FirstPageOutlined,
  FitnessCenterOutlined,
  FlagOutlined,
  FlareOutlined,
  FlashAutoOutlined,
  FlashOffOutlined,
  FlashOnOutlined,
  FlightLandOutlined,
  FlightOutlined,
  FlightTakeoffOutlined,
  FlipCameraAndroidOutlined,
  FlipCameraIosOutlined,
  FlipOutlined,
  FlipToBackOutlined,
  FlipToFrontOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  FolderSharedOutlined,
  FolderSpecialOutlined,
  FontDownloadOutlined,
  FormatAlignCenterOutlined,
  FormatAlignJustifyOutlined,
  FormatAlignLeftOutlined,
  FormatAlignRightOutlined,
  FormatBoldOutlined,
  FormatClearOutlined,
  FormatColorFillOutlined,
  FormatColorResetOutlined,
  FormatColorTextOutlined,
  FormatIndentDecreaseOutlined,
  FormatIndentIncreaseOutlined,
  FormatItalicOutlined,
  FormatLineSpacingOutlined,
  FormatListBulletedOutlined,
  FormatListNumberedOutlined,
  FormatListNumberedRtlOutlined,
  FormatPaintOutlined,
  FormatQuoteOutlined,
  FormatShapesOutlined,
  FormatSizeOutlined,
  FormatStrikethroughOutlined,
  FormatTextdirectionLToROutlined,
  FormatTextdirectionRToLOutlined,
  FormatUnderlinedOutlined,
  ForumOutlined,
  Forward10Outlined,
  Forward30Outlined,
  Forward5Outlined,
  ForwardOutlined,
  FourKOutlined,
  FreeBreakfastOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  FunctionsOutlined,
  GTranslateOutlined,
  GamepadOutlined,
  GamesOutlined,
  GavelOutlined,
  GestureOutlined,
  GetAppOutlined,
  GifOutlined,
  GolfCourseOutlined,
  GpsFixedOutlined,
  GpsNotFixedOutlined,
  GpsOffOutlined,
  GradeOutlined,
  GradientOutlined,
  GrainOutlined,
  GraphicEqOutlined,
  GridOffOutlined,
  GridOnOutlined,
  GroupAddOutlined,
  GroupOutlined,
  GroupWorkOutlined,
  HdOutlined,
  HdrOffOutlined,
  HdrOnOutlined,
  HdrStrongOutlined,
  HdrWeakOutlined,
  HeadsetMicOutlined,
  HeadsetOutlined,
  HealingOutlined,
  HearingOutlined,
  HeightOutlined,
  HelpOutlineOutlined,
  HelpOutlined,
  HighQualityOutlined,
  HighlightOffOutlined,
  HighlightOutlined,
  HistoryOutlined,
  HomeOutlined,
  HomeWorkOutlined,
  HorizontalSplitOutlined,
  HotTubOutlined,
  HotelOutlined,
  HourglassEmptyOutlined,
  HourglassFullOutlined,
  HouseOutlined,
  HowToRegOutlined,
  HowToVoteOutlined,
  HttpOutlined,
  HttpsOutlined,
  ImageAspectRatioOutlined,
  ImageOutlined,
  ImageSearchOutlined,
  ImportContactsOutlined,
  ImportExportOutlined,
  ImportantDevicesOutlined,
  InboxOutlined,
  IndeterminateCheckBoxOutlined,
  InfoOutlined,
  InputOutlined,
  InsertChartOutlined,
  InsertChartOutlinedOutlined,
  InsertChartOutlinedRounded,
  InsertChartOutlinedSharp,
  InsertChartOutlinedTwoTone,
  InsertCommentOutlined,
  InsertDriveFileOutlined,
  InsertEmoticonOutlined,
  InsertInvitationOutlined,
  InsertLinkOutlined,
  InsertPhotoOutlined,
  InvertColorsOffOutlined,
  InvertColorsOutlined,
  IsoOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
  KeyboardArrowUpOutlined,
  KeyboardBackspaceOutlined,
  KeyboardCapslockOutlined,
  KeyboardHideOutlined,
  KeyboardOutlined,
  KeyboardReturnOutlined,
  KeyboardTabOutlined,
  KeyboardVoiceOutlined,
  KingBedOutlined,
  KitchenOutlined,
  LabelImportantOutlined,
  LabelOffOutlined,
  LabelOutlined,
  LandscapeOutlined,
  LanguageOutlined,
  LaptopChromebookOutlined,
  LaptopMacOutlined,
  LaptopOutlined,
  LaptopWindowsOutlined,
  LastPageOutlined,
  LaunchOutlined,
  LayersClearOutlined,
  LayersOutlined,
  LeakAddOutlined,
  LeakRemoveOutlined,
  LensOutlined,
  LibraryAddCheckOutlined,
  LibraryAddOutlined,
  LibraryBooksOutlined,
  LibraryMusicOutlined,
  LineStyleOutlined,
  LineWeightOutlined,
  LinearScaleOutlined,
  LinkOffOutlined,
  LinkOutlined,
  LinkedCameraOutlined,
  ListAltOutlined,
  ListOutlined,
  LiveHelpOutlined,
  LiveTvOutlined,
  LocalActivityOutlined,
  LocalAirportOutlined,
  LocalAtmOutlined,
  LocalBarOutlined,
  LocalCafeOutlined,
  LocalCarWashOutlined,
  LocalConvenienceStoreOutlined,
  LocalDiningOutlined,
  LocalDrinkOutlined,
  LocalFloristOutlined,
  LocalGasStationOutlined,
  LocalGroceryStoreOutlined,
  LocalHospitalOutlined,
  LocalHotelOutlined,
  LocalLaundryServiceOutlined,
  LocalLibraryOutlined,
  LocalMallOutlined,
  LocalMoviesOutlined,
  LocalOfferOutlined,
  LocalParkingOutlined,
  LocalPharmacyOutlined,
  LocalPhoneOutlined,
  LocalPizzaOutlined,
  LocalPlayOutlined,
  LocalPostOfficeOutlined,
  LocalPrintshopOutlined,
  LocalSeeOutlined,
  LocalShippingOutlined,
  LocalTaxiOutlined,
  LocationCityOutlined,
  LocationDisabledOutlined,
  LocationOffOutlined,
  LocationOnOutlined,
  LocationSearchingOutlined,
  LockOpenOutlined,
  LockOutlined,
  Looks3Outlined,
  Looks4Outlined,
  Looks5Outlined,
  Looks6Outlined,
  LooksOneOutlined,
  LooksOutlined,
  LooksTwoOutlined,
  LoopOutlined,
  LoupeOutlined,
  LowPriorityOutlined,
  LoyaltyOutlined,
  MailOutlineOutlined,
  MailOutlined,
  MapOutlined,
  MarkunreadMailboxOutlined,
  MarkunreadOutlined,
  MaximizeOutlined,
  MeetingRoomOutlined,
  MemoryOutlined,
  MenuBookOutlined,
  MenuOpenOutlined,
  MenuOutlined,
  MergeTypeOutlined,
  MessageOutlined,
  MicNoneOutlined,
  MicOffOutlined,
  MicOutlined,
  MinimizeOutlined,
  MissedVideoCallOutlined,
  MmsOutlined,
  MobileFriendlyOutlined,
  MobileOffOutlined,
  MobileScreenShareOutlined,
  ModeCommentOutlined,
  MonetizationOnOutlined,
  MoneyOffOutlined,
  MoneyOutlined,
  MonochromePhotosOutlined,
  MoodBadOutlined,
  MoodOutlined,
  MoreHorizOutlined,
  MoreOutlined,
  MoreVertOutlined,
  MouseOutlined,
  MoveToInboxOutlined,
  MovieCreationOutlined,
  MovieFilterOutlined,
  MovieOutlined,
  MultilineChartOutlined,
  MuseumOutlined,
  MusicNoteOutlined,
  MusicOffOutlined,
  MusicVideoOutlined,
  MyLocationOutlined,
  NatureOutlined,
  NaturePeopleOutlined,
  NavigateBeforeOutlined,
  NavigateNextOutlined,
  NavigationOutlined,
  NearMeOutlined,
  NetworkCellOutlined,
  NetworkCheckOutlined,
  NetworkLockedOutlined,
  NetworkWifiOutlined,
  NewReleasesOutlined,
  NextWeekOutlined,
  NfcOutlined,
  NightsStayOutlined,
  NoEncryptionOutlined,
  NoMeetingRoomOutlined,
  NoSimOutlined,
  NotInterestedOutlined,
  NotListedLocationOutlined,
  NoteAddOutlined,
  NoteOutlined,
  NotesOutlined,
  NotificationImportantOutlined,
  NotificationsActiveOutlined,
  NotificationsNoneOutlined,
  NotificationsOffOutlined,
  NotificationsOutlined,
  NotificationsPausedOutlined,
  OfflineBoltOutlined,
  OfflinePinOutlined,
  OndemandVideoOutlined,
  OpacityOutlined,
  OpenInBrowserOutlined,
  OpenInNewOutlined,
  OpenWithOutlined,
  OutdoorGrillOutlined,
  OutlinedFlag,
  OutlinedFlagOutlined,
  OutlinedFlagRounded,
  OutlinedFlagSharp,
  OutlinedFlagTwoTone,
  PagesOutlined,
  PageviewOutlined,
  PaletteOutlined,
  PanToolOutlined,
  PanoramaFishEyeOutlined,
  PanoramaHorizontalOutlined,
  PanoramaOutlined,
  PanoramaVerticalOutlined,
  PanoramaWideAngleOutlined,
  PartyModeOutlined,
  PauseCircleFilledOutlined,
  PauseCircleOutlineOutlined,
  PauseOutlined,
  PausePresentationOutlined,
  PaymentOutlined,
  PeopleAltOutlined,
  PeopleOutlineOutlined,
  PeopleOutlined,
  PermCameraMicOutlined,
  PermContactCalendarOutlined,
  PermDataSettingOutlined,
  PermDeviceInformationOutlined,
  PermIdentityOutlined,
  PermMediaOutlined,
  PermPhoneMsgOutlined,
  PermScanWifiOutlined,
  PersonAddDisabledOutlined,
  PersonAddOutlined,
  PersonOutlineOutlined,
  PersonOutlined,
  PersonPinCircleOutlined,
  PersonPinOutlined,
  PersonalVideoOutlined,
  PetsOutlined,
  PhoneAndroidOutlined,
  PhoneBluetoothSpeakerOutlined,
  PhoneCallbackOutlined,
  PhoneDisabledOutlined,
  PhoneEnabledOutlined,
  PhoneForwardedOutlined,
  PhoneInTalkOutlined,
  PhoneIphoneOutlined,
  PhoneLockedOutlined,
  PhoneMissedOutlined,
  PhoneOutlined,
  PhonePausedOutlined,
  PhonelinkEraseOutlined,
  PhonelinkLockOutlined,
  PhonelinkOffOutlined,
  PhonelinkOutlined,
  PhonelinkRingOutlined,
  PhonelinkSetupOutlined,
  PhotoAlbumOutlined,
  PhotoCameraOutlined,
  PhotoFilterOutlined,
  PhotoLibraryOutlined,
  PhotoOutlined,
  PhotoSizeSelectActualOutlined,
  PhotoSizeSelectLargeOutlined,
  PhotoSizeSelectSmallOutlined,
  PictureAsPdfOutlined,
  PictureInPictureAltOutlined,
  PictureInPictureOutlined,
  PieChartOutlined,
  PinDropOutlined,
  PlaceOutlined,
  PlayArrowOutlined,
  PlayCircleFilledOutlined,
  PlayCircleFilledWhiteOutlined,
  PlayCircleOutlineOutlined,
  PlayForWorkOutlined,
  PlaylistAddCheckOutlined,
  PlaylistAddOutlined,
  PlaylistPlayOutlined,
  PlusOneOutlined,
  PolicyOutlined,
  PollOutlined,
  PoolOutlined,
  PortableWifiOffOutlined,
  PortraitOutlined,
  PostAddOutlined,
  PowerInputOutlined,
  PowerOffOutlined,
  PowerOutlined,
  PowerSettingsNewOutlined,
  PregnantWomanOutlined,
  PresentToAllOutlined,
  PrintDisabledOutlined,
  PrintOutlined,
  PriorityHighOutlined,
  PublicOutlined,
  PublishOutlined,
  QueryBuilderOutlined,
  QuestionAnswerOutlined,
  QueueMusicOutlined,
  QueueOutlined,
  QueuePlayNextOutlined,
  RadioButtonCheckedOutlined,
  RadioButtonUncheckedOutlined,
  RadioOutlined,
  RateReviewOutlined,
  ReceiptOutlined,
  RecentActorsOutlined,
  RecordVoiceOverOutlined,
  RedeemOutlined,
  RedoOutlined,
  RefreshOutlined,
  RemoveCircleOutlineOutlined,
  RemoveCircleOutlined,
  RemoveFromQueueOutlined,
  RemoveOutlined,
  RemoveRedEyeOutlined,
  RemoveShoppingCartOutlined,
  ReorderOutlined,
  RepeatOneOutlined,
  RepeatOutlined,
  Replay10Outlined,
  Replay30Outlined,
  Replay5Outlined,
  ReplayOutlined,
  ReplyAllOutlined,
  ReplyOutlined,
  ReportOffOutlined,
  ReportOutlined,
  ReportProblemOutlined,
  RestaurantMenuOutlined,
  RestaurantOutlined,
  RestoreFromTrashOutlined,
  RestoreOutlined,
  RestorePageOutlined,
  RingVolumeOutlined,
  RoomOutlined,
  RoomServiceOutlined,
  Rotate90DegreesCcwOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  RoundedCornerOutlined,
  RouterOutlined,
  RowingOutlined,
  RssFeedOutlined,
  RvHookupOutlined,
  SatelliteOutlined,
  SaveAltOutlined,
  SaveOutlined,
  ScannerOutlined,
  ScatterPlotOutlined,
  ScheduleOutlined,
  SchoolOutlined,
  ScoreOutlined,
  ScreenLockLandscapeOutlined,
  ScreenLockPortraitOutlined,
  ScreenLockRotationOutlined,
  ScreenRotationOutlined,
  ScreenShareOutlined,
  SdCardOutlined,
  SdStorageOutlined,
  SearchOutlined,
  SecurityOutlined,
  SelectAllOutlined,
  SendOutlined,
  SentimentDissatisfiedOutlined,
  SentimentSatisfiedAltOutlined,
  SentimentSatisfiedOutlined,
  SentimentVeryDissatisfiedOutlined,
  SentimentVerySatisfiedOutlined,
  SettingsApplicationsOutlined,
  SettingsBackupRestoreOutlined,
  SettingsBluetoothOutlined,
  SettingsBrightnessOutlined,
  SettingsCellOutlined,
  SettingsEthernetOutlined,
  SettingsInputAntennaOutlined,
  SettingsInputComponentOutlined,
  SettingsInputCompositeOutlined,
  SettingsInputHdmiOutlined,
  SettingsInputSvideoOutlined,
  SettingsOutlined,
  SettingsOverscanOutlined,
  SettingsPhoneOutlined,
  SettingsPowerOutlined,
  SettingsRemoteOutlined,
  SettingsSystemDaydreamOutlined,
  SettingsVoiceOutlined,
  ShareOutlined,
  ShopOutlined,
  ShopTwoOutlined,
  ShoppingBasketOutlined,
  ShoppingCartOutlined,
  ShortTextOutlined,
  ShowChartOutlined,
  ShuffleOutlined,
  ShutterSpeedOutlined,
  SignalCellular0BarOutlined,
  SignalCellular1BarOutlined,
  SignalCellular2BarOutlined,
  SignalCellular3BarOutlined,
  SignalCellular4BarOutlined,
  SignalCellularAltOutlined,
  SignalCellularConnectedNoInternet0BarOutlined,
  SignalCellularConnectedNoInternet1BarOutlined,
  SignalCellularConnectedNoInternet2BarOutlined,
  SignalCellularConnectedNoInternet3BarOutlined,
  SignalCellularConnectedNoInternet4BarOutlined,
  SignalCellularNoSimOutlined,
  SignalCellularNullOutlined,
  SignalCellularOffOutlined,
  SignalWifi0BarOutlined,
  SignalWifi1BarLockOutlined,
  SignalWifi1BarOutlined,
  SignalWifi2BarLockOutlined,
  SignalWifi2BarOutlined,
  SignalWifi3BarLockOutlined,
  SignalWifi3BarOutlined,
  SignalWifi4BarLockOutlined,
  SignalWifi4BarOutlined,
  SignalWifiOffOutlined,
  SimCardOutlined,
  SingleBedOutlined,
  SkipNextOutlined,
  SkipPreviousOutlined,
  SlideshowOutlined,
  SlowMotionVideoOutlined,
  SmartphoneOutlined,
  SmokeFreeOutlined,
  SmokingRoomsOutlined,
  SmsFailedOutlined,
  SmsOutlined,
  SnoozeOutlined,
  SortByAlphaOutlined,
  SortOutlined,
  SpaOutlined,
  SpaceBarOutlined,
  SpeakerGroupOutlined,
  SpeakerNotesOffOutlined,
  SpeakerNotesOutlined,
  SpeakerOutlined,
  SpeakerPhoneOutlined,
  SpeedOutlined,
  SpellcheckOutlined,
  SportsBaseballOutlined,
  SportsBasketballOutlined,
  SportsCricketOutlined,
  SportsEsportsOutlined,
  SportsFootballOutlined,
  SportsGolfOutlined,
  SportsHandballOutlined,
  SportsHockeyOutlined,
  SportsKabaddiOutlined,
  SportsMmaOutlined,
  SportsMotorsportsOutlined,
  SportsOutlined,
  SportsRugbyOutlined,
  SportsSoccerOutlined,
  SportsTennisOutlined,
  SportsVolleyballOutlined,
  SquareFootOutlined,
  StarBorderOutlined,
  StarHalfOutlined,
  StarOutlineOutlined,
  StarOutlined,
  StarRateOutlined,
  StarsOutlined,
  StayCurrentLandscapeOutlined,
  StayCurrentPortraitOutlined,
  StayPrimaryLandscapeOutlined,
  StayPrimaryPortraitOutlined,
  StopOutlined,
  StopScreenShareOutlined,
  StorageOutlined,
  StoreMallDirectoryOutlined,
  StoreOutlined,
  StorefrontOutlined,
  StraightenOutlined,
  StreetviewOutlined,
  StrikethroughSOutlined,
  StyleOutlined,
  SubdirectoryArrowLeftOutlined,
  SubdirectoryArrowRightOutlined,
  SubjectOutlined,
  SubscriptionsOutlined,
  SubtitlesOutlined,
  SubwayOutlined,
  SupervisedUserCircleOutlined,
  SupervisorAccountOutlined,
  SurroundSoundOutlined,
  SwapCallsOutlined,
  SwapHorizOutlined,
  SwapHorizontalCircleOutlined,
  SwapVertOutlined,
  SwapVerticalCircleOutlined,
  SwitchCameraOutlined,
  SwitchVideoOutlined,
  SyncAltOutlined,
  SyncDisabledOutlined,
  SyncOutlined,
  SyncProblemOutlined,
  SystemUpdateAltOutlined,
  SystemUpdateOutlined,
  TabOutlined,
  TabUnselectedOutlined,
  TableChartOutlined,
  TabletAndroidOutlined,
  TabletMacOutlined,
  TabletOutlined,
  TagFacesOutlined,
  TapAndPlayOutlined,
  TerrainOutlined,
  TextFieldsOutlined,
  TextFormatOutlined,
  TextRotateUpOutlined,
  TextRotateVerticalOutlined,
  TextRotationAngledownOutlined,
  TextRotationAngleupOutlined,
  TextRotationDownOutlined,
  TextRotationNoneOutlined,
  TextsmsOutlined,
  TextureOutlined,
  TheatersOutlined,
  ThreeDRotationOutlined,
  ThreeSixtyOutlined,
  ThumbDownAltOutlined,
  ThumbDownOutlined,
  ThumbUpAltOutlined,
  ThumbUpOutlined,
  ThumbsUpDownOutlined,
  TimeToLeaveOutlined,
  TimelapseOutlined,
  TimelineOutlined,
  Timer10Outlined,
  Timer3Outlined,
  TimerOffOutlined,
  TimerOutlined,
  TitleOutlined,
  TocOutlined,
  TodayOutlined,
  ToggleOffOutlined,
  ToggleOnOutlined,
  TollOutlined,
  TonalityOutlined,
  TouchAppOutlined,
  ToysOutlined,
  TrackChangesOutlined,
  TrafficOutlined,
  TrainOutlined,
  TramOutlined,
  TransferWithinAStationOutlined,
  TransformOutlined,
  TransitEnterexitOutlined,
  TranslateOutlined,
  TrendingDownOutlined,
  TrendingFlatOutlined,
  TrendingUpOutlined,
  TripOriginOutlined,
  TuneOutlined,
  TurnedInNotOutlined,
  TurnedInOutlined,
  TvOffOutlined,
  TvOutlined,
  TwoWheelerOutlined,
  UnarchiveOutlined,
  UndoOutlined,
  UnfoldLessOutlined,
  UnfoldMoreOutlined,
  UnsubscribeOutlined,
  UpdateOutlined,
  UsbOutlined,
  VerifiedUserOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignCenterOutlined,
  VerticalAlignTopOutlined,
  VerticalSplitOutlined,
  VibrationOutlined,
  VideoCallOutlined,
  VideoLabelOutlined,
  VideoLibraryOutlined,
  VideocamOffOutlined,
  VideocamOutlined,
  VideogameAssetOutlined,
  ViewAgendaOutlined,
  ViewArrayOutlined,
  ViewCarouselOutlined,
  ViewColumnOutlined,
  ViewComfyOutlined,
  ViewCompactOutlined,
  ViewDayOutlined,
  ViewHeadlineOutlined,
  ViewListOutlined,
  ViewModuleOutlined,
  ViewQuiltOutlined,
  ViewStreamOutlined,
  ViewWeekOutlined,
  VignetteOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
  VoiceChatOutlined,
  VoiceOverOffOutlined,
  VoicemailOutlined,
  VolumeDownOutlined,
  VolumeMuteOutlined,
  VolumeOffOutlined,
  VolumeUpOutlined,
  VpnKeyOutlined,
  VpnLockOutlined,
  WallpaperOutlined,
  WarningOutlined,
  WatchLaterOutlined,
  WatchOutlined,
  WavesOutlined,
  WbAutoOutlined,
  WbCloudyOutlined,
  WbIncandescentOutlined,
  WbIridescentOutlined,
  WbSunnyOutlined,
  WcOutlined,
  WebAssetOutlined,
  WebOutlined,
  WeekendOutlined,
  WhatshotOutlined,
  WhereToVoteOutlined,
  WidgetsOutlined,
  WifiLockOutlined,
  WifiOffOutlined,
  WifiOutlined,
  WifiTetheringOutlined,
  WorkOffOutlined,
  WorkOutlineOutlined,
  WorkOutlined,
  WrapTextOutlined,
  YoutubeSearchedForOutlined,
  ZoomInOutlined,
  ZoomOutMapOutlined,
  ZoomOutOutlined
}

export default Icons
