import { UserInterfaceState, UserInterfaceActionTypes } from 'types/types'
import { PASSWORD_RESET_DONE } from '../actions/userInterface'

const initialState: UserInterfaceState = {
  passwordResetDone: false
}

const userInterface = (state = initialState, action: UserInterfaceActionTypes) => {
  switch (action.type) {
    case PASSWORD_RESET_DONE: {
      return {
        ...state,
        passwordResetDone: action.payload.passwordResetDone
      }
    }
    default:
      return state
  }
}

export default userInterface
