import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { Theme } from '@mui/material'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Wrapper from './Wrapper'

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: '8px'
  },
  card: {
    maxWidth: 344,
    border: '1px solid rgba(0, 44, 56, 0.12)',
    boxSizing: 'border-box',
    borderRadius: '4px',
    margin: 'auto',
    '@media (min-width: 1024px)': {
      maxWidth: 400
    }
  },
  cardActionArea: {
    cursor: 'default',
    '& > span': {
      all: 'unset',
      '& > span': {
        all: 'unset'
      }
    }
  },
  cardHeaderStyles: {
    padding: '16px 16px 0 16px'
  },
  headerContent: {
    alignSelf: 'flex-start'
  },
  overline: {
    lineHeight: 0.75
  },
  avatar: {
    height: '40px',
    width: '40px',
    backgroundColor: theme.palette.secondary.main
  },
  user: {
    color: theme.palette.secondary.main,
    textTransform: 'uppercase'
  },
  date: {
    color: theme.palette.text.secondary,
    textTransform: 'none'
  },
  subtitle: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
    textTransform: 'none',
    lineHeight: 2
  },
  textContent: {
    padding: '0px 16px 16px 70px'
  },
  noSubtitle: {
    marginTop: '-16px'
  },
  noCardHeaderContent: {
    marginTop: '-40px'
  },
  text: {
    lineHeight: '21px',
    color: theme.palette.text.primary
  }
}))

export interface Props {
  /**
   * Image for avatar
   */
  avatarImage?: string
  /**
   * Text for avatar
   */
  avatarText?: string
  /**
   * What avatar color to use
   */
  avatarColor?: string
  /**
   * What avatar background color to use
   */
  avatarBackgroundColor?: string
  /**
   * User name on feed card
   */
  user?: string
  /**
   * Date displayed next to the user
   */
  date?: string
  /**
   * Subtitle displayed under the user and the date
   */
  subtitle?: string
  /**
   * FeedCard's text
   */
  text?: string
  /**
   * FeedCard's text, supports html
   */
  htmlText?: string
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const FeedCard: React.FC<Props> = ({
  avatarImage,
  avatarText,
  avatarColor,
  avatarBackgroundColor,
  user,
  date,
  subtitle,
  text,
  htmlText,
  style,
  className,
  maxWidth
}) => {
  const { classes } = useStyles()

  return (
    <Wrapper style={style} className={className} maxWidth={maxWidth}>
      <div className={classes.container}>
        <Card classes={{ root: classes.card }} style={{ minHeight: '77px' }}>
          <CardActionArea className={classes.cardActionArea}>
            <CardHeader
              classes={{
                root: classes.cardHeaderStyles,
                content: classes.headerContent
              }}
              avatar={
                avatarImage ? (
                  <Avatar alt="Person" src={avatarImage} />
                ) : (
                  <Avatar
                    aria-label="avatar"
                    className={classes.avatar}
                    style={{
                      color: avatarColor,
                      backgroundColor: avatarBackgroundColor
                    }}
                  >
                    {avatarText}
                  </Avatar>
                )
              }
              title={
                <div style={{ display: 'flex' }}>
                  <Typography
                    style={{
                      marginRight: user ? '8px' : '0px',
                      marginBottom: '4px',
                      color: avatarBackgroundColor,
                      textTransform: 'uppercase',
                      lineHeight: 0.75
                    }}
                    variant="overline"
                  >
                    {user}
                  </Typography>
                  {date ? (
                    <Typography
                      className={classes.date}
                      style={{ lineHeight: 0.75 }}
                      variant="overline"
                    >
                      {date}
                    </Typography>
                  ) : null}
                </div>
              }
              subheader={
                <Typography
                  variant="overline"
                  className={classes.subtitle}
                  style={{ lineHeight: 0.75 }}
                >
                  {subtitle}
                </Typography>
              }
            />
            <CardContent className={classes.textContent}>
              {text && !htmlText ? (
                <Typography variant="body2" className={classes.text}>
                  {text}
                </Typography>
              ) : null}
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </Wrapper>
  )
}

export default FeedCard
