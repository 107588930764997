/**
 * UQ AppBar components action object
 * to open guide pdf
 */
export const appBarAction = {
  icon: 'HelpOutline',
  onClick: () => {
    window.open(process.env.REACT_APP_GUIDE_URL)
  }
}
