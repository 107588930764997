// @ts-nocheck
import { onError } from '@apollo/client/link/error'

export const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError && networkError.statusCode === 401) {
    localStorage.removeItem('token')
    localStorage.removeItem('userId')
    window.location.href = '/kirjaudu'
  }
})
