import { useState, useEffect, useRef, useCallback } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Close, Edit, PlayCircleFilled, Pause, Stop } from '@mui/icons-material'
import {
  Snackbar,
  IconButton,
  SelectChangeEvent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import {
  UqCircularProgress,
  UqContainer,
  UqButton,
  UqTypography,
  UqListItem,
  UqList,
  UqListItemSecondaryAction,
  UqListItemText,
  UqBox,
  UqAlert
} from '@uniqore/wrapper'
import { WhiteLabel, StyledButton } from 'components/common'
import { useMutation, useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { addTimerActionQuery, getCleaningsQuery } from './queries/queries'
import {
  formatDateTime,
  getWorkingHoursInSeconds,
  secondsToHoursMinutesAndSeconds
} from './utils/dateFunctions'
import { parseISO } from 'date-fns'

const useStyles = makeStyles()(() => ({
  whiteLabel: {
    position: 'fixed',
    bottom: '0',
    width: '100% !important',
    left: '0'
  },
  textButton: {
    width: '100%',
    textAlign: 'center'
  },
  speedDial: {
    position: 'fixed',
    left: '75%',
    bottom: 10
  },
  snackbar: {
    backgroundColor: '#FF3F8D'
  }
}))

type BreakTypeValues = 'Ruokatauko' | 'Kahvitauko' | 'Siirtymä kohteiden välillä' | 'Oma meno'
type BreakType = {
  value: BreakTypeValues,
  label: string
}

const WorkTimes = () => {
  const { classes } = useStyles()
  let history = useHistory()

  let workingHours: any = JSON.parse(localStorage.getItem('workingHours') || '[]')

  const [canStartWork, setCanStartWork] = useState<boolean>(false)
  const [canStartBreak, setCanStartBreak] = useState<boolean>(false)
  const [canStopBreak, setCanStopBreak] = useState<boolean>(false)
  const [canStopWork, setCanStopWork] = useState<boolean>(false)
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const [successMessage, showSuccessMessage] = useState<boolean>(false)
  const [worktimes, setWorktimes] = useState<any>([])

  const [open, setOpen] = useState(false)
  const [reason, setReason] = useState('Kahvitauko')
  const worktimeHistoryDays = 14
  const breakTypes: BreakType[] = [
    {value: 'Ruokatauko', label: 'Ruokatauko'},
    {value: 'Kahvitauko', label: 'Kahvitauko'},
    {value: 'Siirtymä kohteiden välillä', label: 'Siirtymä kohteiden välillä'},
    {value: 'Oma meno', label: 'Oma meno'}
  ]
  // Comparing value comes from localStorage which can be string | null
  // Not getting too deep here with typing.
  const unpaidBreakTypes: string[] = ['Oma meno']

  const handleChange = (event: SelectChangeEvent<string>) => {
    if (event.target.value) setReason(event.target.value)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    localStorage.setItem('breakReason', reason)
    setOpen(false)
  }

  const d = new Date()
  d.setDate(d.getDate() - worktimeHistoryDays)
  const now = useRef(d)
  const { loading: loadingDays, refetch } = useQuery(getCleaningsQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      id: process.env.REACT_APP_WORKTIMES,
      parameters: {
        date: now.current
      }
    },
    onCompleted(result) {
      setWorktimes(result.fetchDataview.data.records)
    }
  })

  const distinctDays = () => {
    const distinctDays: any = []
    worktimes.forEach((time: any) => {
      const date = formatDateTime(parseISO(time.fields.Start), 'yyyy-MM-dd')
      if (!distinctDays.includes(date)) {
        distinctDays.push(date)
      }
    })

    return distinctDays
  }

  const activeWorktime = JSON.parse(localStorage.getItem('activeWorktime') || '{}')
  const isEmpty = useCallback(() => {
    return Object.keys(activeWorktime).length === 0
  }, [activeWorktime])

  const isBreak = useCallback(() => {
    if (!isEmpty()) {
      if (activeWorktime.type === 'Break') {
        return true
      }
    }
    return false
  }, [activeWorktime, isEmpty])

  const currentType = () => {
    if (!isEmpty()) {
      return activeWorktime.type
    }
    return 'Work'
  }

  const [addTimerAction, { loading: statusLoading }] = useMutation(addTimerActionQuery, {
    onCompleted(mutationResult) {
      if (mutationResult.ingestForm.errors || !mutationResult.ingestForm.ok) {
        setShowSnackbar(true)
      } else {
        refetch()
      }
    }
  })

  const stopTimer = () => {
    localStorage.setItem('currentTimerStatus', 'stop')
  }

  const addWorkingHours = (startTime: Date, endTime: Date | null, status: string) => {
    workingHours.push({
      startTime,
      endTime,
      status
    })
  }

  const startWorkOrBreak = (type: string) => {
    const breakReason = localStorage.getItem('breakReason') ?? 'Break'
    if (type === 'Work') {
      localStorage.setItem('currentTimerStatus', 'work')
    } else if (type === 'Break') {
      localStorage.setItem('currentTimerStatus', 'break')
    }
    if (!isEmpty()) {
      // Worktime exists, save it and start new worktime
      const workingType = activeWorktime.type === 'Break' ? breakReason : 'Work'
      addWorkingHours(activeWorktime.start, new Date(), workingType)
      const filteredWorkingHours = getTimeSlotsWithoutDuplicates()
      localStorage.setItem('workingHours', JSON.stringify(filteredWorkingHours))

      addTimerAction({
        variables: {
          id: process.env.REACT_APP_START_WORK_BREAK,
          form: {
            start: activeWorktime.start,
            end: new Date(),
            type: workingType
          }
        }
      }).then(() => {
        localStorage.setItem(
          'activeWorktime',
          JSON.stringify({
            start: new Date(),
            type
          })
        )

        if (type === 'Break') {
          setCanStartWork(false)
          setCanStartBreak(false)
          setCanStopWork(false)
          setCanStopBreak(true)
        } else {
          setCanStartWork(false)
          setCanStartBreak(false)
          setCanStopWork(true)
          setCanStopBreak(false)
        }
      })
    } else {
      // No worktime exists, create new one
      const filteredWorkingHours = getTimeSlotsWithoutDuplicates()
      localStorage.setItem('workingHours', JSON.stringify(filteredWorkingHours))
      const workingType = type === 'Break' ? breakReason : 'Work'
      addWorkingHours(new Date(), null, workingType)
      localStorage.setItem('workingHours', JSON.stringify(workingHours))

      localStorage.setItem(
        'activeWorktime',
        JSON.stringify({
          start: new Date(),
          type
        })
      )
      setCanStartWork(false)
      setCanStartBreak(true)
      setCanStopWork(true)
      setCanStopBreak(false)
    }
  }

  const stopWorkOrBreak = (type: string) => {
    const breakReason = localStorage.getItem('breakReason') ?? 'Break'
    if (!isEmpty()) {
      if (type === 'Work') {
        localStorage.setItem('currentTimerStatus', 'stop')
        // Stop job worktime
        addWorkingHours(activeWorktime.start, new Date(), activeWorktime.type)
        const filteredWorkingHours = getTimeSlotsWithoutDuplicates()
        localStorage.setItem('workingHours', JSON.stringify(filteredWorkingHours))
        addTimerAction({
          variables: {
            id: process.env.REACT_APP_START_WORK_BREAK,
            form: {
              start: activeWorktime.start,
              end: new Date(),
              type: activeWorktime.type
            }
          }
        }).then(() => {
          localStorage.setItem('activeWorktime', '{}')
          stopTimer()
          setCanStartWork(true)
          // setIsStoppedWorkingTimer(true)
          setCanStartBreak(false)
          setCanStopWork(false)
          setCanStopBreak(false)
        })
      } else {
        localStorage.setItem('currentTimerStatus', 'work')
        const workingType = activeWorktime.type === 'Break' ? breakReason : 'Work'
        addWorkingHours(activeWorktime.start, new Date(), workingType)
        const filteredWorkingHours = getTimeSlotsWithoutDuplicates()
        localStorage.setItem('workingHours', JSON.stringify(filteredWorkingHours))
        addWorkingHours(new Date(), null, 'Work')
        localStorage.setItem('workingHours', JSON.stringify(workingHours))
        // Stop break worktime and start job worktime
        addTimerAction({
          variables: {
            id: process.env.REACT_APP_START_WORK_BREAK,
            form: {
              start: activeWorktime.start,
              end: new Date(),
              type: workingType
            }
          }
        }).then(() => {
          localStorage.setItem(
            'activeWorktime',
            JSON.stringify({
              start: new Date(),
              type: 'Work'
            })
          )
          setCanStartWork(false)
          setCanStartBreak(true)
          setCanStopBreak(false)
          setCanStopWork(true)
        })
      }
    }
  }

  const [timer, setTimer] = useState(
    secondsToHoursMinutesAndSeconds(getWorkingHoursInSeconds(workingHours || '[]'))
  )

  const getTimeSlotsWithoutDuplicates = () => {
    if (workingHours.length > 1) {
      const filteredSlots = workingHours.filter((timeSlot: any) => timeSlot.endTime !== null)
      return filteredSlots
    }
    return workingHours
  }

  const isPaidBreak = (type: string | null) => {
    if(type === null) return false
    return !unpaidBreakTypes.includes(type)
  }

  useEffect(() => {
    let timerInstance = setTimeout(() => {
      if (localStorage.getItem('currentTimerStatus')) {
        setTimer(secondsToHoursMinutesAndSeconds(getWorkingHoursInSeconds(workingHours || '[]')))
      }
    }, 1000)

    // Cleanup to avoid state update on unmounted component
    return () => {
      clearTimeout(timerInstance)
    }
  })

  useEffect(() => {
    if (isEmpty()) {
      setCanStartWork(true)
      setCanStopWork(false)
      setCanStartBreak(false)
    } else {
      if (isBreak()) {
        setCanStopBreak(true)
      } else {
        setCanStopWork(true)
        setCanStartBreak(true)
      }
    }
  }, [isBreak, isEmpty])

  return (
    <div style={{ width: '100%', marginBottom: '30px' }}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setShowSnackbar(false)}
          >
            <Close fontSize="small" />
          </IconButton>
        }
        message="Virhe tapahtui. Ole hyvä ja yritä hetken kuluttua uudestaan."
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={successMessage}
        autoHideDuration={6000}
        onClose={() => showSuccessMessage(false)}
        message="Huomio lisätty."
        color="primary"
        ContentProps={{
          className: classes.snackbar
        }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => showSuccessMessage(false)}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
      <Dialog open={open} onClose={handleClose} style={{ minWidth: '100%' }}>
        <DialogTitle>Valitse tauon syy</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <InputLabel id="dialog-label">Tauko</InputLabel>
            <Select
              fullWidth
              labelId="dialog-label"
              value={reason}
              onChange={handleChange}
              label="Tauko"
            >
              {breakTypes.map(breakType => (
                <MenuItem value={breakType.value} key={breakType.value}>
                  {breakType.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleClose}>Peruuta</StyledButton>
          <StyledButton
            variant="contained"
            onClick={() => {
              startWorkOrBreak('Break')
              handleClose()
            }}
          >
            Ok
          </StyledButton>
        </DialogActions>
      </Dialog>
      <UqContainer maxWidth="xs" fixed={true}>
        <UqTypography align="center" variant="h3">
          {timer}
        </UqTypography>
        {activeWorktime.type &&
          activeWorktime.type !== 'Work' &&
          isPaidBreak(localStorage.getItem('breakReason')) && (
            <UqTypography align="center" variant="body2">
              Olet palkallisella tauolla. Tauon aika lisätään työaikaasi, kun lopetat tauon.
            </UqTypography>
          )}
        <UqBox
          marginTop={24}
          marginBottom={24}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center'
          }}
        >
          {canStartWork ? (
            <UqButton
              startIcon={<PlayCircleFilled />}
              style={{ textAlign: 'center' }}
              color="secondary"
              variant="contained"
              onClick={() => startWorkOrBreak('Work')}
              disabled={statusLoading}
              sx={{ padding: '16px 24px' }}
            >
              {statusLoading ? <UqCircularProgress size="24px" color="inherit" /> : 'ALOITA TYÖ'}
            </UqButton>
          ) : null}

          {canStartBreak ? (
            <UqButton
              startIcon={<Pause />}
              style={{ textAlign: 'center' }}
              color="secondary"
              variant="contained"
              onClick={handleClickOpen}
              disabled={statusLoading}
              sx={{ padding: '16px 24px' }}
            >
              {statusLoading ? <UqCircularProgress size="24px" color="inherit" /> : 'TAUKO'}
            </UqButton>
          ) : null}
          {canStopWork || canStopBreak ? (
            <UqButton
              startIcon={<Stop />}
              style={{ textAlign: 'center' }}
              variant="contained"
              color="secondary"
              onClick={() =>
                currentType() === 'Work' ? stopWorkOrBreak('Work') : stopWorkOrBreak('Break')
              }
              disabled={statusLoading}
              sx={{ padding: '16px 24px' }}
            >
              {statusLoading ? (
                <UqCircularProgress size="24px" color="inherit" />
              ) : currentType() === 'Work' ? (
                'LOPETA TYÖ'
              ) : (
                'LOPETA TAUKO'
              )}
            </UqButton>
          ) : null}
        </UqBox>
      </UqContainer>
      <UqContainer maxWidth="xs" fixed={true}>
        <UqTypography align="left" variant="h6">
          Työpäivät (viimeiset {worktimeHistoryDays} päivää)
        </UqTypography>
        {loadingDays ? (
          <div
            style={{
              width: 60,
              margin: '25px auto'
            }}
          >
            <UqCircularProgress />
          </div>
        ) : (
          <>
          <UqList>
            {distinctDays().map((day: any) => {
              return (
                <UqListItem
                  key={day}
                  style={{ padding: '8px 0' }}
                  onClick={() => {
                    history.push(`/tyoaika/${day}`)
                  }}
                >
                  <UqListItemText primary={day} />
                    <UqListItemSecondaryAction>
                      <IconButton edge="end" aria-label="edit" disabled={loadingDays}>
                        <Edit />
                      </IconButton>
                    </UqListItemSecondaryAction>
                </UqListItem>
              )
            })}
          </UqList>
          {!worktimes.length && <UqAlert>Ei merkittyjä työaikoja</UqAlert>}
          </>
        )}
      </UqContainer>
      <UqContainer maxWidth="lg" fixed={true}>
        <WhiteLabel className={classes.whiteLabel} />
      </UqContainer>
    </div>
  )
}

export default WorkTimes
