import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import {
  Snackbar,
  IconButton,
  CircularProgress,
  AlertProps,
  Alert as MuiAlert,
  TextField,
  TextFieldProps
} from '@mui/material'
import { StyledButton, SearchDialog, AppBar, Dialog, WhiteLabel } from 'components/common'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import EditIcon from '@mui/icons-material/Edit'
import { Close, Edit } from '@mui/icons-material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import {
  UqContainer,
  UqListItem,
  UqTypography,
  UqBox,
  UqListItemText,
  UqListItemSecondaryAction,
  UqListItemIcon
} from '@uniqore/wrapper'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import {
  getCleaningsQuery,
  searchQuery,
  editDataQuery,
  addTimerActionQuery,
  getCleaningTimesQuery,
  markCompletedQuery
} from './queries/queries'
import {
  changeDateFormat,
  formatDateForEvents,
  formatDateTime,
  formatActionDateTime,
  lastSunday,
  secondsToHoursAndMinutes
} from './utils/dateFunctions'
import parse from 'date-fns/parse'
import nextMonday from 'date-fns/nextMonday'
import InProgressItems from './catalog/InProgressItems'
import ActiveItem from './catalog/ActiveItem'
import {
  workAllocationIsPlanned,
  workAllocationIsCompleted,
  userHasItemInProgress,
  hasItemsInProgress,
  getField
} from './utils/allocationFunctions'
import { appBarAction } from './utils/help'
import { Record, TimeRecord } from '../types/types'

const useStyles = makeStyles()(() => ({
  whiteLabel: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    left: '0'
  },
  snackbar: {
    backgroundColor: '#FF3F8D'
  },
  timeFields: {
    marginTop: 25
  }
}))

const Catalog = () => {
  const { classes } = useStyles()
  let history = useHistory()
  const [searchOpen, setSearchOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [startTimeValue, setStartTimeValue] = useState(new Date())
  const [endTimeValue, setEndTimeValue] = useState(new Date())
  const [pauseDuration, setPauseDuration] = useState(new Date())
  const [airtableId, setAirtableId] = useState<string>('')
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [successMessage, showSuccessMessage] = useState<boolean>(false)
  const [workAllocation, setWorkAllocation] = useState<number | boolean>(false)
  const [workAllocationTime, setWorkAllocationTime] = useState<number | boolean>(false)

  const [startTimeError, showStartTimeError] = useState<boolean>(false)
  const [startTimeErrorMessage, setStartTimeErrorMessage] = useState<string>('')
  const [endTimeError, showEndTimeError] = useState<boolean>(false)
  const [endTimeErrorMessage, setEndTimeErrorMessage] = useState<string>('')
  const [pauseError, showPauseError] = useState<boolean>(false)
  const [pauseErrorMessage, setPauseErrorMessage] = useState<string>('')
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)

  const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }

  const [addTimerAction] = useMutation(addTimerActionQuery)
  useEffect(() => {
    let actions: any = JSON.parse(localStorage.getItem('actions') || '[]')
    const pauseTimer = () => {
      const date = new Date()
      let currentItem = localStorage.getItem('currentItem')
      let parsedCurrentItem = currentItem ? JSON.parse(currentItem) : null
      addTimerAction({
        variables: {
          id: process.env.REACT_APP_PAUSE_INGEST_FORM_ID,
          form: {
            date: formatDateForEvents(date),
            engagement: parsedCurrentItem.engagementId
          }
        }
      }).then(() => {
        date.setSeconds(date.getSeconds() + 1)
        let startTime = formatActionDateTime(date)
        const timerAction = {
          startTime: startTime,
          endTime: null,
          status: 'pause'
        }
        actions.push(timerAction)
        if (actions[actions.length - 2].endTime === null) {
          actions[actions.length - 2].endTime = formatActionDateTime(new Date())
        }
        localStorage.setItem('actions', JSON.stringify(actions))
        actions = JSON.parse(localStorage.getItem('actions') || '[]')
      })
    }

    if (
      localStorage.getItem('itemInProgress') &&
      actions.length > 0 &&
      actions[actions.length - 1].status !== 'pause'
    ) {
      pauseTimer()
    }
  }, [addTimerAction])

  const allocationIsCompleted = (record: Record) => {
    return workAllocationIsCompleted(record)
  }

  const { data, loading, error, refetch } = useQuery(getCleaningsQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      id: process.env.REACT_APP_FETCHDATAVIEW_ID,
      parameters: {
        startDate: formatDateTime(lastSunday(), 'yyyy-MM-dd'),
        endDate: formatDateTime(nextMonday(new Date()), 'yyyy-MM-dd'),
        email: localStorage.getItem('email')
      }
    }
  })

  let records = []
  if (data) {
    records = data.fetchDataview.data.records

    records.sort((a: Record, b: Record) => {
      return new Date(a.fields.Planned).valueOf() - new Date(b.fields.Planned).valueOf()
    })

    // if itemInProgress is at stop status AND allocation is completed (by someone else in this case)
    // then clear the itemInProgress from localStorage
    if (localStorage.getItem('itemInProgress')) {
      // Get the allocation record from airtable data based on localStorage value
      const recordInProgress = records.find((r: Record) => {
        return r.id === localStorage.getItem('itemInProgress')
      })
      // Check the status of it
      if (recordInProgress && allocationIsCompleted(recordInProgress)) {
        let actions: any = JSON.parse(localStorage.getItem('actions') || '[]')
        // actions are cleared on timer stoppage so if actions are empty
        // that means that no active timer is present
        if (actions.length === 0) {
          localStorage.setItem('currentItem', '')
          localStorage.setItem('itemInProgress', '')
        }
      }
    }
  }

  /**
   *
   */
  const [markCompleted, { loading: completeLoading }] = useMutation(markCompletedQuery, {
    onCompleted(mutationResult) {
      if (mutationResult.ingestForm.errors || !mutationResult.ingestForm.ok) {
        setShowSnackbar(true)
      } else {
        refetch()
      }
    }
  })

  const [loadSearchData, { data: searchData, loading: searchLoading }] = useLazyQuery(searchQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      id: process.env.REACT_APP_FETCH_SEARCH_ID,
      parameters: {
        startDate: formatDateTime(lastSunday(), 'yyyy-MM-dd'),
        endDate: formatDateTime(nextMonday(new Date()), 'yyyy-MM-dd'),
        search: searchValue
      }
    }
  })

  let searchRecords = []
  if (searchData && searchData.fetchDataview) {
    searchRecords = searchData.fetchDataview.data.records
  }

  const onClickSearch = () => {
    loadSearchData()
    setSearchOpen(true)
  }

  const [loadWorkAllocationTimes, { data: timesData, loading: timesLoading }] = useLazyQuery(
    getCleaningTimesQuery,
    {
      fetchPolicy: 'no-cache',
      variables: {
        id: process.env.REACT_APP_FETCH_TIMES_ID,
        parameters: {
          workAllocation: workAllocation
        }
      }
    }
  )

  let workAllocationTimeRecords = []
  if (timesData) {
    workAllocationTimeRecords = timesData.fetchDataview.data.records
  }

  const [editWorkingTime, { loading: editLoading }] = useMutation(editDataQuery, {
    notifyOnNetworkStatusChange: true,
    onCompleted(mutationResult) {
      if (mutationResult.ingestForm.errors || !mutationResult.ingestForm.ok) {
        setOpenDialog(true)
        if (mutationResult.ingestForm.errors['endTime']) {
          showEndTimeError(true)
          setEndTimeErrorMessage(mutationResult.ingestForm.errors['endTime'][0])
        } else if (mutationResult.ingestForm.errors['startTime']) {
          showStartTimeError(true)
          setStartTimeErrorMessage(mutationResult.ingestForm.errors['startTime'][0])
        } else if (mutationResult.ingestForm.errors['pauses']) {
          showPauseError(true)
          setPauseErrorMessage(mutationResult.ingestForm.errors['pauses'][0])
        }
      } else {
        showSuccessMessage(true)
        setWorkAllocationTime(false)
        loadWorkAllocationTimes()
      }
    }
  })

  const foundDone = records.some((record: Record) => record.fields['Completed at'])
  const foundWaiting = records.some(
    (record: Record) =>
      !allocationIsCompleted(record) && record['id'] !== localStorage.getItem('itemInProgress')
  )

  const handleSubmit = (e: any, record: Record) => {
    localStorage.setItem(
      'currentItem',
      JSON.stringify({
        address: getField(record.fields, 'Address', 0),
        planned: record.fields['Planned'],
        company: record.fields['Client name (from Clients) (from Service agreements)'][0],
        engagementId: record.fields['UQ Engagement ID'],
        airtableId: record['id'],
        cleaningPlan:
          record.fields['Cleaning routine description'] !== undefined &&
          record.fields['Cleaning routine description'].length > 0
            ? record.fields['Cleaning routine description'][0]
            : ''
      })
    )
    history.push('/siivous')
  }

  const results: React.ReactNode[] = searchRecords.map((record: Record, index: number) => {
    if (allocationIsCompleted(record)) {
      // These are finished allocations
      return (
        <UqListItem key={index} style={{ cursor: 'pointer' }}>
          <UqListItemText
            primary={getField(record.fields, 'Address', 0)}
            secondary={[
              `${
                record.fields['Client name (from Clients) (from Service agreements)']
              }, ${changeDateFormat(record.fields['Planned'])}`
            ]}
          />
        </UqListItem>
      )
    } else {
      // Unfinished allocations can be selected
      return (
        <UqListItem
          key={index}
          style={{ cursor: 'pointer' }}
          onClick={(e: any) => handleSubmit(e, record)}
        >
          <UqListItemText
            primary={getField(record.fields, 'Address', 0)}
            secondary={[
              `${
                record.fields['Client name (from Clients) (from Service agreements)']
              }, ${changeDateFormat(record.fields['Planned'])}`
            ]}
          />
          <UqListItemSecondaryAction>
            <ArrowForwardIcon color="primary" />
          </UqListItemSecondaryAction>
        </UqListItem>
      )
    }
  })

  const workAllocationTimeResults: React.ReactNode[] = workAllocationTimeRecords.map(
    (record: TimeRecord, index: number) => {
      return (
        <UqListItem
          key={index}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setWorkAllocationTime(record.fields['ID'])
            getTimeRecord(record)
          }}
        >
          <UqListItemIcon>
            <AccessTimeIcon color="inherit" />
          </UqListItemIcon>
          <UqListItemText
            primary={`${record.fields['Lastname (from Employees)']} - ${formatDateTime(
              record.fields['Date'],
              'dd.MM.yyyy'
            )}`}
            secondary={
              <>
                <UqTypography
                  variant="body2"
                  color="text.secondary"
                  component="span" // needed for validate DOM-nesting
                  sx={{ display: 'block' }} // since component is 'span', set display to block
                >
                  {`klo. ${secondsToHoursAndMinutes(
                    record.fields['Start time']
                  )} - ${secondsToHoursAndMinutes(record.fields['End time'])}`}
                </UqTypography>
                <UqTypography
                  variant="body2"
                  color="text.secondary"
                  component="span" // needed for validate DOM-nesting
                  sx={{ display: 'block' }} // since component is 'span', set display to block
                >
                  {`Yhteensä: ${secondsToHoursAndMinutes(record.fields['Total duration'])}`}
                </UqTypography>
              </>
            }
          />
          <UqListItemSecondaryAction>
            <Edit color="inherit" />
          </UqListItemSecondaryAction>
        </UqListItem>
      )
    }
  )

  const returnToPrev = () => {
    history.push('/')
  }

  if (loading || completeLoading) {
    return (
      <UqContainer>
        <div
          style={{
            width: 60,
            margin: '25px auto'
          }}
        >
          <CircularProgress />
        </div>
      </UqContainer>
    )
  }

  if (error) {
    return <div />
  }

  const getAllocationtimes = (record: Record) => {
    setWorkAllocation(record.fields['ID'])
    loadWorkAllocationTimes()
  }

  const getTimeRecord = (record: TimeRecord) => {
    const startTime = secondsToHoursAndMinutes(record.fields['Start time'])
    const endTime = secondsToHoursAndMinutes(record.fields['End time'])
    const pausesDuration = record.fields['Pauses duration']
      ? secondsToHoursAndMinutes(record.fields['Pauses duration'])
      : '00:00'

    let formattedStartTime = parse(startTime, 'HH:mm', new Date())
    let formattedEndTime = parse(endTime, 'HH:mm', new Date())
    let formattedPauseTime = parse(pausesDuration, 'HH:mm', new Date())

    setStartTimeValue(formattedStartTime)
    setEndTimeValue(formattedEndTime)
    setAirtableId(record['id'])
    setPauseDuration(formattedPauseTime)

    return record
  }

  const sendEditedTime = () => {
    editWorkingTime({
      variables: {
        id: process.env.REACT_APP_EDIT_ITEM_INGEST_FORM_ID,
        form: {
          startTime: formatDateTime(startTimeValue, 'HH:mm'),
          endTime: formatDateTime(endTimeValue, 'HH:mm'),
          airtableId: airtableId,
          pauses: formatDateTime(pauseDuration, 'HH:mm')
        }
      }
    })
  }

  const appBarActions = () => {
    const actions = []
    if (process.env.REACT_APP_GUIDE_URL) {
      actions.push(appBarAction)
    }
    actions.push({ icon: 'Search', onClick: onClickSearch })
    return actions
  }

  return (
    <div style={{ width: '100%' }}>
      <UqContainer maxWidth="sm">
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={() => setShowSnackbar(false)}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setShowSnackbar(false)}
            >
              <Close fontSize="small" />
            </IconButton>
          }
        >
          <Alert severity="error">
            Virhe tapahtui. Ole hyvä ja yritä hetken kuluttua uudestaan.
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={successMessage}
          autoHideDuration={6000}
          onClose={() => showSuccessMessage(false)}
          message="Muokkaus onnistui!"
          color="primary"
          ContentProps={{
            className: classes.snackbar
          }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => showSuccessMessage(false)}
            >
              <Close fontSize="small" />
            </IconButton>
          }
        />
        <AppBar
          mainAction={{ icon: 'ArrowBack', onClick: returnToPrev }}
          text="Takaisin"
          actions={appBarActions()}
          backgroundColor="#fafafa"
        />
      </UqContainer>
      <SearchDialog
        open={searchOpen}
        searchValue={searchValue}
        onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
          setSearchValue(e.target.value)
        }
        onClose={() => setSearchOpen(false)}
        inputPlaceholder="Etsi..."
        resultsHeading="Hakutulokset"
        isSearching={searchLoading}
        resultsPlaceholder={
          searchValue === ''
            ? 'Hae esim. "Osoite", "Yritys"'
            : !searchLoading && results.length === 0
            ? 'Ei tuloksia'
            : ''
        }
        searchResults={searchValue === '' ? [] : results}
      />
      <UqContainer maxWidth="sm" fixed={true}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Dialog
            open={openDialog}
            onClose={() => {
              setWorkAllocation(false)
              setWorkAllocationTime(false)
              workAllocationTimeRecords = []
              setOpenDialog(false)
            }}
            title={workAllocationTime ? 'Muokkaa' : 'Aikakirjaukset'}
            actionComponents={
              workAllocationTime
                ? [
                    <StyledButton
                      disabled={editLoading}
                      variant="text"
                      color="secondary"
                      onClick={() => setWorkAllocationTime(false)}
                    >
                      Peruuta
                    </StyledButton>,
                    <StyledButton
                      disabled={editLoading}
                      variant="contained"
                      color="primary"
                      onClick={sendEditedTime}
                    >
                      {editLoading ? <CircularProgress size="24px" color="inherit" /> : 'Lähetä'}
                    </StyledButton>
                  ]
                : workAllocation
                ? [
                    <StyledButton
                      variant="text"
                      onClick={() => setOpenDialog(false)}
                      color="secondary"
                    >
                      Sulje
                    </StyledButton>
                  ]
                : []
            }
          >
            {timesLoading && (
              <UqContainer style={{ textAlign: 'center' }}>
                <CircularProgress />
              </UqContainer>
            )}
            {!timesLoading && !workAllocationTimeRecords.length ? (
              <UqContainer>
                <p>Aikakirjauksia ei löytynyt</p>
              </UqContainer>
            ) : (
              ''
            )}
            {!workAllocationTime && <>{workAllocationTimeResults}</>}
            {workAllocationTime && (
              <>
                <TimePicker
                  key="start"
                  className={classes.timeFields}
                  value={startTimeValue}
                  inputFormat="HH:mm"
                  renderInput={(params: TextFieldProps) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={startTimeError}
                      helperText={startTimeErrorMessage}
                    />
                  )}
                  ampm={false}
                  label="Aloitus"
                  onChange={(date: any) => setStartTimeValue(date)}
                  onAccept={(date: any) => {
                    setStartTimeValue(date)
                  }}
                />
                <TimePicker
                  key="end"
                  value={endTimeValue}
                  className={classes.timeFields}
                  inputFormat="HH:mm"
                  renderInput={(params: TextFieldProps) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={endTimeError}
                      helperText={endTimeErrorMessage}
                    />
                  )}
                  ampm={false}
                  label="Lopetus"
                  onChange={(date: any) => setEndTimeValue(date)}
                  onAccept={(date: any) => {
                    setEndTimeValue(date)
                  }}
                />
                <TimePicker
                  key="pauses"
                  value={pauseDuration}
                  className={classes.timeFields}
                  inputFormat="HH:mm"
                  renderInput={(params: TextFieldProps) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={pauseError}
                      helperText={pauseErrorMessage}
                    />
                  )}
                  ampm={false}
                  label="Taukoja"
                  onChange={(date: any) => setPauseDuration(date)}
                  onAccept={(date: any) => {
                    setPauseDuration(date)
                  }}
                />
              </>
            )}
          </Dialog>
        </LocalizationProvider>
        <UqBox padding={'16px'}>
          <UqTypography variant="h5" align="left" color="text" paddingBottom={'8px'}>
            Suunnitellut
          </UqTypography>
          <UqTypography variant="body2" color="text" align="left">
            {foundWaiting ? '' : 'Ei kohteita'}
          </UqTypography>
        </UqBox>
        {records.map((record: Record, index: number) => {
          // Render planned allocation
          if (workAllocationIsPlanned(record)) {
            return (
              <UqListItem
                key={index}
                style={{ cursor: 'pointer' }}
                onClick={(e: any) => (userHasItemInProgress() ? false : handleSubmit(e, record))}
              >
                <UqListItemText
                  primary={getField(record.fields, 'Address', 0) as string}
                  secondary={[
                    `${
                      record.fields['Client name (from Clients) (from Service agreements)'] || '-'
                    }, ${changeDateFormat(record.fields['Planned'])}`
                  ]}
                />
                {!userHasItemInProgress() && (
                  <UqListItemSecondaryAction>
                    <ArrowForwardIcon color="primary" />
                  </UqListItemSecondaryAction>
                )}
              </UqListItem>
            )
          } else return null
        })}
        <UqBox padding={'16px'}>
          <UqTypography variant="h5" align="left" paddingBottom={'8px'} color="text">
            Kesken
          </UqTypography>
          <UqTypography variant="body2" color="text" align="left">
            {hasItemsInProgress(records) ? '' : 'Ei kohteita'}
          </UqTypography>
        </UqBox>

        <ActiveItem />

        <InProgressItems
          records={records}
          onComplete={options => {
            markCompleted(options)
          }}
          onContinue={(record: Record) => {
            handleSubmit({}, record)
          }}
        />
        <UqBox padding={'16px'}>
          <UqTypography variant="h5" align="left" paddingBottom={'8px'} color="text">
            Valmis
          </UqTypography>
          <UqTypography variant="body2" color="text" align="left">
            {foundDone ? '' : 'Ei kohteita'}
          </UqTypography>
        </UqBox>

        {records.map((record: Record, index: number) => {
          if (allocationIsCompleted(record)) {
            return (
              <UqListItem
                key={index}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setOpenDialog(true)
                  getAllocationtimes(record)
                }}
              >
                <UqListItemText
                  primary={getField(record.fields, 'Address', 0) as string}
                  secondary={
                    <>
                      <UqTypography
                        variant="body2"
                        color="text.secondary"
                        component="span" // needed for validate DOM-nesting
                        sx={{ display: 'block' }} // since component is 'span', set display to block
                      >
                        {`${
                          record.fields['Client name (from Clients) (from Service agreements)']
                        }, ${changeDateFormat(record.fields['Planned'])}`}
                      </UqTypography>
                      <UqTypography
                        variant="body2"
                        color="text.secondary"
                        component="span" // needed for validate DOM-nesting
                        sx={{ display: 'block' }} // since component is 'span', set display to block
                      >
                        {(record.fields['Total duration from times'] as number)
                          ? secondsToHoursAndMinutes(record.fields['Total duration from times'])
                          : '00:00'}
                      </UqTypography>
                    </>
                  }
                />
                <UqListItemSecondaryAction>
                  <EditIcon color="primary" />
                </UqListItemSecondaryAction>
              </UqListItem>
            )
          } else return null
        })}
        <WhiteLabel className={classes.whiteLabel} />
      </UqContainer>
    </div>
  )
}

export default Catalog
