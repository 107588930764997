import React, { useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'
import theme from 'theme/theme'
import { default as MaterialAppBar } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Icons from './Icons'
import Wrapper from './Wrapper'

const useStyles = makeStyles()(() => ({
  border: {
    borderBottom: '1px solid rgba(0, 44, 56, 0.12)'
  },
  centerText: {
    textAlign: 'center'
  }
}))

export interface AppBarAction {
  icon: string
  onClick?: React.MouseEventHandler
}

export interface Props {
  /**
   * Hides component until value in pixels is scrolled down.
   */
  showScrollHeight?: number
  /**
   * What position to use.
   */
  position?: 'fixed' | 'absolute' | 'relative' | 'static' | 'sticky'
  /**
   * Shows bottom border.
   */
  border?: boolean
  /**
   * What logo to use. Can be a string or a React Node.
   */
  logo?: string | React.ReactNode
  /**
   * Logo's size in pixels.
   */
  logoSize?: number
  /**
   * AppBar's leftmost action, an object with icon and onClick properties. Icon uses Material UI icon names.
   */
  mainAction?: AppBarAction
  /**
   * What text to use.
   */
  text?: string
  /**
   * Centers text or logo.
   */
  centerText?: boolean
  /**
   * What color for content (text, actions) to use.
   */
  color?: string
  /**
   * What background color to use.
   */
  backgroundColor?: string
  /**
   * AppBar's actions on the right side. An array of objects with icon and onClick properties.
   */
  actions?: AppBarAction[]
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const AppBar: React.FC<Props> = ({
  showScrollHeight,
  position = 'static',
  border = false,
  logo,
  logoSize = 90,
  text,
  centerText = false,
  color,
  backgroundColor = '#FFFFFF',
  mainAction = { icon: '' },
  actions = [],
  style,
  className,
  maxWidth
}) => {
  const { classes } = useStyles()
  const [visible, setVisible] = useState<boolean>(true)
  const contentColor = color || theme.palette.text.primary

  useEffect(() => {
    if (showScrollHeight) {
      const handleScroll = () => {
        const show = window.scrollY > showScrollHeight
        show ? setVisible(true) : setVisible(false)
      }
      document.addEventListener('scroll', handleScroll)
      return () => {
        document.removeEventListener('scroll', handleScroll)
      }
    }
    return setVisible(true)
  }, [showScrollHeight])

  let iconType: string | undefined = mainAction.icon
  if (!Object.prototype.hasOwnProperty.call(Icons, mainAction.icon)) {
    iconType = undefined
  }

  return (
    <Wrapper style={style} className={className}>
      <MaterialAppBar
        elevation={0}
        position={position}
        sx={{
          backgroundColor: backgroundColor === 'transparent' ? backgroundColor : 'white'
        }}
        style={{
          transition: showScrollHeight ? '150ms' : '',
          transform: !visible ? 'translateY(-56px)' : ''
        }}
        className={border ? classes.border : ''}
      >
        <Toolbar
          style={{
            backgroundColor: backgroundColor ? backgroundColor : 'white',
            paddingLeft: '16px',
            paddingRight: '16px',
            display: 'block',
            minHeight: '56px'
          }}
        >
          <Wrapper
            style={{
              display: 'flex',
              position: 'relative',
              alignItems: 'center',
              minHeight: '56px'
            }}
            maxWidth={maxWidth}
          >
            {iconType ? (
              <IconButton
                edge="start"
                onClick={mainAction.onClick}
                sx={{ color: contentColor, marginRight: theme.spacing(2) }}
              >
                {React.createElement(Icons[iconType])}
              </IconButton>
            ) : null}
            {logo ? (
              typeof logo === 'string' || logo instanceof String ? (
                <div style={{ flexGrow: 1 }} className={centerText ? classes.centerText : ''}>
                  <img src={logo as string} style={{ width: `${logoSize}px` }} alt="logo" />
                </div>
              ) : (
                <div style={{ width: `${logoSize}px` }}>{logo}</div>
              )
            ) : (
              <Typography
                className={centerText ? classes.centerText : ''}
                variant="subtitle1"
                sx={{ color: contentColor, flexGrow: 1 }}
              >
                {text}
              </Typography>
            )}
            {actions.map((action, i) => {
              let icon: string | undefined = action.icon
              if (!Object.prototype.hasOwnProperty.call(Icons, action.icon)) {
                icon = undefined
              }
              return (
                <IconButton
                  key={i}
                  style={{ color: contentColor, marginLeft: '12px' }}
                  onClick={action.onClick}
                  edge="end"
                >
                  {icon ? React.createElement(Icons[icon]) : null}
                </IconButton>
              )
            })}
          </Wrapper>
        </Toolbar>
      </MaterialAppBar>
    </Wrapper>
  )
}

export default AppBar
