import produce from 'immer'
import { REFETCH } from 'store/actions/common'
import { CommonState, CommonActionTypes } from 'types/types'

const initialState: CommonState = {
  refetch: false
}

const common = (state = initialState, action: CommonActionTypes): CommonState => {
  switch (action.type) {
    case REFETCH: {
      const { payload } = action
      return produce(state, draft => {
        draft.refetch = payload.refetch
      })
    }

    default:
      return state
  }
}

export default common
