import React from 'react'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { CustomerUI } from './components/CustomerUI'
import LoginView from './components/Login'
import ForgotPassword from './components/ForgotPassword'
import Home from './components/Home'
import Catalog from './components/Catalog'
import InProgress from './components/InProgress'
import PrivateRoute from './components/auth/PrivateRoute'
import WorkTime from 'components/WorkTime'

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/kirjaudu" render={() => <LoginView />} />
      <Route exact path="/palauta-salasana" render={() => <ForgotPassword />} />
      <PrivateRoute exact path="/" component={() => <Home />} />
      <PrivateRoute exact path="/siivoukset" component={() => <Catalog />} />
      <PrivateRoute exact path="/siivous" component={() => <InProgress />} />
      <PrivateRoute exact path="/tyoaika/:date" component={() => <WorkTime />} />
      <Route exact path="/:engagement" children={<CustomerUI />} />
    </Switch>
  </Router>
)

export default App
