import { AppActions, UserInterfaceActionTypes } from 'types/types'
import { Dispatch } from 'redux'

export const PASSWORD_RESET_DONE = 'PASSWORD_RESET_DONE'

export const passwordResetDone =
  (passwordResetDone: boolean) =>
  (dispatch: Dispatch<AppActions>): UserInterfaceActionTypes => {
    return dispatch({
      type: PASSWORD_RESET_DONE,
      payload: { passwordResetDone }
    })
  }
