import React, { SetStateAction, useState } from 'react'
import Feedback from './Feedback'
import { StyledButton, StatusHero, Dialog, AppBar } from 'components/common'
import { UqButton, UqTextField } from '@uniqore/wrapper'
import { gql, useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { refetch as refetchAction } from 'store/actions/common'
import { Snackbar, Alert } from '@mui/material'
import { EngagementType } from 'types/types'
import { formatDateForEvents } from '../utils/dateFunctions'
import { Comment } from '@mui/icons-material'

interface Errors {
  message?: string
}

export interface Props {
  engagement: EngagementType
}

export const Header: React.FC<Props> = ({ engagement }) => {
  let feedbackGiven = engagement.metadata.find(obj => {
    return obj.key === 'feedbackGiven'
  })
  let feedbackGivenDefault = false
  let feedbackValueDefault = undefined
  if (feedbackGiven && feedbackGiven.value === '1') {
    feedbackGivenDefault = true
    feedbackValueDefault = 1
  }
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [errors, setErrors] = useState<Errors>({})
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const dispatch = useDispatch()
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [commentSubmitted, setCommentSubmitted] = useState<boolean>(feedbackGivenDefault)
  const [feedbackValue, setFeedbackValue] = useState<SetStateAction<any>>(feedbackValueDefault)

  const FORM_MUTATION = gql`
    mutation IngestActionForm($data: GenericScalar!, $id: ID!) {
      ingestForm(form: $data, id: $id) {
        ok
        errors
      }
    }
  `

  const [contact, { loading }] = useMutation(FORM_MUTATION, {
    onCompleted(result) {
      if (!result.ingestForm.ok) {
        setErrors(result.ingestForm.errors)
      } else {
        setOpen(false)
        setSnackbarOpen(true)
        setMessage('')
        setErrors({})
        dispatch(refetchAction(true))
      }
    }
  })

  const [rate] = useMutation(FORM_MUTATION, {
    onCompleted(result) {
      if (!result.ingestForm.ok) {
        console.warn('error')
      } else {
        dispatch(refetchAction(true))
      }
    }
  })

  const [rateComment] = useMutation(FORM_MUTATION, {
    onCompleted(result) {
      if (!result.ingestForm.ok) {
        console.warn('error')
      } else {
        dispatch(refetchAction(true))
        setCommentSubmitted(true)
        setOpenDialog(false)
        setInputValue('')
      }
    }
  })

  let component = null
  let dialog = null
  if (engagement.stage.name === 'Ready') {
    component = (
      <React.Fragment>
        <Feedback
          text="Kiitos palautteestasi"
          title="Kiitos"
          subtitle="Anna lisäpalaute?"
          onConfirm={() => setOpenDialog(true)}
          commentSubmitted={commentSubmitted}
          onCancel={() => {
            setCommentSubmitted(true)
          }}
          feedbackValue={feedbackValue}
          onChange={(value: number) => {
            setFeedbackValue(value)
            rate({
              variables: {
                data: {
                  feedback: value,
                  engagement: engagement.id,
                  date: formatDateForEvents(new Date())
                },
                id: process.env.REACT_APP_RATE_FORM_ID
              }
            })
          }}
        />
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          title="Anna lisäpalaute"
          children={[
            <UqTextField
              autoFocus
              fullWidth
              minRows={3}
              multiline
              placeholder={inputValue}
              value={inputValue}
              onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                setInputValue(e.target.value)
              }
            />
          ]}
          actionComponents={[
            <UqButton
              variant="text"
              color="inherit"
              shadow
              disablePadding
              onClick={() => setOpenDialog(false)}
              sx={{
                padding: '16px 24px',
                boxShadow:
                  '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
              }}
            >
              SULJE
            </UqButton>,
            <UqButton
              variant="contained"
              color="primary"
              disablePadding
              sx={{ padding: '16px 24px' }}
              onClick={() => {
                rateComment({
                  variables: {
                    data: {
                      comment: inputValue,
                      engagement: engagement.id,
                      date: formatDateForEvents(new Date())
                    },
                    id: process.env.REACT_APP_RATE_COMMENT_FORM_ID
                  }
                })
              }}
            >
              LÄHETÄ
            </UqButton>
          ]}
        />
      </React.Fragment>
    )
  } else {
    component = (
      <StyledButton
        onClick={() => setOpen(true)}
        color="primary"
        variant="contained"
        startIcon={<Comment />}
      >
        Ota yhteyttä
      </StyledButton>
    )

    dialog = (
      <Dialog
        open={open}
        title="Ota yhteyttä"
        onClose={() => setOpen(false)}
        actionComponents={[
          <StyledButton onClick={() => setOpen(false)} color={'primary'} variant={'text'}>
            Sulje
          </StyledButton>,
          <div>
            <StyledButton
              onClick={() =>
                contact({
                  variables: {
                    data: {
                      message,
                      engagement: engagement.id,
                      date: formatDateForEvents(new Date())
                    },
                    id: process.env.REACT_APP_CONTACT_FORM_ID
                  }
                })
              }
              color={'primary'}
              disabled={loading}
              variant="contained"
            >
              Lähetä
            </StyledButton>
          </div>
        ]}
        children={[
          <UqTextField
            style={{ marginTop: 15 }}
            label="Viesti"
            error={errors.message ? true : false}
            helperText={errors.message}
            onChange={(event: { target: { value: React.SetStateAction<string> } }) =>
              setMessage(event.target.value)
            }
            value={message}
            fullWidth
            minRows={3}
            multiline
          />
        ]}
      />
    )
  }

  let statusTitle = ''
  let statusSubtitle = ''
  let statusIcon = undefined
  let statusBody = ''

  if (engagement.stage.name === 'Ready') {
    let date = engagement.metadata.find(obj => {
      return obj.key === 'cleanEnded'
    })
    statusTitle = 'Siivous valmis'
    statusSubtitle = `Lopetimme siivouksen ${date ? date.value : ''}`
    statusIcon = 'EventAvailable'
    statusBody = 'Anna palautetta siivouksesta.'
  } else if (
    engagement.stage.name === 'New agreement' ||
    engagement.stage.name === 'Onboarding' ||
    engagement.stage.name === 'Underway'
  ) {
    let date = engagement.metadata.find(obj => {
      return obj.key === 'nextClean'
    })

    statusTitle = date ? date.value : ''
    statusSubtitle = 'Seuraava siivous'
    statusIcon = 'Sync'
  } else if (engagement.stage.name === 'Ongoing') {
    let date = engagement.metadata.find(obj => {
      return obj.key === 'cleanStarted'
    })
    statusTitle = 'Siivous käynnissä'
    statusSubtitle = `Aloitimme siivouksen ${date ? date.value : ''}`
    statusIcon = 'CheckCircle'
  }

  return (
    <React.Fragment>
      <AppBar
        backgroundColor="#FFF1E9"
        logo="https://minttisiivous.fi/wp-content/themes/theme/assets/img/mintti-text-logo.svg"
        logoSize={88}
        maxWidth={'xs'}
        color="secondary"
      />
      <StatusHero
        color={'#69315F'}
        icon={statusIcon}
        iconSize={80}
        title={statusTitle}
        subtitle={statusSubtitle}
        backgroundColor={'#FFF1E9'}
        actionComponent={component}
        body={statusBody}
        style={{ marginBottom: 32 }}
      />
      {dialog}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          Kiitos yhteydenotostasi
        </Alert>
      </Snackbar>
    </React.Fragment>
  )
}
