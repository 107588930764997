// @ts-nocheck
const analyticsMiddleware = () => next => action => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: action.type,
    payload: action.payload
  })

  return next(action)
}

export default analyticsMiddleware
