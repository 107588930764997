import { ReactElement, useState } from 'react'
import {
  UqListItem,
  UqButton,
  UqListItemText,
  UqTypography,
  UqListItemSecondaryAction
} from '@uniqore/wrapper'
import CheckIcon from '@mui/icons-material/Check'
import { Dialog } from 'components/common'
import {
  formatDateForEvents,
  formatDateTime,
  secondsToHoursAndMinutes,
  changeDateFormat
} from '../utils/dateFunctions'
import { workAllocationIsInProgress, userHasItemInProgress } from '../utils/allocationFunctions'
import { Record } from '../../types/types'

interface IonComplete {
  variables: {
    id: string | undefined
    form: {
      dateText: string
      engagement: string
      airtableId: string
      date: Date
      endTime: string
      completedAt: string
    }
  }
}

interface Props {
  records: Array<Record>
  onComplete: (options: IonComplete) => void
  onContinue: (record: Record) => void
}

function InProgressItems({ records, onComplete, onContinue }: Props): ReactElement {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [openRecord, setOpenRecord] = useState<Record | null>(null)

  const recordClicked = (record: Record) => {
    setOpenRecord(record)
    setDialogOpen(true)
  }

  const closeDialog = () => {
    setOpenRecord(null)
    setDialogOpen(false)
  }

  /**
   * ListItem's onClick callback
   * @param record
   */
  const markComplete = (record: Record) => {
    const date = new Date()
    const stopTime = formatDateTime(date, 'HH:mm')

    onComplete({
      variables: {
        id: process.env.REACT_APP_COMPLETE_INGEST_FORM_ID,
        form: {
          dateText: formatDateForEvents(date),
          engagement: record.fields['UQ Engagement ID'],
          airtableId: record.id,
          date: formatDateTime(date, 'yyyy-MM-dd'),
          endTime: stopTime,
          completedAt: date.toISOString()
        }
      }
    })
  }

  return (
    <>
      {records.map((record: Record, index: number) => {
        if (workAllocationIsInProgress(record)) {
          return (
            <div key={index} title="Merkkaa siivous kohteessa valmiiksi">
              <UqListItem style={{ cursor: 'pointer' }} onClick={() => recordClicked(record)}>
                <UqListItemText
                  primary={'Address' in record.fields ? record.fields['Address'][0] : ''}
                  secondary={
                    <>
                      <UqTypography
                        variant="body2"
                        color="text.secondary"
                        component="span" // needed for validate DOM-nesting
                        sx={{ display: 'block' }} // since component is 'span', set display to block
                      >
                        {`${
                          record.fields['Client name (from Clients) (from Service agreements)']
                        }, ${changeDateFormat(record.fields['Planned'])}`}
                      </UqTypography>
                      <UqTypography
                        variant="body2"
                        color="text.secondary"
                        component="span" // needed for validate DOM-nesting
                        sx={{ display: 'block' }} // since component is 'span', set display to block
                      >
                        {(record.fields['Total duration from times'] as number)
                          ? secondsToHoursAndMinutes(record.fields['Total duration from times'])
                          : '00:00'}
                      </UqTypography>
                    </>
                  }
                />
                <UqListItemSecondaryAction>
                  <CheckIcon color="primary" />
                </UqListItemSecondaryAction>
              </UqListItem>
            </div>
          )
        } else return null
      })}
      {openRecord ? (
        <Dialog
          open={dialogOpen && openRecord !== null}
          onClose={e => {
            closeDialog()
          }}
          title={'Address' in openRecord.fields ? openRecord.fields['Address'][0] : ''}
          actionComponents={[
            <UqButton
              variant="text"
              disablePadding
              onClick={() => closeDialog()}
              color="secondary"
              sx={{ textTransform: 'none', padding: '16px' }}
            >
              Peruuta
            </UqButton>
          ]}
        >
          <>
            <UqButton
              icon={'Check'}
              fullWidth
              onClick={(e: any) => markComplete(openRecord)}
              variant="contained"
              color="secondary"
              sx={{ textTransform: 'none', padding: '16px' }}
              marginBottom={8}
              marginTop={16}
            >
              Merkitse kohde valmiiksi
            </UqButton>
            {userHasItemInProgress() ? (
              <UqButton
                variant={'text'}
                disabled
                fullWidth
                sx={{ textTransform: 'none', padding: '16px' }}
                color="secondary"
              >
                Et voi jatkaa kohteen siivousta tässä kohteessa ennen kuin olet päättänyt toisen
                kohteen.
              </UqButton>
            ) : (
              <UqButton
                variant={'text'}
                fullWidth
                color="secondary"
                onClick={(e: any) => onContinue(openRecord)}
                sx={{ textTransform: 'none', padding: '16px' }}
              >
                Jatka siivousta kohteessa
              </UqButton>
            )}
          </>
        </Dialog>
      ) : null}
    </>
  )
}

export default InProgressItems
