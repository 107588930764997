import { setContext } from '@apollo/client/link/context'

export const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'X-Authorization': token ? `OrgUserToken ${token}` : '',
      'X-Organization': process.env.REACT_APP_ORGANIZATION
    }
  }
})
