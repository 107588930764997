import { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { UqContainer, UqTextField, UqButton, UqAlert } from '@uniqore/wrapper'
import { Login } from '@uniqore/module'
import { WhiteLabel } from 'components/common'
import { useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { gql } from '@apollo/client'
import { useMutation } from '@apollo/client'
import { passwordResetDone } from '../store/actions/userInterface'
import { CircularProgress, IconButton, InputAdornment } from '@mui/material'
import { MailOutline, Visibility, VisibilityOff } from '@mui/icons-material'

const useStyles = makeStyles()(() => ({
  form: {
    marginTop: '40%'
  },
  whiteLabel: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    left: '0'
  }
}))

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const resetEmailQuery = gql`
  mutation reset($email: EmailAddress!, $type: LoginType, $organization: ID, $lang: String) {
    requestPasswordReset(email: $email, type: $type, organization: $organization, lang: $lang) {
      error
    }
  }
`

const resetPasswordQuery = gql`
  mutation reset($token: String!, $pw: String!) {
    resetPassword(token: $token, newPassword: $pw) {
      error
    }
  }
`

const ForgotPassword = () => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  let query = useQuery()
  const token = query.get('token')
  const history = useHistory()

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordRepeat, setPasswordRepeat] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [isEmailRequest] = useState<boolean>(token === null)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [success, setSuccess] = useState<string>('')

  const [resetEmail, { loading: mutationLoading }] = useMutation(resetEmailQuery, {
    onCompleted(mutationResult) {
      if (mutationResult.requestPasswordReset.error) {
        setErrorMessage(mutationResult.requestPasswordReset.error)
      } else {
        setSuccess('Salasanan vaihtoviesti on lähetetty sähköpostiisi!')
        setEmail('')
      }
    }
  })

  const [resetPassword, { loading: resetLoading }] = useMutation(resetPasswordQuery, {
    onCompleted(mutationResult) {
      if (mutationResult.resetPassword.error) {
        setErrorMessage(mutationResult.resetPassword.error)
      } else {
        setPassword('')
        setPasswordRepeat('')
        dispatch(passwordResetDone(true))
        history.push('/kirjaudu')
      }
    }
  })

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setErrorMessage('')
    if (isEmailRequest) {
      if (email === '') {
        setErrorMessage('Sähköposti ei voi olla tyhjä')
      } else {
        resetEmail({
          variables: {
            email: email,
            type: 'ORGUSER',
            organization: process.env.REACT_APP_ORGANIZATION
            // lang: 'fi' // backend uses first it finds, so can leave out
          }
        })
      }
    } else {
      resetPassword({
        variables: {
          token,
          pw: password
        }
      })
    }
  }
  return (
    <UqContainer maxWidth="xs" fixed={true}>
      <div className={classes.form}>
        {success ? (
          <UqAlert severity="success">
            { success }
          </UqAlert>
        ) : (
          <>
            {isEmailRequest ? (
              <Login
                headingComponents={{
                  primary: {
                    text: 'Salasana unohtunut?'
                  },
                  secondary: {
                    text: ''
                  }
                }}
                contentComponents={[
                  <UqTextField
                    autoFocus
                    fullWidth
                    placeholder="Sähköposti"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutline />
                        </InputAdornment>
                      )
                    }}
                    name="email"
                    value={email}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                      setEmail(e.target.value)
                    }
                    error={errorMessage ? true : false}
                    helperText={errorMessage}
                  />
                ]}
                actionComponents={[
                  <UqButton
                    type="submit"
                    color="secondary"
                    variant="contained"
                    fullWidth
                    sx={{ padding: '16px 0' }}
                    onClick={(e: React.FormEvent) => handleSubmit(e)}
                  >
                    {mutationLoading ? (
                      <CircularProgress size="24px" color="inherit" />
                    ) : (
                      'NOLLAA SALASANA'
                    )}
                  </UqButton>
                ]}
              />
            ) : (
              <>
                <Login
                  headingComponents={{
                    primary: {
                      text: 'Vaihda salasana'
                    },
                    secondary: {
                      text: ''
                    }
                  }}
                  contentComponents={[
                    <UqTextField
                      autoFocus
                      fullWidth
                      value={password}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setPassword(e.target.value)
                      }}
                      placeholder="Salasana"
                      name="password"
                      autoComplete="false"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      error={errorMessage ? true : false}
                      helperText={errorMessage}
                    />,
                    <UqTextField
                      value={passwordRepeat}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPasswordRepeat(e.target.value)
                      }
                      placeholder="Salasana uudestaan"
                      fullWidth
                      autoComplete="false"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      error={errorMessage ? true : false}
                      helperText={errorMessage}
                    />
                  ]}
                  actionComponents={[
                    <UqButton 
                      type="submit" 
                      color="secondary" 
                      variant="contained" 
                      fullWidth 
                      sx={{ padding: '16px 0' }}
                      onClick={(e: React.FormEvent) => handleSubmit(e)}>
                      {resetLoading ? (
                        <CircularProgress size="24px" color="inherit" />
                      ) : (
                        'VAIHDA SALASANA'
                      )}
                    </UqButton>
                  ]}
                />
              </>
            )}
          </>
        )}
      <WhiteLabel className={classes.whiteLabel} />
      </div>
    </UqContainer>
  )
}

export default ForgotPassword
