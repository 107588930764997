import React, { useRef, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Wrapper from './Wrapper'
import Icons from './Icons'

export interface Props {
  /**
   *  Icon uses Material UI icon names.
   */
  icon?: string
  /**
   * Icon's size in pixels. (Default 80).
   */
  iconSize?: number
  /**
   * Image either as a ReactNode or as a string
   */
  image?: React.ReactNode | string
  /**
   * Video's url
   */
  video?: string
  /**
   * What color for content to use
   */
  color?: string
  /**
   * What background color to use
   */
  backgroundColor?: string
  /**
   * The notice text content.
   */
  noticeText?: string | React.ReactNode
  /**
   * The headline content.
   */
  headline?: string
  /**
   * The title content.
   */
  title?: string
  /**
   * The subtitle content.
   */
  subtitle?: string
  /**
   * The body content.
   */
  body?: string
  /**
   * If 'true', reverses headline, title and subtitle order.
   */
  reverseText?: boolean
  /**
   * The component to be displayed inside the content area.
   */
  contentComponent?: React.ReactNode
  /**
   * The component to be displayed at the bottom of the content area.
   */
  actionComponent?: React.ReactNode
  /**
   * Height of the component
   */
  componentHeight?: number
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
  /**
   * @ignore
   */
  getHeight?: number[]
  /**
   * @ignore
   */
  setGetHeight?: React.Dispatch<React.SetStateAction<number[]>>
}

const StatusHero: React.FC<Props> = ({
  icon = '',
  iconSize = 80,
  image,
  video,
  color = '#FFFFFF',
  backgroundColor = 'rgba(0, 44, 56, 0.87)',
  noticeText,
  headline,
  title,
  subtitle,
  body,
  reverseText = false,
  contentComponent,
  actionComponent,
  componentHeight,
  style,
  className,
  maxWidth,
  getHeight,
  setGetHeight
}) => {
  const [actionHeight, setActionHeight] = useState<number>(0)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const heightRef = useRef(null)
  const actionHeightRef = useRef(null)
  //   const { classes } = useStyles({ iconSize, actionHeight, componentHeight })

  /**
   * If this component has StatusHeroSwipe as a parent,
   * send this component's height back to the parent.
   * Attributes getHeight and setGetHeight are props from StatusHeroSwipe
   */
  useEffect(() => {
    if (getHeight && setGetHeight && isLoaded) {
      if (heightRef.current) {
        setGetHeight((arr: number[]) => [
          ...arr,
          // @ts-ignore
          heightRef.current.clientHeight + 48 // <-- add more height because of the StatusHeroSwipe indicators
        ])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heightRef, isLoaded])

  useEffect(() => {
    if (image && typeof image !== 'string') {
      setIsLoaded(true)
    }
    if (!image) {
      setIsLoaded(true)
    }
  }, [image])

  useEffect(() => {
    if (actionHeightRef.current) {
      // @ts-ignore
      setActionHeight(actionHeightRef.current.clientHeight)
    }
  }, [actionHeightRef, actionComponent])

  let iconType: string | undefined = icon
  if (!Object.prototype.hasOwnProperty.call(Icons, icon)) {
    iconType = undefined
  }

  return (
    <Wrapper style={style} className={className}>
      <div
        ref={heightRef}
        style={{
          backgroundColor,
          height: `${componentHeight}px`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Wrapper maxWidth={maxWidth}>
          <div style={{ textAlign: 'center', padding: '48px 16px' }}>
            {iconType ? (
              <div
                style={{
                  color
                }}
              >
                {React.createElement(Icons[iconType], {
                  style: { fontSize: iconSize }
                })}
              </div>
            ) : null}
            {image ? (
              typeof image === 'string' ? (
                <img
                  style={{ maxWidth: '100%' }}
                  src={image as string}
                  alt=""
                  onLoad={() => setIsLoaded(true)}
                />
              ) : (
                <div>{image}</div>
              )
            ) : null}
            {video ? (
              <div
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                  paddingTop: '56.25%'
                }}
              >
                <iframe
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    border: 0
                  }}
                  src={video}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  title="video"
                  onLoad={() => setIsLoaded(true)}
                />
              </div>
            ) : null}
            {contentComponent ? <div>{contentComponent}</div> : null}
            {noticeText ? (
              <Typography style={{ color, paddingTop: '8px' }} variant="h3">
                {noticeText}
              </Typography>
            ) : null}
            {headline || title || subtitle ? (
              <div style={{ paddingTop: '8px' }}>
                {headline ? (
                  <Typography style={{ color }} variant="h5">
                    {headline}
                  </Typography>
                ) : null}
                {title ? (
                  <Typography style={{ color }} variant="h6">
                    {title}
                  </Typography>
                ) : null}
                {subtitle ? (
                  <Typography style={{ color }} variant="subtitle1">
                    {subtitle}
                  </Typography>
                ) : null}
              </div>
            ) : null}
          </div>
          {!componentHeight && (
            <React.Fragment>
              {body ? (
                <Typography
                  style={{ color, padding: '8px 16px', textAlign: 'center' }}
                  variant="body2"
                >
                  {body}
                </Typography>
              ) : null}
              {actionComponent && (
                <div
                  style={{
                    position: 'relative',
                    height: `${actionHeight}px`,
                    marginBottom: '0px',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <div
                    ref={actionHeightRef}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      paddingLeft: '16px',
                      paddingRight: '16px'
                    }}
                  >
                    {actionComponent}
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
        </Wrapper>
      </div>
      {componentHeight && (
        <React.Fragment>
          <div style={{ position: 'relative', marginTop: `-${actionHeight}px` }}>
            {body ? (
              <Typography
                style={{
                  color,
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)'
                }}
                variant="body2"
              >
                {body}
              </Typography>
            ) : null}
            {actionComponent && (
              <div
                style={{
                  position: 'relative',
                  height: `${actionHeight}px`,
                  marginBottom: '38px',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <div
                  ref={actionHeightRef}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    paddingLeft: '16px',
                    paddingRight: '16px'
                  }}
                >
                  {actionComponent}
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </Wrapper>
  )
}

export default StatusHero
