import { CommonActionTypes, AppActions } from 'types/types'
import { Dispatch } from 'redux'

export const REFETCH = 'REFETCH'

export const refetch =
  (value: boolean) =>
  (dispatch: Dispatch<AppActions>): CommonActionTypes => {
    return dispatch({
      type: REFETCH,
      payload: { refetch: value }
    })
  }
