import { useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'
import ReactMarkdown from 'react-markdown'
import {
  StyledButton,
  AppBar,
  StatusHero,
  FeedCard,
  FeedTitle,
  Dialog,
  WhiteLabel
} from 'components/common'
import {
  Snackbar,
  IconButton,
  Card,
  CardContent,
  AlertProps,
  Alert as MuiAlert,
  SpeedDialIcon
} from '@mui/material'
import { Close, Stop, Pause, PlayCircleFilled } from '@mui/icons-material'
import CommentIcon from '@mui/icons-material/Comment'
import {
  UqCircularProgress,
  UqContainer,
  UqButton,
  UqTextField,
  UqSpeedDial,
  UqSpeedDialAction,
  UqBackdrop,
  UqBox
} from '@uniqore/wrapper'
import { useMutation, useQuery } from '@apollo/client'
import ServiceClient from '../api/serviceClient'
import { useHistory } from 'react-router-dom'
import { addTimerActionQuery, editDataQuery, getFeedcardsQuery } from './queries/queries'
import {
  formatDateForEvents,
  formatActionDateTime,
  formatDateTime,
  secondsToHoursAndMinutes,
  getActionsInSeconds,
  secondsToHoursMinutesAndSeconds
} from './utils/dateFunctions'
import { appBarAction } from './utils/help'

const useStyles = makeStyles()(() => ({
  whiteLabel: {
    position: 'fixed',
    bottom: '0',
    width: '100% !important',
    left: '0'
  },
  textButton: {
    width: '100%',
    textAlign: 'center'
  },
  speedDial: {
    position: 'fixed',
    left: '75%',
    bottom: 10
  },
  snackbar: {
    backgroundColor: '#FF3F8D'
  }
}))

const InProgress = () => {
  const { classes } = useStyles()
  const history = useHistory()

  let actions: any = JSON.parse(localStorage.getItem('actions') || '[]')
  let status = 'start'
  if (actions.length > 0 && actions[actions.length - 1].status === 'work') {
    status = 'work'
  } else if (actions.length > 0 && actions[actions.length - 1].status === 'pause') {
    status = 'pause'
  }

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [noteValue, setNoteValue] = useState<string>('')
  const [errorText, setErrorText] = useState<string>('')
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const [successMessage, showSuccessMessage] = useState<boolean>(false)
  const [more, setMore] = useState(10)
  const [currentStatus, setCurrentStatus] = useState<string>(status)
  const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }

  const getCurrentItem = () => {
    let currentItem = localStorage.getItem('currentItem')
    return currentItem ? JSON.parse(currentItem) : null
  }

  if (!getCurrentItem()) {
    history.push('/')
  }

  const {
    data: feedcardsData,
    loading: feedcardsLoading,
    error,
    refetch
  } = useQuery(getFeedcardsQuery, {
    client: ServiceClient,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: getCurrentItem().engagementId
    }
  })

  let feedcards: React.ReactNode[] = []
  if (feedcardsData) {
    for (let card of feedcardsData.serviceEngagement.events) {
      if (card.key === 'cleaningContent') {
        feedcards.push(card)
      }
    }
  }

  const [addTimerAction, { loading: statusLoading }] = useMutation(addTimerActionQuery, {
    onCompleted(mutationResult) {
      if (mutationResult.ingestForm.errors || !mutationResult.ingestForm.ok) {
        setShowSnackbar(true)
      } else {
        refetch()
      }
    }
  })

  const [stopTimer, { loading: stopLoading }] = useMutation(addTimerActionQuery, {
    onCompleted(mutationResult) {
      if (mutationResult.ingestForm.errors || !mutationResult.ingestForm.ok) {
        setShowSnackbar(true)
      } else {
        refetch()
        setCurrentStatus('stop')
        setNoteValue('')
        localStorage.setItem('currentItem', '')
        localStorage.setItem('itemInProgress', '')
        localStorage.setItem('actions', JSON.stringify([]))
        returnToPrev()
      }
    }
  })

  const [backdrop, setBackdrop] = useState<boolean>(false)
  const handleOpen = () => {
    setBackdrop(true)
  }

  const handleClose = () => setBackdrop(false)

  const putEndTimeToPreviousItem = () => {
    if (actions[actions.length - 2].endTime === null) {
      actions[actions.length - 2].endTime = formatActionDateTime(new Date())
    }
    localStorage.setItem('actions', JSON.stringify(actions))
  }

  const onPause = async () => {
    const date = new Date()
    await addTimerAction({
      variables: {
        id: process.env.REACT_APP_PAUSE_INGEST_FORM_ID,
        form: {
          date: formatDateForEvents(date),
          engagement: getCurrentItem().engagementId
        }
      }
    })
    setCurrentStatus('pause')
    date.setSeconds(date.getSeconds() + 1)
    let startTime = formatActionDateTime(date)
    const timerAction = {
      startTime: startTime,
      endTime: null,
      status: 'pause'
    }
    actions.push(timerAction)
    putEndTimeToPreviousItem()
  }

  const returnToPrev = () => {
    history.push('/siivoukset')
  }

  const onPlay = async () => {
    const date = new Date()
    await addTimerAction({
      variables: {
        id: process.env.REACT_APP_START_INGEST_FORM_ID,
        form: {
          date: formatDateForEvents(date),
          engagement: getCurrentItem().engagementId
        }
      }
    })
    setCurrentStatus('work')
    let startTimeWithSeconds = formatActionDateTime(date)
    const timerAction = {
      startTime: startTimeWithSeconds,
      endTime: null,
      status: 'work'
    }
    actions.push(timerAction)
    localStorage.setItem('actions', JSON.stringify(actions))
    if (
      localStorage.getItem('itemInProgress') === '' ||
      localStorage.getItem('itemInProgress') === null
    ) {
      localStorage.setItem('itemInProgress', getCurrentItem().airtableId)
    }
  }

  const onContinue = async () => {
    const date = new Date()
    await addTimerAction({
      variables: {
        id: process.env.REACT_APP_CONTINUE_INGEST_FORM_ID,
        form: {
          date: formatDateForEvents(date),
          engagement: getCurrentItem().engagementId
        }
      }
    })
    setCurrentStatus('work')
    date.setSeconds(date.getSeconds() + 1)
    let startTime = formatActionDateTime(date)
    const timerAction = {
      startTime: startTime,
      endTime: null,
      status: 'work'
    }
    actions.push(timerAction)
    putEndTimeToPreviousItem()
  }

  const onStop = async () => {
    const date = new Date()
    const stopTime = formatDateTime(date, 'HH:mm')
    let stopFullTime = formatActionDateTime(date)
    if (actions[actions.length - 1].endTime === null) {
      actions[actions.length - 1].endTime = stopFullTime
    }
    localStorage.setItem('actions', JSON.stringify(actions))

    await stopTimer({
      variables: {
        id: process.env.REACT_APP_STOP_INGEST_FORM_ID,
        form: {
          dateText: formatDateForEvents(date),
          engagement: getCurrentItem().engagementId,
          airtableId: getCurrentItem().airtableId,
          date: formatDateTime(date, 'yyyy-MM-dd'),
          startTime: formatDateTime(actions[0].startTime, 'HH:mm'),
          endTime: stopTime,
          pauses: secondsToHoursAndMinutes(getActionsInSeconds(actions, 'pause')),
          completedAt: date.toISOString()
        }
      }
    })
  }

  const [sendNoteAction, { loading: loadingNote }] = useMutation(editDataQuery, {
    onCompleted(mutationResult) {
      if (mutationResult.ingestForm.errors || !mutationResult.ingestForm.ok) {
        setErrorText('Virhe tapahtui. Ole hyvä ja yritä hetken kuluttua uudestaan.')
      } else if (mutationResult.ingestForm.ok) {
        setNoteValue('')
        setOpenDialog(false)
        setErrorText('')
        showSuccessMessage(true)
      }
    }
  })

  const sendNote = () => {
    const date = new Date()
    sendNoteAction({
      variables: {
        id: process.env.REACT_APP_SEND_NOTE_INGEST_FORM_ID,
        form: {
          date: formatDateForEvents(date),
          engagement: getCurrentItem().engagementId,
          note: noteValue,
          airtableId: getCurrentItem().airtableId,
          planned: getCurrentItem().planned,
          company: getCurrentItem().company,
          address: getCurrentItem().address
        }
      }
    })
  }

  const appBarActions = () => {
    const actions = []
    if (process.env.REACT_APP_GUIDE_URL) {
      actions.push(appBarAction)
    }
    return actions
  }

  const [timer, setTimer] = useState(
    secondsToHoursMinutesAndSeconds(
      getActionsInSeconds(JSON.parse(localStorage.getItem('actions') || '[]'), 'work')
    )
  )
  useEffect(() => {
    let isCancelled = false
    let timerInstance = setTimeout(() => {
      if (!isCancelled) {
        setTimer(
          secondsToHoursMinutesAndSeconds(
            getActionsInSeconds(JSON.parse(localStorage.getItem('actions') || '[]'), 'work')
          )
        )
      }
    }, 1000)

    // Cleanup to avoid state update on unmounted component
    return () => {
      isCancelled = true
      clearTimeout(timerInstance)
    }
  })

  if (error) {
    return <div />
  }

  return (
    <div style={{ width: '100%' }}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setShowSnackbar(false)}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      >
        <Alert severity="error">Virhe tapahtui. Ole hyvä ja yritä hetken kuluttua uudestaan.</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={successMessage}
        autoHideDuration={6000}
        onClose={() => showSuccessMessage(false)}
        message="Huomio lisätty."
        color="primary"
        ContentProps={{
          className: classes.snackbar
        }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => showSuccessMessage(false)}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
      <AppBar
        backgroundColor="#69315F"
        color="#fff"
        logo="https://storage.googleapis.com/minttisiivous/41d7da18-mintti-flag-big-darkbg.svg"
        logoSize={32}
        mainAction={{ icon: 'ArrowBack', onClick: returnToPrev }}
        actions={appBarActions()}
        maxWidth="sm"
      />
      <UqBackdrop open={backdrop} sx={{ color: '#fff', zIndex: 100 }} />
      <StatusHero
        backgroundColor="#69315F"
        color="#FFF1E9"
        noticeText={timer}
        iconSize={80}
        headline={
          actions.length < 1
            ? 'Aloita siivous'
            : actions.length > 0 && actions[actions.length - 1].status === 'work'
            ? 'Siivous käynnissä'
            : actions.length > 0 && actions[actions.length - 1].status === 'pause'
            ? 'Siivous pysäytetty'
            : 'Aloita siivous'
        }
        subtitle={getCurrentItem()['address']}
        actionComponent={
          currentStatus === 'pause' ? (
            <UqButton
              startIcon={<Stop />}
              color="primary"
              variant="contained"
              onClick={() => onStop()}
              disabled={statusLoading || stopLoading}
              sx={{ padding: '16px 24px' }}
              marginBottom={32}
            >
              {statusLoading || stopLoading ? (
                <UqCircularProgress size="24px" color="inherit" />
              ) : (
                'LOPETA'
              )}
            </UqButton>
          ) : currentStatus === 'work' ? (
            <UqButton
              startIcon={<Pause />}
              color="primary"
              variant="contained"
              onClick={() => onPause()}
              disabled={statusLoading}
              sx={{ padding: '16px 24px' }}
              marginBottom={32}
            >
              {statusLoading ? <UqCircularProgress size="24px" color="inherit" /> : 'PYSÄYTÄ'}
            </UqButton>
          ) : (
            <UqButton
              startIcon={<PlayCircleFilled />}
              color="primary"
              variant="contained"
              onClick={() => onPlay()}
              disabled={statusLoading}
              sx={{ padding: '16px 24px' }}
              marginBottom={32}
            >
              {statusLoading ? <UqCircularProgress size="24px" color="inherit" /> : 'ALOITA'}
            </UqButton>
          )
        }
      />
      <div className={classes.textButton}>
        {currentStatus === 'pause' ? (
          <UqButton
            startIcon={<PlayCircleFilled />}
            disabled={statusLoading}
            color="primary"
            onClick={() => onContinue()}
            variant="text"
            marginTop={24}
            sx={{ textTransform: 'none', textDecoration: 'underline' }}
          >
            {statusLoading ? <UqCircularProgress size="24px" color="inherit" /> : 'Jatka siivousta'}
          </UqButton>
        ) : null}
      </div>
      <UqContainer maxWidth="lg" fixed={true}>
        {getCurrentItem().cleaningPlan !== '' ? (
          <>
            <FeedTitle text="Siivoussuunnitelma" style={{ marginBottom: 10 }} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 25
              }}
            >
              <Card
                style={{
                  maxWidth: 400,
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <CardContent>
                  <ReactMarkdown>{getCurrentItem().cleaningPlan}</ReactMarkdown>
                </CardContent>
              </Card>
            </div>
          </>
        ) : null}

        <FeedTitle text={feedcards.length < 1 ? '' : 'Viimeiset tapahtumat'} />
        <div style={{ marginBottom: 40 }}>
          {feedcardsLoading ? (
            <UqContainer>
              <div
                style={{
                  width: 60,
                  margin: '25px auto'
                }}
              >
                <UqCircularProgress />
              </div>
            </UqContainer>
          ) : null}
          {feedcards.slice(0, more).map((card: any, index: number) => {
            if (!feedcardsLoading) {
              return (
                <div key={index}>
                  <FeedCard
                    user={card.metadata[0].value.user as string}
                    subtitle={card.metadata[0].value.subtitle as string}
                    text={card.metadata[0].value.text as string}
                    date={card.metadata[0].value.date as string}
                    avatarBackgroundColor={card.metadata[0].value.avatarBackgroundColor as string}
                  />
                </div>
              )
            } else return null
          })}
          {more < feedcards.length && !feedcardsLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <StyledButton
                color="secondary"
                onClick={() => setMore(more + 10)}
                variant="contained"
              >
                Lisää
              </StyledButton>
            </div>
          ) : null}
        </div>
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          title="Lisää huomio"
          children={[
            <UqTextField
              key={'note'}
              style={{ marginTop: 16 }}
              multiline
              minRows={3}
              autoFocus
              fullWidth
              label="Lisää huomio"
              value={noteValue}
              error={errorText ? true : false}
              helperText={errorText}
              onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                setNoteValue(e.target.value)
              }
            />
          ]}
          actionComponents={[
            <StyledButton
              variant="text"
              color="inherit"
              disablePadding
              disabled={loadingNote}
              onClick={() => setOpenDialog(false)}
            >
              Peruuta
            </StyledButton>,
            <StyledButton
              disabled={loadingNote}
              color="primary"
              disablePadding
              onClick={() => sendNote()}
              variant="contained"
            >
              {loadingNote ? <UqCircularProgress size="24px" color="inherit" /> : 'Lisää'}
            </StyledButton>
          ]}
        />
        <UqBox>
          <UqSpeedDial
            onOpen={handleOpen}
            onClose={handleClose}
            open={backdrop}
            className={classes.speedDial}
            ariaLabel={'Lisää huomio'}
            icon={<SpeedDialIcon />}
            FabProps={{
              sx: {
                bgcolor: 'secondary.main',
                '&:hover': {
                  bgcolor: 'secondary.main'
                }
              }
            }}
          >
            <UqSpeedDialAction
              key={'Lisää huomio'}
              icon={<CommentIcon onClick={() => setOpenDialog(true)} />}
              tooltipTitle={'Lisää huomio'}
            />
          </UqSpeedDial>
        </UqBox>
        <WhiteLabel className={classes.whiteLabel} />
      </UqContainer>
    </div>
  )
}

export default InProgress
