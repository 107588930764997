import { makeStyles } from 'tss-react/mui'
import { UqContainer, UqCircularProgress, UqButton } from '@uniqore/wrapper'
import { AppBar, StatusHero, WhiteLabel } from 'components/common'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import ServiceClient from '../api/serviceClient'
import { getOrgUserQuery } from './queries/queries'
import { appBarAction } from './utils/help'
import AddIcon from '@mui/icons-material/Add'
import WorkTimes from './WorkTimes'

const useStyles = makeStyles()(() => ({
  whiteLabel: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    left: '0'
  }
}))

const Home = () => {
  const { classes } = useStyles()

  const { data, loading, error } = useQuery(getOrgUserQuery, {
    client: ServiceClient,
    variables: { id: localStorage.getItem('userId') }
  })

  let history = useHistory()

  const logOut = () => {
    localStorage.removeItem('token')
    history.push('/kirjaudu')
  }

  const handleSubmit = () => {
    history.push('/siivoukset')
  }

  const appBarActions = () => {
    const actions = []
    if (process.env.REACT_APP_GUIDE_URL) {
      actions.push(appBarAction)
    }
    actions.push({ icon: 'ExitToApp', onClick: logOut })
    return actions
  }

  if (loading) {
    return (
      <UqContainer>
        <div
          style={{
            width: 60,
            margin: '25px auto'
          }}
        >
          <UqCircularProgress />
        </div>
      </UqContainer>
    )
  }

  if (error) {
    return <div />
  }

  return (
    <div style={{ width: '100%' }}>
      <AppBar
        backgroundColor="#69315F"
        color="#fff"
        logo="https://storage.googleapis.com/minttisiivous/41d7da18-mintti-flag-big-darkbg.svg"
        logoSize={32}
        maxWidth="xs"
        actions={appBarActions()}
      />
      <StatusHero
        backgroundColor="#69315F"
        icon="EmojiPeople"
        iconSize={80}
        headline={`Hei ${data.orgUser.firstName},`}
        subtitle="Kirjaa kohde tai tarkastele kirjauksiasi."
        actionComponent={
          <>
            <UqButton
              color="primary"
              onClick={handleSubmit}
              variant="contained"
              startIcon={<AddIcon />}
              sx={{ padding: '16px 24px' }}
            >
              KIRJAA ASIAKASTYÖ
            </UqButton>
          </>
        }
        style={{ marginBottom: 48 }}
      />
      <WorkTimes />
      <UqContainer maxWidth="lg" fixed={true}>
        <WhiteLabel className={classes.whiteLabel} />
      </UqContainer>
    </div>
  )
}

export default Home
