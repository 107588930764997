import { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import {
  UqListItem,
  UqListItemSecondaryAction,
  UqListItemText,
  UqTypography
} from '@uniqore/wrapper'
import { getCurrentItem } from '../utils/allocationFunctions'
import {
  changeDateFormat,
  secondsToHoursAndMinutes,
  getActionsInSeconds
} from '../utils/dateFunctions'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

function ActiveItem(): ReactElement {
  const history = useHistory()
  const item = getCurrentItem()

  if (!item) return <></>

  const { address = '', company = '', planned = false } = item

  return (
    <>
      {item ? (
        <UqListItem style={{ cursor: 'pointer' }} onClick={() => history.push('/siivous')}>
          <UqListItemText
            primary={address}
            secondary={
              <>
                <UqTypography
                  variant="body2"
                  color="text.secondary"
                  component="span" // needed for validate DOM-nesting
                  sx={{ display: 'block' }} // since component is 'span', set display to block
                >
                  {company}, {planned ? changeDateFormat(planned) : ''}
                </UqTypography>
                <UqTypography
                  variant="body2"
                  color="text.secondary"
                  component="span" // needed for validate DOM-nesting
                  sx={{ display: 'block' }} // since component is 'span', set display to block
                >
                  {secondsToHoursAndMinutes(
                    getActionsInSeconds(JSON.parse(localStorage.getItem('actions') || '[]'), 'work')
                  )}
                </UqTypography>
              </>
            }
          />
          <UqListItemSecondaryAction>
            <ArrowForwardIcon color="primary" />
          </UqListItemSecondaryAction>
        </UqListItem>
      ) : null}
    </>
  )
}

export default ActiveItem
