// @ts-nocheck
import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from '@mui/material/styles'
import { ApolloProvider } from '@apollo/client'
import { Provider } from 'react-redux'
import { initStore } from 'store/store'
import theme from 'theme/theme'
import PublicClient from './api/publicClient'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM
}

TagManager.initialize(tagManagerArgs)

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)

export const store = initStore()
const render = () => {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <ApolloProvider client={PublicClient}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </ApolloProvider>
      </Provider>
    </React.StrictMode>
  )
}

render()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
