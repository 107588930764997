import { useParams } from 'react-router-dom'
import { WhiteLabel, Feed } from 'components/common'
import { UqContainer, UqCircularProgress } from '@uniqore/wrapper'
import { gql, useQuery } from '@apollo/client'
import { Header } from './customer-ui/Header'
import { EngagementType } from 'types/types'

interface RouteParams {
  engagement?: string
}

const GET_ENGAGEMENT = gql`
  query serviceEngagement($id: ID!) {
    serviceEngagement(id: $id) {
      id
      metadata {
        key
        value
      }
      stage {
        name
      }
    }
  }
`

interface EngagementData {
  serviceEngagement: EngagementType
}

export const CustomerUI = () => {
  const { engagement } = useParams<RouteParams>()
  const { loading, error, data } = useQuery<EngagementData>(GET_ENGAGEMENT, {
    variables: { id: engagement }
  })

  if (error || data === undefined || data.serviceEngagement == null) {
    return null
  }

  return (
    <div style={{ paddingBottom: 100 }}>
      {loading && (
        <UqContainer>
          <div
            style={{
              width: 60,
              margin: '25px auto'
            }}
          >
            <UqCircularProgress />
          </div>
        </UqContainer>
      )}
      <Header engagement={data.serviceEngagement} />
      <Feed engagement={data.serviceEngagement} />
      <WhiteLabel style={{ position: 'absolute', bottom: 0, width: '100%' }} />
    </div>
  )
}
