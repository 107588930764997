import React from 'react'
import { styled } from '@mui/material/styles'
import { UqTypography } from '@uniqore/wrapper'
import { Property } from 'csstype'
interface ContainerProps {
  textcolor?: Property.Color
  background?: Property.Background
}
const Container = styled('div', {
  name: 'WhiteLabel',
  shouldForwardProp: prop => prop !== 'textcolor' && prop !== 'background'
})<ContainerProps>(props => ({
  height: '29px',
  borderTop: '1px solid rgba(0, 44, 56, 0.12)',
  textAlign: 'center',
  color: props.textcolor || 'rgba(0, 44, 56, 0.38)',
  background: props.background || '#fff'
}))
export interface WhiteLabelProps {
  /**
   * @description Text color of the WhiteLabel.
   * @default 'rgba(0, 44, 56, 0.38)'
   */
  textColor?: Property.Color
  /**
   * @description Background of the WhiteLabel.
   * @default '#fff'
   */
  background?: Property.Background
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
}
const WhiteLabel: React.FC<WhiteLabelProps> = ({ textColor, background, style, className }) => {
  return (
    <div style={style} className={className}>
      <Container textcolor={textColor} background={background}>
        <UqTypography
          component="span"
          sx={{
            margin: 0,
            fontSize: 12,
            lineHeight: 2.3
          }}
        >
          Made with{' '}
        </UqTypography>
        <UqTypography
          sx={{
            color: 'rgba(0, 44, 56, 0.60)',
            fontWeight: 700,
            textDecoration: 'none',
            fontSize: 12,
            margin: 0
          }}
          component="a"
          href="https://uniqore.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Uniqore&trade;
        </UqTypography>
      </Container>
    </div>
  )
}
export default WhiteLabel
